import React,{ useState } from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container, Divider} from "@mui/material";
import Heading from '../../../Components/Heading';
import Breadcrumbs from '../../../Components/Breadcrumbs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SeeAllEvent from './SeeAllEvent';
import CreateEvent from './CreateEvent';
import { useNavigate } from 'react-router-dom';

export default function EventsTab() {

    const userName = JSON.parse(localStorage.getItem('user'));
    const role = JSON.parse(localStorage.getItem('role'));

      const [value, setValue] = useState("1");

  // Tab functionality
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const navigate = useNavigate();

  const handleClick = (path) =>{
   navigate(`${path}`)
  }

  const access = JSON.parse(localStorage.getItem('access'));
  const { CreateEvent : Create, SeeAllEvents } = access;


  return (
    <Box>

    <Box py={2} px={1}>
    <Breadcrumbs 
    previous={'Home'}
    current={'Announcement'}
    link1={`/home`}
    link2={'/home/announcement'}
    currentSection={'Events'}
    />
    </Box>

    <Container>
      <Box py={3}>
      <Heading  title={'Events'}/>
      </Box>
    </Container>



 <Container>

    <Box >
    <TabContext value={value}>
      <Box>
          <TabList
                     variant="scrollable"
                     scrollButtons
                     allowScrollButtonsMobile
          onChange={handleChange} aria-label="tabs">
            {
              Create === 'true' && 
                  <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Create Event" value="1" />

            }
            {
                  SeeAllEvents === 'true' && 
                  <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginLeft: 5 }} label="See All Events" value="2" />
            }
          </TabList>
          </Box>
          {
              Create === 'true' && 
          <TabPanel value="1">
          <Box>
          <CreateEvent />
          </Box>
          </TabPanel>
          }
          {
              SeeAllEvents === 'true' &&
          <TabPanel value="2">
          <Box>
          <SeeAllEvent />
          </Box>
          </TabPanel>
          }
        </TabContext>
      </Box>
    </Container>

  </Box>
  )
}
