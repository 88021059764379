import { Box, Button, Card, Grid, TextField, Container, Snackbar, Alert, Grow, Autocomplete } from "@mui/material";
import React from 'react';
import { appendData } from '../../../Variables/Variables';
import axios from 'axios';
import { insertAccountDetails, methodPost, updateAccountDetails } from '../../../API_Service/API_Links';
import { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import IconBreadcrumbs from "../../Breadcrumbs";
import Heading from "../../Heading";

export default function UpdateBank() {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const location = useLocation();
  const { data } = location.state;
  const [isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen] = useState(false);
  const [Notification, setNotification] = useState("");
  const [editedData, setEditedData] = useState({
    AccountNumber: data.AccountNumber,
    IFSCCode: data.IFSCCode,
    BankName: data.BankName,
    Branch: data.Branch,
    AccountName: data.AccountName,
    AccountHolder: data.AccountHolder,
    AliasName: data.AliasName,
    ManualAlias: data.AliasName,
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAccountNumberChange = (e) => {
    const newValue = e.target.value;
    if (newValue.match(/^[0-9]+$/)) {
      setEditedData((prevData) => ({ ...prevData, AccountNumber: newValue }));
    } else {
      setEditedData((prevData) => ({ ...prevData, AccountNumber: '' }));
    }
  };

  const handleAliasChange = (event, value) => {
    setEditedData((prevData) => ({ ...prevData, AliasName: value, ManualAlias: value }));
  };

  const handleManualAliasChange = (e) => {
    const newValue = e.target.value;
    setEditedData((prevData) => ({ ...prevData, ManualAlias: newValue }));
  };

  const handleAccountHolderChange = (event, value) => {
    setEditedData((prevData) => ({ ...prevData, AccountHolder: value }));
  };

  const UpdateAccount = () => {
    const obj = {
      Id: data.AccountId,
      AccountNumber: editedData.AccountNumber,
      IFSCCode: editedData.IFSCCode,
      BankName: editedData.BankName,
      Branch: editedData.Branch,
      AccountName: editedData.AccountName,
      AccountHolder: editedData.AccountHolder,
      AliasName: editedData.ManualAlias !== "" ? editedData.ManualAlias : editedData.AliasName,
    };

    const ldata = appendData(obj);
    axios({
      method: methodPost,
      url: updateAccountDetails,
      data: ldata,
    }).then((res) => {
      if (!res.data.error) {
        setIsAddedCartSnackbarOpen(true);
        setNotification(res.data.message);
        setTimeout(() => { navigate(-1); }, 1500);
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    }).catch((err) => {
      alert('Oops something went wrong ' + err);
    });
  };

  const handleCloseAddedCartSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsAddedCartSnackbarOpen(false);
  };

  function GrowTransition(props) {
    return <Grow {...props} direction="up" />;
  }

  return (
    <Box>
      <Snackbar
        open={isAddedCartSnackbarOpen}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={GrowTransition}
        onClose={handleCloseAddedCartSnackbar}>
        <Alert onClose={handleCloseAddedCartSnackbar} severity='success' variant="filled" sx={{ width: '100%' }}>
          {Notification}
        </Alert>
      </Snackbar>

      <Box py={2} px={1}>
        <IconBreadcrumbs
          previous={'Home'}
          current={'Bank'}
          link1={`/home`}
          link2={'/home/finance/bank'}
          currentSection={'Update Account'}
        />
      </Box>

      <Container>
        <Box py={3}>
          <Heading title={'Update Account Details'} />
        </Box>
      </Container>

      <Container>
        <Box component={Card} p={4}>
          <Box
            sx={{
              border: "1px solid black",
              p: 3,
              borderColor: "#d2cbcb;",
              borderRadius: "4px",
              mt: 1,
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="AccountNumber"
                  label="Account Number"
                  fullWidth
                  size='small'
                  value={editedData.AccountNumber}
                  onChange={handleAccountNumberChange}
                  inputProps={{
                    maxLength: 16,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="IFSCCode"
                  label="IFSC Code"
                  fullWidth
                  size='small'
                  value={editedData.IFSCCode}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="BankName"
                  label="Bank Name"
                  fullWidth
                  size='small'
                  value={editedData.BankName}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="Branch"
                  label="Branch"
                  fullWidth
                  size='small'
                  value={editedData.Branch}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="AccountName"
                  label="Account Name"
                  fullWidth
                  value={editedData.AccountName}
                  size='small'
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  freeSolo
                  options={['CC', 'CA']}
                  value={editedData.AliasName}
                  onChange={handleAliasChange}
                  size='small'
                  renderInput={(params) => (
                    <TextField
                      onChange={handleManualAliasChange}
                      fontSize='small'
                      label='Alias'
                      color='secondary'
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={editedData.AccountHolder}
                  options={['Igreen', 'Igreen Tec']}
                  onChange={handleAccountHolderChange}
                  size='small'
                  renderInput={(params) => (
                    <TextField
                      fontSize='small'
                      label='Account Holder'
                      color='secondary'
                      {...params}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container display='flex' justifyContent='center' mt={1} spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={4} display='flex' justifyContent='center'>
                <Button variant='contained' color='error' onClick={() => navigate(-1)} fullWidth>Cancel</Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} display='flex' justifyContent='center'>
                <Button variant='contained' color='secondary' onClick={UpdateAccount} fullWidth>Update Account</Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}




















// import {Box,Button,Card,Grid,TextField, Container, Snackbar, Alert, Grow, Autocomplete } from "@mui/material";
// import React from 'react'
// import {  appendData, } from '../../../Variables/Variables';
// import axios from 'axios';
// import { insertAccountDetails, methodPost, updateAccountDetails } from '../../../API_Service/API_Links';
// import { useState } from "react";
// import SnackBar from "../../SnackBar";
// import { Navigate, useLocation, useNavigate } from "react-router-dom";
// import IconBreadcrumbs from "../../Breadcrumbs";
// import Heading from "../../Heading";


// export default function UpdateBank() {

//   const [open, setOpen] = useState(false);
//   const [status, setStatus] = useState(false);
//   const [color, setColor] = useState(false);
//   const [message, setMessage] = useState("");
//   const location = useLocation();
//   const {data} = location.state;
//   const [isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen] = useState(false);
//   const [Notification, setNotification] = useState("");
//   const [editedData , setEditedData] = useState({
//     AccountNumber:data.AccountNumber,
//     IFSCCode:data.IFSCCode,
//     BankName:data.BankName,
//     Branch:data.Branch,
//     AccountName:data.AccountName,
//     AccountHolder:data.AccountHolder,
//     AliasName:data.AliasName,
//     ManualAlias:data.AliasName,
//   });

//   const navigate = useNavigate();


//   const handleChange = (e) =>{
//     const {name, value} = e.target;
//     setEditedData({...editedData , [name]:value});
//   }

//   const UpdateAccount = () =>{
//    const obj = {
//    Id:data.AccountId,
//   AccountNumber:editedData.AccountNumber,
//   IFSCCode:editedData.IFSCCode,
//   BankName:editedData.BankName,
//   Branch:editedData.Branch,
//   AccountName:editedData.AccountName,
//   AccountHolder:editedData.AccountHolder,
//   AliasName:editedData.ManualAlias !== "" ? editedData.ManualAlias : editedData.AliasName,
//    }
//    const ldata = appendData(obj);
//         axios({
//             method: methodPost,
//             url:updateAccountDetails,
//             data: ldata,
//         }).then(res => {
//             if (!res.data.error) {
//                 setIsAddedCartSnackbarOpen(true);
//                 setNotification(res.data.message)
//               setTimeout(() => {navigate(-1)},1500);
//             } else {
//               setMessage(res.data.message)
//                 setOpen(true)
//                 setStatus(false)
//                 setColor(false)
//             }
//         }).catch(err => {
//             alert('Oops something went wrong ' + err)
//         })
//   }

//   const handleCloseAddedCartSnackbar = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setIsAddedCartSnackbarOpen(false);
//   };

//     function GrowTransition(props) {
//     return <Grow {...props} direction="up" />;
//   }

//   return (
//     <Box>

//        <Snackbar
//        open={isAddedCartSnackbarOpen}
//        autoHideDuration={2000}
//        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//        TransitionComponent={GrowTransition}
//         onClose={handleCloseAddedCartSnackbar}>
//      <Alert onClose={handleCloseAddedCartSnackbar} severity='success' variant="filled" sx={{ width: '100%' }}>
//       {Notification}
//      </Alert>
//       </Snackbar>

// <Box py={2} px={1}>
//     <IconBreadcrumbs
//     previous={'Home'}
//     current={'Bank'}
//     link1={`/home`}
//     link2={'/home/finance/bank'}
//     currentSection={'Update Account'}

//     />
//     </Box>

//     <Container>
//       <Box py={3}>
//       <Heading title={'Update Account Details'}/>
//       </Box>
//     </Container>

// <Container>
//    <Box component={Card} p={4}>
//    <Box
//         sx={{
//         border: "1px solid black",
//         p: 3,
//         borderColor: "#d2cbcb;",
//         borderRadius: "4px",
//         mt: 1,
//         }}
//     >

//       <Grid container spacing={4}>

//       <Grid item xs={12} sm={6} md={4} lg={4}>
//       <TextField
//             name="AccountNumber"
//             label="Account Number"
//             fullWidth
//             size='small'
//             value={editedData.AccountNumber}
//             onChange={(e) => {
//               const newValue = e.target.value;
//               if (newValue.match(/^[0-9]+$/)){
//                   setEditedData({...data , AccountNumber: parseInt(newValue)});
//               }
//               else{
//                 setEditedData({...data , AccountNumber: ''});
//               }
//             }}
//             inputProps={{
//               maxLength:16,
//             }}
//           />
//       </Grid>

//       <Grid item xs={12} sm={6} md={4} lg={4}>
//       <TextField
//             name="IFSCCode"
//             label="IFSC Code"
//             fullWidth
//             size='small'
//             value={editedData.IFSCCode}
//             onChange={handleChange}
//           />
//       </Grid>


//       <Grid item xs={12} sm={6} md={4} lg={4}>
//       <TextField
//             name="BankName"
//             label="Bank Name"
//             fullWidth
//             size='small'
//             value={editedData.BankName}
//             onChange={handleChange}
//           />
//       </Grid>

//       <Grid item xs={12} sm={6} md={4} lg={4}>
//       <TextField
//             name="Branch"
//             label="Branch"
//             fullWidth
//             size='small'
//             value={editedData.Branch}
//             onChange={handleChange}
//           />
//       </Grid>

//       <Grid item xs={12} sm={6} md={4} lg={4}>
//       <TextField
//             name="AccountName"
//             label="Account Name"
//             fullWidth
//             value={editedData.AccountName}
//             size='small'
//             onChange={handleChange}
//           />
//       </Grid>

//       <Grid item xs={12} sm={6} md={4} lg={4}>
//       <Autocomplete
//       disablePortal
//       id="combo-box-demo"
//       freeSolo
//       options={['CC', 'CA']}
//       value={editedData.AliasName}
//       onChange={(event, value) =>setEditedData({...data , AliasName:value})}
//       size='small'
//       renderInput={(params) => <TextField onChange={(e)=>setEditedData({...data , ManualAlias:e.target.value})}  fontSize='small' label='Alias' color='secondary' {...params} />}
//         />
//       </Grid>

//       <Grid item xs={12} sm={6} md={4} lg={4}>
//       <Autocomplete
//       disablePortal
//       id="combo-box-demo"
//       value={editedData.AccountHolder}
//       options={['Igreen', 'Igreen Tec']}
//       onChange={(event, value) => setEditedData({...data , AccountHolder:value})}
//       size='small'
//       renderInput={(params) => <TextField fontSize='small' label='Account Holder' color='secondary' {...params} />}
//         />
//       </Grid>

//       </Grid>

//       <Grid container display='flex' justifyContent='center' mt={1} spacing={3}>
//       <Grid item xs={12} sm={6} md={4} lg={4} display='flex' justifyContent='center'>
//         <Button variant='contained' color='error' onClick={()=>navigate(-1)} fullWidth>Cancel</Button>
//       </Grid>
//       <Grid item xs={12} sm={6} md={4} lg={4} display='flex' justifyContent='center'>
//       <Button variant='contained' color='secondary' onClick={UpdateAccount} fullWidth>Update Account</Button>
//     </Grid>
//       </Grid>


//       </Box>
//       </Box>
//     </Container>
//     </Box>
//   )
// }
