import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Card, Grid } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LayersIcon from '@mui/icons-material/Layers';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import { get_employee_activities, methodPost } from '../../API_Service/API_Links';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ProfileDialog({ EmployeeId , setEmployeeId ,setMessage , setColor ,setStatus , setOpen , openDialog, setOpenDialog }) {

  const [maxWidth, setMaxWidth] = React.useState('md');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [Task , setTask] = useState([]);
  const [Projects, setProject] = useState([]);
  const [Leaves, setLeaves] = useState([]);
  const [EmployeeName, setEmployeeName] = useState("");
  const [Designation, setDesignation] = useState('')

  const handleClose = () => {
    setOpenDialog(false);
    setEmployeeId(null);
  };

  console.log(EmployeeId);


  useEffect(() => {
    if(EmployeeId !== null && EmployeeId !==''){
    const sendData = new FormData()
    sendData.append('EmployeeId', EmployeeId)
    axios({
      method: methodPost,
      url: get_employee_activities,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setEmployeeName(res.data.data.EmployeeName)
          setDesignation(res.data.data.Designation)
          setTask(res.data.data.Task)
          setProject(res.data.data.Projects)
          setLeaves(res.data.data.Leaves)
          setOpen(true);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
    }
  }, [EmployeeId]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Employee Profile
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box>
            <Box p={5}>
           <Grid container spacing={4} rowSpacing={4}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
            <Box component={Card} p={4} alignItems='center' 
            display='flex' flexDirection='row'>
              <Box>
              <img alt='profile' src='https://wallpapers.com/images/featured/s52z1uggme5sj92d.jpg' 
              style={{objectFit:'contain', width:100, height:100, borderRadius:10}} />
            </Box>
            <Box px={2}>
              <Typography color='#28313c' fontWeight={600} fontSize={17}>{EmployeeName}</Typography>
              <Typography color='#616e80' variant='subtitle1'>{Designation}</Typography>
              <Typography color='#616e80' variant='caption'>Emp Id : {EmployeeId}</Typography>
            </Box>
            </Box>

            <Box mt={2} component={Card} p={2}  display='flex' justifyContent='space-between' flexDirection='column'>
                <Box  display='flex' justifyContent='space-between' flexDirection='row'>
                <Typography fontWeight={600}>Leaves</Typography>
                <Box alignSelf='center'>
                <HolidayVillageIcon sx={{color:'#616e80'}} fontSize='large'/>
                </Box>
                </Box>
                <Box display='flex'  flexDirection='row' mt={3} justifyContent='space-evenly'>
                  <Box textAlign='center'>
                        <Typography color='#3860b5'>{Leaves.Approved}</Typography>
                        <Typography color='#3860b5'>Approved</Typography>
                  </Box>
                  <Box textAlign='center'>
                  <Typography color='#c2312f'>{Leaves.Rejected}</Typography>
                 <Typography color='#c2312f'>Rejected</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box component={Card} p={3}  display='flex' justifyContent='space-between' flexDirection='column'>
                <Box  display='flex' justifyContent='space-between' flexDirection='row'>
                <Typography fontWeight={600}>Tasks</Typography>
                <Box alignSelf='center'>
                <FormatListBulletedIcon sx={{color:'#616e80'}} fontSize='large'/>
                </Box>
                </Box>
                    <Box display='flex' flexDirection='row' mt={3} justifyContent='space-evenly'>
                  <Box mr={2} textAlign='center'>
                  <Typography color='#3860b5'>{Task.Progress}</Typography>
                 <Typography color='#3860b5'>In Progress</Typography>
                  </Box>
                  <Box ml={2} textAlign='center'>
                  <Typography color='#c2312f'>{Task.Overdue}</Typography>
                 <Typography color='#c2312f'>Overdue</Typography>
                  </Box>

                </Box>

              </Box>

              <Box mt={3} component={Card} p={2}  display='flex' justifyContent='space-between' flexDirection='column'>
                <Box  display='flex' justifyContent='space-between' flexDirection='row'>
                <Typography fontWeight={600}>Projects</Typography>
                <Box alignSelf='center'>
                <LayersIcon sx={{color:'#616e80'}} fontSize='large'/>
                </Box>
                </Box>
                    <Box display='flex' flexDirection='row' mt={3} justifyContent='space-evenly'>
                  <Box mr={2} textAlign='center'>
                  <Typography color='#3860b5'>{Projects.Completed}</Typography>
                 <Typography color='#3860b5'>Completed</Typography>
                  </Box>
                  <Box ml={2} textAlign='center'>
                  <Typography color='#c2312f'>{Projects.Overdue}</Typography>
                 <Typography color='#c2312f'>Overdue</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>


            

           </Grid>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}