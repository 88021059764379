import React from 'react'
import { Box, Button, Card, Grid, TextField, Typography, Container } from "@mui/material";
import Heading from '../Heading';
import Breadcrumbs from '../Breadcrumbs';
import { financepageControllerView, financepageEmployeeView } from '../../Variables/Variables';
import { Outlet, useNavigate } from 'react-router-dom';
import IconBreadcrumbs from '../Breadcrumbs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import FuelTable from './AllExpenses/FuelTable';
import RentalTable from './AllExpenses/RentalTable';
import AccomodationTable from './AllExpenses/AccomodationTable';
import FoodTable from './AllExpenses/FoodTable';
import GeneralTable from './AllExpenses/GeneralTable';
import OtherTable from './AllExpenses/OtherTable';
import WageTable from './AllExpenses/WageTable';
import PurchasesTable from './AllExpenses/PurchasesTable';
import TransportTable from './AllExpenses/TransportTable';
import UtilizationTable from './AllExpenses/UtilizationTable';
import MaintenanceTable from './AllExpenses/MaintenanceTable';
import TravelTable from './AllExpenses/TravelTable';
import SummaryTable from './AllExpenses/SummaryTable';
import AccountExpense from './AllExpenses/AccountExpense';
import Transaction from './AllExpenses/Transaction';
import NewSummaryExpense from './AllExpenses/NewSummaryExpense';
import ApproveExpense from './AllExpenses/ApproveExpense';
import RejectedList from './AllExpenses/RejectedList';
import AddUnderMaintenancPage from './Maintenance/AddUnderMaintenance';
import MaintenancePage from './Maintenance/Maintenance';
import UnderMaintenancePage from './Maintenance/UnderMaintenance';
import AddUnderMaintenancePage from './Maintenance/AddUnderMaintenance';

export default function MaintenanceTab() {

    const [value, setValue] = useState("1");

    // Tab functionality
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const role = JSON.parse(localStorage.getItem('role'));


    return (
        <Box>

            <Box py={2} px={1}>
                <IconBreadcrumbs
                    previous={'Home'}
                    current={'Expense'}
                    link1={`/home`}
                    link2={'/home/finance/expense'}
                    currentSection={'Maintenance'}
                />
            </Box>

            <Container>
                <Box py={3}>
                    <Heading title={'Maintenance'} />
                </Box>
            </Container>



            <Box sx={{ p: 1 }}>
                <TabContext value={value}>
                    <Box>
                        <TabList
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            onChange={handleChange} aria-label="tabs">
                            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m: 2 }} label="Add Under Maintenance" value="1" />
                            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m: 2 }} label="Under Maintenance" value="2" />
                            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m: 2 }} label="Maintenance" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Box>
                            <AddUnderMaintenancePage />
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <Box>
                            <UnderMaintenancePage />
                        </Box>
                    </TabPanel>
                    <TabPanel value="3">
                        <Box>
                            <MaintenancePage />
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </Box>
    )
}
