import React, { useState } from 'react';
import { TextField, Container, Box, Button, Grid, Card, Autocomplete, InputAdornment, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SnackBar from '../../SnackBar';
import { addOwner, addvendor, fetch_sub1_category, fetch_sub2_category, fetch_sub3_category, fetch_sub4_category, fetch_sub5_category, fetch_sub6_category, getAllEmployeeName, getAllOwner, getAllSiteProject, getCity, getEmployeeProjectCode, getGroupsDropdown, getLedgerCategory1Dropdown, getLedgerCategory2Dropdown, getLedgerCategory3Dropdown, getLedgerCategory4Dropdown, getLedgerCategory5Dropdown, getLedgerNameDropdown, getSuggestionBox, getVendorDropdown, insertLedgerCreation, insertTravelExpense, methodGet, methodPost } from '../../../API_Service/API_Links';
import axios from 'axios';
import { useEffect } from 'react';
import { appendData } from '../../../Variables/Variables';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';

export default function TravelCreation() {
  const { handleSubmit, register, formState: { errors } } = useForm();
  const [projectCode, setProjectCode] = useState('');
  const [Type, setType] = useState(null);
  const [category1, setCategory1] = useState(null);
  const [category2, setCategory2] = useState(null);
  const [category3, setCategory3] = useState(null);
  const [category4, setCategory4] = useState(null);
  const [category5, setCategory5] = useState(null);
  const [rentedOrOwned, setRentedOrOwned] = useState(null);
  const [rentType, setRentType] = useState(null);
  const [duration, setDuration] = useState('');
  const [rate, setRate] = useState('');
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [vendorDropdown, setVendorDropdown] = useState([]);
  const [registrationDropdown, setRegistrationDropdown] = useState([]);
  const [balanceAmount, setBalanceAmount] = useState('');
  const [advance, setAdvance] = useState('');
  const [otherCategory, setOtherCategory] = useState('');

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [startingKM, setStartingKM] = useState("");
  const [closingKM, setClosingKM] = useState("");
  const [tollCharges, setTollCharges] = useState("");
  const [amount, setAmount] = useState(0);
  const [comments, setComments] = useState("");
  const [ticketCharges, setTicketCharges] = useState("");
  const [driverTips, setDriverTips] = useState("");
  const [imagesProof, setImagesProof] = useState(null);
  const [expenseDate, setExpenseDate] = useState(null);
  const options = ['GST', 'NON-GST'];
  const [gst, setGst] = useState('NON-GST');
  const [sgst, setSGST] = useState('');
  const [cgst, setCGST] = useState('');
  const [igst, setIGST] = useState('');
  const [ProjectList, setProjectList] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [input, setInput] = useState(false);
  const [EmpList, setEmpList] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCity1, setSelectedCity1] = useState(null);

  // List
  const [groupList, setGroupList] = useState([]);
  const [ledgerList, setLedgerList] = useState([]);
  const [TypeList, setTypeList] = useState([]);
  const [Category1List, setCategory1List] = useState([]);
  const [Category2List, setCategory2List] = useState([]);
  const [Category3List, setCategory3List] = useState([]);
  const [Category4List, setCategory4List] = useState([]);
  const [Category5List, setCategory5List] = useState([]);

  // Next Field Show
  const [showCategory1, setShowCategory1] = useState(false);
  const [showCategory2, setShowCategory2] = useState(false);
  const [showCategory3, setShowCategory3] = useState(false);
  const [showCategory4, setShowCategory4] = useState(false);
  const [showCategory5, setShowCategory5] = useState(false);
  const [showRent, setShowRent] = useState(false);

  const [LedgerName, setLedgerName] = useState(null);
  const [offficeCategory, setoffficeCategory] = useState(null);
  const [group, setGroup] = useState(null);
  const [ledgerCategory1, setLedgerCategory1] = useState(null);
  const [ledgerCategory2, setLedgerCategory2] = useState(null);
  const [ledgerCategory3, setLedgerCategory3] = useState(null);
  const [ledgerCategory4, setLedgerCategory4] = useState(null);
  const [ledgerCategory5, setLedgerCategory5] = useState(null);

  const [ManualOfficecategory1, setManualOfficeCategory1] = useState(null);
  const [ManualLedgerName, setManualLedgerName] = useState(null);
  const [Manualgroup, setManualGroup] = useState(null);
  const [Manualcategory1, setManualCategory1] = useState(null);
  const [Manualcategory2, setManualCategory2] = useState(null);
  const [Manualcategory3, setManualCategory3] = useState(null);
  const [Manualcategory4, setManualCategory4] = useState(null);
  const [Manualcategory5, setManualCategory5] = useState(null);
  const [empname, setEmpname] = useState([]);
  const [EmpId, setEmpId] = useState(null);

  const [validationError, setValidationError] = useState('');
  const [validationError1, setValidationError1] = useState('');
  const [validationError2, setValidationError2] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [vendorName, setVendorName] = useState('');
  const [vendorCompany, setVendorCompany] = useState('');
  const [vendorGst, setVendorGst] = useState('');
  const [vendorMobile, setVendorMobile] = useState('');
  const [vendorEmail, setVendorEmail] = useState('');
  const [vendorAddress, setVendorAddress] = useState('');
  const [vendorServices, setVendorServices] = useState('');

  const [ownerName, setOwnerName] = useState('');
  const [ownerContact, setOwnerContact] = useState('');
  const [addRegistrationNumber, setAddRegistrationNumber] = useState('');
  const [vehicleDetails, setvehicleDetails] = useState('');

  const [commentList, setCommentList] = useState([]);

  useEffect(() => {
    const fetchSuggestion = async () => {
      const sendData = new FormData();
      sendData.append('Category', 'Travel');
      try {
        const res = await axios.post(getSuggestionBox, sendData);
        setCommentList(res.data.data);
        console.log('sug', res.data.data);
      } catch (error) {
        console.log(error);
        setCommentList([]);
      }
    };
    fetchSuggestion();
  }, []);

  //validation for from to locations
  const handleCityChangeForSelectedCity = (cityValue, otherCityValue) => {
    if (cityValue && otherCityValue && cityValue.CityName === otherCityValue.CityName) {
      setValidationError('Starting city cannot be the same as ending city');
      setIsFormValid(false);
    } else {
      setValidationError('');
      setSelectedCity(cityValue);
      setIsFormValid(true);
    }
  };

  const handleCityChangeForSelectedCity1 = (cityValue, otherCityValue) => {
    if (cityValue && otherCityValue && cityValue.CityName === otherCityValue.CityName) {
      setValidationError('Starting city cannot be the same as ending city');
      setIsFormValid(false);
    } else {
      setValidationError('');
      setSelectedCity1(cityValue);
      setIsFormValid(true);
    }
  };
  //...end....

  //validation for starting and closing kilometers
  const handleStartingKMChange = (newStartingKM) => {
    setValidationError1('');
    setStartingKM(newStartingKM);
    setIsFormValid(true);

    if (closingKM !== '' && parseFloat(newStartingKM) >= parseFloat(closingKM)) {
      setValidationError1('Starting kilometer cannot be greater than or equal to closing kilometer');
      setIsFormValid(false);
    }
  };

  const handleClosingKMChange = (newClosingKM) => {
    setValidationError1('');
    setClosingKM(newClosingKM);
    setIsFormValid(true);

    if (startingKM !== '' && parseFloat(newClosingKM) <= parseFloat(startingKM)) {
      setValidationError1('Closing kilometer cannot be less than or equal to starting kilometer');
      setIsFormValid(false);
    }
  };

  //...end...

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 2); // Subtract 2 days
    const formattedDate = currentDate.toISOString().split('T')[0]; // Get date in "YYYY-MM-DD" format
    setExpenseDate(formattedDate);
  }, []);

  const handleDateChange = (e) => {
    setExpenseDate(e.target.value);
  };

  const handleAddVendor = () => {
    setOpenDialog(true);
  };
  const handleRegistration = () => {
    setOpenDialog1(true);
  };

  const handleSaveVendor = () => {
    const sendData = new FormData()
    sendData.append('VendorName', vendorName);
    sendData.append('VendorCompany', vendorCompany);
    sendData.append('VendorAddress', vendorAddress);
    sendData.append('VendorGST', vendorGst);
    sendData.append('VendorMobile', vendorMobile);
    sendData.append('VendorEmail', vendorEmail);
    sendData.append('VendorService', vendorServices);
    axios({
      method: methodPost,
      url: addvendor,
      data: sendData
    })
      .then((res) => {
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          handleClose();
        } else {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  };
  const handleClose = () => {
    setOpenDialog(false);
    setVendorName('');
    setVendorCompany('');
    setVendorAddress('');
    setVendorGst('');
    setVendorMobile('');
    setVendorEmail('');
    setVendorServices('');
  }
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveRegistration = () => {
    const sendData = new FormData()
    sendData.append('OwnerName', ownerName);
    sendData.append('OwnerContact', ownerContact);
    sendData.append('RegisterationNumber', addRegistrationNumber);
    sendData.append('VechicalDetails', vehicleDetails);
    axios({
      method: methodPost,
      url: addOwner,
      data: sendData
    })
      .then((res) => {
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          handleClose1();
        } else {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  };


  const handleClose1 = () => {
    setOpenDialog(false);
    setOwnerName('');
    setOwnerContact('');
    setAddRegistrationNumber('');
    setvehicleDetails('');
  }

  const handleCloseDialog1 = () => {
    setOpenDialog1(false);
    setOwnerName('');
    setOwnerContact('');
    setAddRegistrationNumber('');
    setvehicleDetails('');
  };


  const fetchRegistrationList = () => {
    const sendData = new FormData();
    sendData.append('RegisterationNumber', '')
    axios({
      method: methodPost,
      url: getAllOwner,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setStatus(false);
          // setColor(false);
          setRegistrationDropdown([])
        } else {
          // setMessage(res.data.message);
          setRegistrationDropdown(res.data.data);
          // setStatus(true);
          // setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
        console.log("chip1", err);
      });
  }
  useEffect(() => {
    fetchRegistrationList();
  }, []);


  useEffect(() => {
    const sendData = new FormData();
    sendData.append('CityName', selectedCity == null ? "" : selectedCity?.CityName)
    sendData.append('page', 1);
    sendData.append('perPage', 6000);
    axios({
      method: methodPost,
      url: getCity,
      data: sendData,
    })
      .then((res) => {
        if (res.data && res.data.data) {
          setCityOptions(res.data.data);
        } else {
          console.log(res.data.message)
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  }, []);

  const fetchVendorList = () => {
    const sendData = new FormData();
    sendData.append('VendorName', '')
    sendData.append('page', 1)
    sendData.append('perPage', 1000)
    axios({
      method: methodPost,
      url: getVendorDropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setStatus(false);
          // setColor(false);
          setVendorDropdown([])
        } else {
          // setMessage(res.data.message);
          setVendorDropdown(res.data.data);
          // setStatus(true);
          // setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
        console.log("chip1", err);
      });
  }
  useEffect(() => {
    fetchVendorList();
  }, []);



  const handleEmpChange = (event, value) => {
    setEmpId(value);
    if (value) {
      fetchProjectCode(value.EmployeeId);
    } else {
      setProjectList([]);
    }
  };


  const fetchProjectCode = async (Emp) => {
    const sendData = new FormData()
    sendData.append("EmployeeId", Emp)
    try {
      const res = await axios.post(getEmployeeProjectCode, sendData)
      setProjectList(res.data.data)
    } catch (error) {
      setProjectList([])
    }
  }


  useEffect(() => {
    const sendData = new FormData();
    sendData.append('ExpenseCategoryId', 3)
    axios({
      method: methodPost,
      url: fetch_sub1_category,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setStatus(false);
          // setColor(false);
          setTypeList([])
        } else {
          // setMessage(res.data.message);
          setTypeList(res.data.data);
          // setStatus(true);
          // setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
        console.log("chip1", err);
      });
  }, [Type]);

  useEffect(() => {
    if (Type?.isSubCategory === 1 && Type?.isSubCategory !== null) {
      setCategory1(null);
      const sendData = new FormData();
      sendData.append('ExpenseCategoryId', 3)
      sendData.append('Sub1CategoryId', Type?.Sub1CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub2_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            // setShowCategory1(false);
            setCategory1List([])
          } else {
            // setMessage(res.data.message);
            setCategory1List(res.data.data);
            setShowCategory1(true)
            // setStatus(true);
            // setColor(true);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
    else {
      setShowCategory1(false);
    }
  }, [Type]);


  useEffect(() => {
    if (category1?.isSubCategory === 1 && category1?.isSubCategory !== null) {
      setCategory2(null);
      const sendData = new FormData();
      sendData.append('Sub2CategoryId', category1?.Sub2CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub3_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            // setShowCategory2(false)
            setCategory2List([])
          } else {
            // setMessage(res.data.message);
            setCategory2List(res.data.data);
            // setStatus(true);
            // setColor(true);
            setShowCategory2(true)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
    else {
      setShowCategory2(false)
    }
  }, [category1]);

  useEffect(() => {
    if (category2?.isSubCategory === 1 && category2?.isSubCategory !== null) {
      setCategory3(null)
      const sendData = new FormData();
      sendData.append('Sub3CategoryId', category2?.Sub3CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub4_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            setCategory3List([])
            // setShowCategory3(false)
          } else {
            // setMessage(res.data.message);
            setCategory3List(res.data.data);
            // setStatus(true);
            // setColor(true);
            setShowCategory3(true)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
          console.log("chip1", err);
        });
    }
    else {
      setShowCategory3(false)
    }
  }, [category2]);


  useEffect(() => {
    if (category3?.isSubCategory === 1 && category3?.isSubCategory !== null) {
      setCategory4(null)
      const sendData = new FormData();
      sendData.append('Sub4CategoryId', category3?.Sub4CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub5_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            setCategory4List([])
            // setShowCategory4(false)
          } else {
            // setMessage(res.data.message);
            setCategory4List(res.data.data);
            // setStatus(true);
            // setColor(true);
            setShowCategory4(true)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
          console.log("chip1", err);
        });
    }
    else {
      setShowCategory4(false)
    }
  }, [category3]);


  useEffect(() => {
    if (category4?.isSubCategory === 1 && category4?.isSubCategory !== null) {
      setCategory4(null)
      const sendData = new FormData();
      sendData.append('Sub5CategoryId', category4?.Sub5CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub6_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            setCategory5List([])
            setShowCategory5(false)
          } else {
            // setMessage(res.data.message);
            setCategory5List(res.data.data);
            // setStatus(true);
            // setColor(true);
            setShowCategory5(true)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
          console.log("chip1", err);
        });
    }
    else {
      setShowCategory5(false)
    }
  }, [category4]);


  useEffect(() => {
    axios({
      method: methodGet,
      url: getAllEmployeeName,
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setOpen(true);
          // setStatus(false);
          // setColor(false);
        } else {
          // setMessage(res.data.message);
          setEmpList(res.data.data);
          // setOpen(true);
          // setStatus(true);
          // setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  useEffect(() => {
    axios({
      method: methodGet,
      url: getLedgerNameDropdown,
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setStatus(false);
          // setColor(false);
        } else {
          // setMessage(res.data.message);
          setLedgerList(res.data.data);
          // setStatus(true);
          // setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  useEffect(() => {
    const sendData = new FormData();
    sendData.append('LedgerNameId', (ManualLedgerName === null && LedgerName !== undefined && LedgerName !== null) ? LedgerName?.LedgerNameId : "");
    axios({
      method: methodPost,
      url: getGroupsDropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setStatus(false);
          // setColor(false);
        } else {
          // setMessage(res.data.message);
          setGroupList(res.data.data);
          if (LedgerName !== null) {
            setGroup(res.data.data[0])
          }
          else {
            setGroup(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [LedgerName]);





  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerGroupsId', (Manualgroup === null && group !== undefined && group !== null) ? group?.LedgerGroupsId : "")
    axios({
      method: methodPost,
      url: getLedgerCategory1Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setStatus(false);
          // setColor(false);
        } else {
          // setMessage(res.data.message);
          setCategory1List(res.data.data);
          if (group !== null) {
            setLedgerCategory1(res.data.data[0])
          }
          else {
            setLedgerCategory1(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [group]);

  console.log("Ledger", LedgerName, "group", group)
  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId1', (Manualcategory1 === null && ledgerCategory1 !== undefined && ledgerCategory1 !== null) ? ledgerCategory1?.LedgerCategoryId1 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory2Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          if (ledgerCategory1 !== null) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
          }
        } else {
          // setMessage(res.data.message);
          setCategory2List(res.data.data);
          if (ledgerCategory1 !== null) {
            setLedgerCategory2(res.data.data[0])
          }
          else {
            setLedgerCategory2(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [ledgerCategory1]);

  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId2', (Manualcategory2 === null && ledgerCategory2 !== undefined && ledgerCategory2 !== null) ? ledgerCategory2?.LedgerCategoryId2 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory3Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          if (ledgerCategory2 !== null) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
          }
        } else {
          // setMessage(res.data.message);
          setCategory3List(res.data.data);
          if (ledgerCategory2 !== null) {
            setLedgerCategory3(res.data.data[0])
          }
          else {
            setLedgerCategory3(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [ledgerCategory2]);

  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId3', (Manualcategory3 === null && ledgerCategory3 !== undefined && ledgerCategory3 !== null) ? ledgerCategory3?.LedgerCategoryId3 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory4Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          if (ledgerCategory3 !== null) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
          }
        } else {
          // setMessage(res.data.message);
          setCategory4List(res.data.data);
          if (ledgerCategory3 !== null) {
            setLedgerCategory4(res.data.data[0])
          }
          else {
            setLedgerCategory4(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [ledgerCategory3]);

  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId4', (Manualcategory4 === null && ledgerCategory4 !== undefined && ledgerCategory4 !== null) ? ledgerCategory4?.LedgerCategoryId4 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory5Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          if (ledgerCategory4 !== null) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
          }
        } else {
          // setMessage(res.data.message);
          setCategory5List(res.data.data);
          if (ledgerCategory4 !== null) {
            setLedgerCategory5(res.data.data[0])
          }
          else {
            setLedgerCategory5(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [ledgerCategory4]);

  useEffect(() => {
    const parseTollCharge = parseFloat(tollCharges) || 0;
    const parseTicket = parseFloat(ticketCharges) || 0;
    const parseDriver = parseFloat(driverTips) || 0;
    const calculateTotal = Math.floor(parseDriver + parseTicket + parseTollCharge);

    if (parseFloat(advance) > calculateTotal) {
      setValidationError2('Advance amount cannot be higher than the total amount');
      setIsFormValid(false);
    } else {
      const remainingBalance = Math.max(calculateTotal - parseFloat(advance) || 0, 0);
      setAmount(calculateTotal.toString());
      setBalanceAmount(remainingBalance.toString());
      setValidationError2('');
      setIsFormValid(true);
    }
  }, [tollCharges, ticketCharges, driverTips, advance]);

  const onSubmit = () => {
    const obj = {
      EmployeeId: EmpId?.EmployeeId,
      projectCode: projectCode,
      category1: "Travel",
      category2: Type?.Sub1CategoryName !== undefined ? Type?.Sub1CategoryName : "",
      category3: category1?.Sub2CategoryName !== undefined ? category1?.Sub2CategoryName : "",
      category4: category2?.Sub3CategoryName !== undefined ? category2?.Sub3CategoryName : "",
      category5: category3?.Sub4CategoryName !== undefined ? category3?.Sub4CategoryName : "",
      category6: category4?.Sub5CategoryName !== undefined ? category4?.Sub5CategoryName : "",
      from: selectedCity?.CityName,
      to: selectedCity1?.CityName,
      startingKM: startingKM,
      closingKM: closingKM,
      tollCharges: tollCharges,
      ticketCharges: ticketCharges,
      driverTips: driverTips,
      VendorName: selectedVendor?.VendorName,
      comments: comments,
      RentedOrOwned: rentedOrOwned,
      RegisterationNumber: selectedRegistration,
      Advance: advance,
      BalanceAmount: balanceAmount,
      PerDuration: duration,
      Rate: rate,
      PerDurationType: rentType,
      amount: amount,
      Others: otherCategory,
      Images: imagesProof == null ? '' : imagesProof,
      AudioFile: '',
      expenseAmount: advance,
      expenseDate: expenseDate,
      addedFrom: "web",
      expenseStatus: "1",
      OverallExpenseStatus:"1",
      Types: gst,
      SGST: sgst,
      CGST: cgst,
      IGST: igst,
      LedgerNameId: LedgerName?.LedgerNameId || null,
      LedgerName: ManualLedgerName !== null ? ManualLedgerName : LedgerName?.LedgerName ? LedgerName?.LedgerName : null,
      LedgerGroups: Manualgroup != null ? Manualgroup : group?.LedgerGroups ? group?.LedgerGroups : null,
      LedgerCategory1: Manualcategory1 != null ? Manualcategory1 : ledgerCategory1?.LedgerCategory1 ? ledgerCategory1?.LedgerCategory1 : null,
      LedgerCategory2: Manualcategory2 != null ? Manualcategory2 : ledgerCategory2?.LedgerCategory2 ? ledgerCategory2?.LedgerCategory2 : null,
      LedgerCategory3: Manualcategory3 != null ? Manualcategory3 : ledgerCategory3?.LedgerCategory3 ? ledgerCategory3?.LedgerCategory3 : null,
      LedgerCategory4: Manualcategory4 !== null ? Manualcategory4 : ledgerCategory4?.LedgerCategory4 ? ledgerCategory4?.LedgerCategory4 : null,
      LedgerCategory5: Manualcategory5 !== null ? Manualcategory5 : ledgerCategory5?.LedgerCategory5 ? ledgerCategory5?.LedgerCategory5 : null,
    }
    if (isFormValid) {
      const ldata = appendData(obj);
      axios({
        method: 'POST',
        url: insertTravelExpense,
        data: ldata,
      }).then(res => {
        if (!res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          setEmpId(null);
          setEmpname(null);
          setProjectCode(null);
          setProjectList(null);
          setType(null);
          setTypeList(null);
          setCategory1(null);
          setCategory2(null);
          setCategory3(null);
          setCategory4(null);
          setCategory5(null);
          setFromDate('');
          setToDate('');
          setAmount('');
          setAdvance('');
          setBalanceAmount('');
          setStartingKM('');
          setClosingKM('');
          setComments('');
          setTicketCharges('');
          setRentedOrOwned(null)
          setDuration('');
          setRate('');
          setSelectedVendor(null)
          setTollCharges('');
          setRentType('')
          setDriverTips('')
          setSelectedCity(null);
          setSelectedCity1(null);
          setManualCategory1(null);
          setManualCategory2(null);
          setManualCategory3(null);
          setManualCategory4(null);
          setManualCategory5(null);
          setManualLedgerName(null);
          setManualGroup(null);
          setGroup(null);
          setIGST('');
          setCGST('');
          setSGST('');
          setLedgerName(null);
          setProjectList([]);
          setInput(true);
          setTypeList([]);
          setCategory1List([]);
          setCategory2List([]);
          setCategory3List([]);
          setCategory4List([]);
          setCategory5List([]);
          setShowCategory1(false);
          setShowCategory2(false);
          setShowCategory3(false);
          setShowCategory4(false);
          setShowCategory5(false);
          setShowRent(false);
          document.getElementById('imagesProof').value = '';
          setLedgerCategory1(null);
          setLedgerCategory2(null);
          setLedgerCategory3(null);
          setLedgerCategory4(null);
          setLedgerCategory5(null);
        } else {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
          setInput(false);
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    } else {
      alert('Form validation failed. Please correct the errors.');
    }
  }
  return (
    <Box>
      <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
      <Container>
        <Box component={Card} p={4}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  size='small'
                  id="combo-box-demo"
                  options={EmpList}
                  value={EmpId}
                  getOptionLabel={(option) => `${option.EmployeeName} (${option.EmployeeId})`}
                  onChange={handleEmpChange}
                  sx={{ my: 1 }}
                  renderInput={(params) => <TextField color='secondary' label='Employee Name' {...params} required />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={ProjectList.map((i) => i.projectCode)}
                  onChange={(event, value) => setProjectCode(value)}
                  size='small'
                  renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Project Code' color='secondary' {...params} required />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={TypeList}
                  getOptionLabel={(option) => option.Sub1CategoryName}
                  onChange={(event, value) => setType(value)}
                  size='small'
                  renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Select Type' color='secondary' {...params} required />}

                />
              </Grid>

              {
                showCategory1 &&
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category1List}
                    value={category1}
                    getOptionLabel={(option) => option.Sub2CategoryName}
                    onChange={(event, value) => setCategory1(value)}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 1' color='secondary' {...params} />}
                  />
                </Grid>
              }

              {
                showCategory2 &&
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category2List}
                    value={category2}
                    getOptionLabel={(option) => option.Sub3CategoryName}
                    onChange={(event, value) => {
                      setCategory2(value);
                      setShowRent(value?.isSubCategory === 1 ? false : true);
                    }}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 2' color='secondary' {...params} />}
                  />
                </Grid>
              }

              {
                showCategory3 &&
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category3List}
                    value={category3}
                    getOptionLabel={(option) => option.Sub4CategoryName}
                    onChange={(event, value) => {
                      setCategory3(value)
                      setShowRent(value?.isSubCategory === 1 ? false : true);
                    }}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 3' color='secondary' {...params} />}
                  />
                </Grid>
              }
              {
                showCategory4 &&
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category4List}
                    value={category4}
                    getOptionLabel={(option) => option.Sub5CategoryName}
                    onChange={(event, value) => {
                      setCategory4(value)
                      setShowRent(value?.isSubCategory === 1 ? false : true);
                    }}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 4' color='secondary' {...params} />}
                  />
                </Grid>
              }
              {
                showCategory5 &&
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category5List}
                    value={category5}
                    getOptionLabel={(option) => option.Sub6CategoryName}
                    onChange={(event, value) => {
                      setCategory5(value)
                      setShowRent(value?.isSubCategory === 1 ? false : true);
                    }}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 5' color='secondary' {...params} />}
                  />
                </Grid>
              }
              {
                (Type?.Sub1CategoryName === 'Bus' || Type?.Sub1CategoryName === 'Rail' || Type?.Sub1CategoryName === 'Air-way' || Type?.Sub1CategoryName === 'Sea-way' || Type?.Sub1CategoryName === 'Car' || Type?.Sub1CategoryName === 'Van' || Type?.Sub1CategoryName === 'Two Wheeler' || Type?.Sub1CategoryName === 'Three Wheeler' || Type?.Sub1CategoryName === 'Others') ?
                  <>
                    {Type?.Sub1CategoryName === 'Others' &&

                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <TextField
                          label="Other Category"
                          value={otherCategory}
                          onChange={(e) => setOtherCategory(e.target.value)}
                          fullWidth
                          sx={{ my: 1 }}
                          size='small'
                        />
                      </Grid>
                    }
                    {
                      (Type?.Sub1CategoryName !== 'Passenger Vehicle' && Type !== '' && Type !== null) &&
                      <>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <Autocomplete
                            disablePortal
                            size='small'
                            id="combo-box-from"
                            options={cityOptions}
                            value={selectedCity}
                            getOptionLabel={(option) => option.CityName}
                            onChange={(event, value) => handleCityChangeForSelectedCity(value, selectedCity1)}
                            sx={{ my: 1 }}
                            renderInput={(params) => (
                              <TextField color='secondary' label='From' {...params} />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <Autocomplete
                            disablePortal
                            size='small'
                            id="combo-box-to"
                            options={cityOptions}
                            value={selectedCity1}
                            getOptionLabel={(option) => option.CityName}
                            onChange={(event, value) => handleCityChangeForSelectedCity1(value, selectedCity)}
                            sx={{ my: 1 }}
                            renderInput={(params) => (
                              <TextField color='secondary' label='To Location' {...params} />
                            )}
                          />
                          {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
                        </Grid>
                      </>
                    }

                    {
                      (Type?.Sub1CategoryName !== 'Bus' && Type?.Sub1CategoryName !== 'Rail' && Type?.Sub1CategoryName !== 'Air-way' && Type?.Sub1CategoryName !== 'Sea-way' && Type !== '' && Type !== null) &&
                      <>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            name="startingKM"
                            label="Starting KM"
                            value={startingKM}
                            fullWidth
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                                handleStartingKMChange(newValue);
                              } else {
                                setValidationError('Invalid input. Please enter a valid number.');
                                setIsFormValid(false);
                              }
                            }}
                            size='small'
                            sx={{ my: 1 }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            name="closingKM"
                            label="Closing KM"
                            value={closingKM}
                            fullWidth
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                                handleClosingKMChange(newValue);
                              } else {
                                setValidationError('Invalid input. Please enter a valid number.');
                                setIsFormValid(false);
                              }
                            }}
                            size='small'
                            sx={{ my: 1 }}
                          />

                          {validationError1 && (
                            <Grid item xs={12}>
                              <div style={{ color: 'red' }}>{validationError1}</div>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            key={input}
                            options={['Rented', "Owned"]}
                            value={rentedOrOwned}
                            onChange={(event, value) => setRentedOrOwned(value)}
                            size='small'
                            renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Rent or Own' color='secondary' {...params} />}
                          />
                        </Grid>
                        {rentedOrOwned === 'Rented' &&
                          <>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                key={input}
                                options={['Per Hour', "Day", "Month Basis"]}
                                value={rentType}
                                onChange={(event, value) => setRentType(value)}
                                size='small'
                                renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Rent Type' color='secondary' {...params} />}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <TextField
                                label="Duration"
                                value={duration}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  const regex = /^[0-9]*$/;
                                  if (regex.test(input)) {
                                    setDuration(e.target.value)
                                  }
                                }}
                                fullWidth
                                sx={{ my: 1 }}
                                size='small'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <TextField
                                label="Rate"
                                value={rate}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  const regex = /^[0-9]*$/;
                                  if (regex.test(input)) {
                                    setRate(e.target.value)
                                  }
                                }}
                                fullWidth
                                sx={{ my: 1 }}
                                size='small'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <Grid container>
                                <Grid xs={10} sm={10} md={10} lg={10}>
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    key={input}
                                    value={selectedVendor}
                                    options={vendorDropdown}
                                    getOptionLabel={(option => option.VendorName)}
                                    onChange={(event, value) => setSelectedVendor(value)}
                                    size='small'
                                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Vendor' color='secondary' {...params} />}
                                    required
                                  />
                                </Grid>
                                <Grid xs={2} sm={2} md={2} lg={2}>
                                  <InputAdornment sx={{ marginTop: '28px' }} position="end">
                                    <IconButton onClick={handleAddVendor}>
                                      <AddIcon />
                                    </IconButton>
                                  </InputAdornment>
                                </Grid>
                              </Grid>

                            </Grid>

                            <Dialog open={openDialog} onClose={handleCloseDialog}>
                              <DialogTitle>Add Vendor Details</DialogTitle>
                              <DialogContent>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor Name"
                                      sx={{ my: 1 }}
                                      value={vendorName}
                                      onChange={(e) => setVendorName(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor Company"
                                      sx={{ my: 1 }}
                                      value={vendorCompany}
                                      onChange={(e) => setVendorCompany(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor Address"
                                      sx={{ my: 1 }}
                                      value={vendorAddress}
                                      onChange={(e) => setVendorAddress(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor GST"
                                      sx={{ my: 1 }}
                                      value={vendorGst}
                                      onChange={(e) => setVendorGst(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor Mobile"
                                      sx={{ my: 1 }}
                                      value={vendorMobile}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const regex = /^[0-9]*$/;
                                        if (regex.test(input)) { setVendorMobile(e.target.value) }
                                      }}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor Email"
                                      sx={{ my: 1 }}
                                      value={vendorEmail}
                                      onChange={(e) => setVendorEmail(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor Services"
                                      sx={{ my: 1 }}
                                      value={vendorServices}
                                      onChange={(e) => setVendorServices(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                </Grid>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleCloseDialog}>Cancel</Button>
                                <Button onClick={handleSaveVendor}>Add</Button>
                              </DialogActions>
                            </Dialog>

                          </>
                        }
                        {rentedOrOwned === 'Owned' &&
                          <>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <Grid container>
                                <Grid xs={10} sm={10} md={10} lg={10}>
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    key={input}
                                    value={selectedRegistration}
                                    options={registrationDropdown}
                                    getOptionLabel={(option => option.registeration_number)}
                                    onChange={(event, value) => setSelectedRegistration(value)}
                                    size='small'
                                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Registration Number' color='secondary' {...params} />}
                                    required
                                  />
                                </Grid>
                                <Grid xs={2} sm={2} md={2} lg={2}>
                                  <InputAdornment sx={{ marginTop: '28px' }} position="end">
                                    <IconButton onClick={handleRegistration}>
                                      <AddIcon />
                                    </IconButton>
                                  </InputAdornment>
                                </Grid>
                              </Grid>

                            </Grid>

                            <Dialog open={openDialog1} onClose={handleCloseDialog1}>
                              <DialogTitle>Add Owner Details</DialogTitle>
                              <DialogContent>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Owner Name"
                                      sx={{ my: 1 }}
                                      value={ownerName}
                                      onChange={(e) => setOwnerName(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Owner Contact"
                                      sx={{ my: 1 }}
                                      value={ownerContact}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const regex = /^[0-9]*$/;
                                        if (regex.test(input)) { setOwnerContact(e.target.value) }
                                      }}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Registration Number"
                                      sx={{ my: 1 }}
                                      value={addRegistrationNumber}
                                      onChange={(e) => setAddRegistrationNumber(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vehicles Details"
                                      sx={{ my: 1 }}
                                      value={vehicleDetails}
                                      onChange={(e) => setvehicleDetails(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                </Grid>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleCloseDialog1}>Cancel</Button>
                                <Button onClick={handleSaveRegistration}>Add</Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        }
                      </>
                    }
                    {
                      (Type?.Sub1CategoryName === 'Car' || Type?.Sub1CategoryName === 'Van' || Type?.Sub1CategoryName === 'Others') &&
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <TextField
                          label="Toll Charges"
                          value={tollCharges}
                          onChange={(e) => {
                            const input = e.target.value;
                            const regex = /^[0-9]*$/;
                            if (regex.test(input)) {
                              setTollCharges(e.target.value)
                            }
                          }}
                          fullWidth
                          sx={{ my: 1 }}
                          size='small'
                        />
                      </Grid>

                    }
                    {
                      (Type?.Sub1CategoryName === 'Bus' || Type?.Sub1CategoryName === 'Rail' || Type?.Sub1CategoryName === 'Air-way' || Type?.Sub1CategoryName === 'Sea-way') &&
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <TextField
                          label="Ticket Charges"
                          value={ticketCharges}
                          type='text'
                          onChange={(e) => {
                            const input = e.target.value;
                            const regex = /^[0-9]*$/;
                            if (regex.test(input)) {
                              setTicketCharges(e.target.value)
                            }
                          }}
                          fullWidth
                          size='small'
                          sx={{ my: 1 }}
                        />
                      </Grid>
                    }
                    {
                      (Type?.Sub1CategoryName !== 'Bus' && Type?.Sub1CategoryName !== 'Rail' && Type?.Sub1CategoryName !== 'Air-way' && Type?.Sub1CategoryName !== 'Sea-way' && Type !== '' && Type !== null) &&
                      <>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            label="Travel Charges"
                            value={ticketCharges}
                            type='text'
                            onChange={(e) => {
                              const input = e.target.value;
                              const regex = /^[0-9]*$/;
                              if (regex.test(input)) {
                                setTicketCharges(e.target.value)
                              }
                            }}
                            fullWidth
                            size='small'
                            sx={{ my: 1 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            label="Driver Tips"
                            value={driverTips}
                            onChange={(e) => {
                              const input = e.target.value;
                              const regex = /^[0-9]*$/;
                              if (regex.test(input)) {
                                setDriverTips(e.target.value)
                              }
                            }}
                            fullWidth
                            sx={{ my: 1 }}
                            size='small'
                          />
                        </Grid>
                      </>
                    }
                    {
                      (Type !== '' && Type !== null) &&
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <TextField
                          name="totalCharges"
                          label="Total Charges"
                          value={amount}
                          fullWidth
                          sx={{ my: 1 }}
                          size='small'
                        />
                      </Grid>
                    }
                    {
                      (Type !== '' && Type !== null) &&
                      <>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            label="Curently Paid Amount"
                            value={advance}
                            onChange={(e) => {
                              const input = e.target.value;
                              const regex = /^[0-9]*$/;
                              if (regex.test(input)) {
                                setAdvance(e.target.value)
                              }
                            }}
                            fullWidth
                            sx={{ my: 1 }}
                            size='small'
                          />
                          {validationError2 && <p style={{ color: 'red' }}>{validationError2}</p>}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            label="Balance Amount"
                            value={balanceAmount}
                            onChange={(e) => {
                              const input = e.target.value;
                              const regex = /^[0-9]*$/;
                              if (regex.test(input)) {
                                setBalanceAmount(e.target.value)
                              }
                            }}
                            fullWidth
                            sx={{ my: 1 }}
                            size='small'
                          />

                        </Grid>
                      </>
                    }

                  </>
                  :

                  <>
                    {((Type !== '' && Type !== null) &&
                      <>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <Autocomplete
                            disablePortal
                            size='small'
                            id="combo-box-from"
                            options={cityOptions}
                            value={selectedCity}
                            getOptionLabel={(option) => option.CityName}
                            onChange={(event, value) => handleCityChangeForSelectedCity(value, selectedCity1)}
                            sx={{ my: 1 }}
                            renderInput={(params) => (
                              <TextField color='secondary' label='From' {...params} />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <Autocomplete
                            disablePortal
                            size='small'
                            id="combo-box-to"
                            options={cityOptions}
                            value={selectedCity1}
                            getOptionLabel={(option) => option.CityName}
                            onChange={(event, value) => handleCityChangeForSelectedCity1(value, selectedCity)}
                            sx={{ my: 1 }}
                            renderInput={(params) => (
                              <TextField color='secondary' label='To Location' {...params} />
                            )}
                          />
                          {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            name="startingKM"
                            label="Starting KM"
                            value={startingKM}
                            fullWidth
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                                handleStartingKMChange(newValue);
                              } else {
                                setValidationError('Invalid input. Please enter a valid number.');
                                setIsFormValid(false);
                              }
                            }}
                            size='small'
                            sx={{ my: 1 }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            name="closingKM"
                            label="Closing KM"
                            value={closingKM}
                            fullWidth
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                                handleClosingKMChange(newValue);
                              } else {
                                setValidationError('Invalid input. Please enter a valid number.');
                                setIsFormValid(false);
                              }
                            }}
                            size='small'
                            sx={{ my: 1 }}
                          />

                          {validationError1 && (
                            <Grid item xs={12}>
                              <div style={{ color: 'red' }}>{validationError1}</div>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            key={input}
                            options={['Rented', "Owned"]}
                            value={rentedOrOwned}
                            onChange={(event, value) => setRentedOrOwned(value)}
                            size='small'
                            renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Rent or Own' color='secondary' {...params} />}
                          />
                        </Grid>
                        {rentedOrOwned === 'Rented' &&
                          <>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                key={input}
                                options={['Per Hour', "Day", "Month Basis"]}
                                value={rentType}
                                onChange={(event, value) => setRentType(value)}
                                size='small'
                                renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Rent Type' color='secondary' {...params} />}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <TextField
                                label="Duration"
                                value={duration}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  const regex = /^[0-9]*$/;
                                  if (regex.test(input)) {
                                    setDuration(e.target.value)
                                  }
                                }}
                                fullWidth
                                sx={{ my: 1 }}
                                size='small'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <TextField
                                label="Rate"
                                value={rate}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  const regex = /^[0-9]*$/;
                                  if (regex.test(input)) {
                                    setRate(e.target.value)
                                  }
                                }}
                                fullWidth
                                sx={{ my: 1 }}
                                size='small'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <Grid container>
                                <Grid xs={10} sm={10} md={10} lg={10}>
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    key={input}
                                    value={selectedVendor}
                                    options={vendorDropdown}
                                    getOptionLabel={(option => option.VendorName)}
                                    onChange={(event, value) => setSelectedVendor(value)}
                                    size='small'
                                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Vendor' color='secondary' {...params} />}
                                    required
                                  />
                                </Grid>
                                <Grid xs={2} sm={2} md={2} lg={2}>
                                  <InputAdornment sx={{ marginTop: '28px' }} position="end">
                                    <IconButton onClick={handleAddVendor}>
                                      <AddIcon />
                                    </IconButton>
                                  </InputAdornment>
                                </Grid>
                              </Grid>

                            </Grid>

                            <Dialog open={openDialog} onClose={handleCloseDialog}>
                              <DialogTitle>Add Vendor Details</DialogTitle>
                              <DialogContent>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor Name"
                                      sx={{ my: 1 }}
                                      value={vendorName}
                                      onChange={(e) => setVendorName(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor Company"
                                      sx={{ my: 1 }}
                                      value={vendorCompany}
                                      onChange={(e) => setVendorCompany(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor Address"
                                      sx={{ my: 1 }}
                                      value={vendorAddress}
                                      onChange={(e) => setVendorAddress(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor GST"
                                      sx={{ my: 1 }}
                                      value={vendorGst}
                                      onChange={(e) => setVendorGst(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor Mobile"
                                      sx={{ my: 1 }}
                                      value={vendorMobile}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const regex = /^[0-9]*$/;
                                        if (regex.test(input)) { setVendorMobile(e.target.value) }
                                      }}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor Email"
                                      sx={{ my: 1 }}
                                      value={vendorEmail}
                                      onChange={(e) => setVendorEmail(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vendor Services"
                                      sx={{ my: 1 }}
                                      value={vendorServices}
                                      onChange={(e) => setVendorServices(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                </Grid>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleCloseDialog}>Cancel</Button>
                                <Button onClick={handleSaveVendor}>Add</Button>
                              </DialogActions>
                            </Dialog>

                          </>
                        }
                        {rentedOrOwned === 'Owned' &&
                          <>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <Grid container>
                                <Grid xs={10} sm={10} md={10} lg={10}>
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    key={input}
                                    value={selectedRegistration}
                                    options={registrationDropdown}
                                    getOptionLabel={(option => option.registeration_number)}
                                    onChange={(event, value) => setSelectedRegistration(value)}
                                    size='small'
                                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Registration Number' color='secondary' {...params} />}
                                    required
                                  />
                                </Grid>
                                <Grid xs={2} sm={2} md={2} lg={2}>
                                  <InputAdornment sx={{ marginTop: '28px' }} position="end">
                                    <IconButton onClick={handleRegistration}>
                                      <AddIcon />
                                    </IconButton>
                                  </InputAdornment>
                                </Grid>
                              </Grid>

                            </Grid>

                            <Dialog open={openDialog1} onClose={handleCloseDialog1}>
                              <DialogTitle>Add Owner Details</DialogTitle>
                              <DialogContent>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Owner Name"
                                      sx={{ my: 1 }}
                                      value={ownerName}
                                      onChange={(e) => setOwnerName(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Owner Contact"
                                      sx={{ my: 1 }}
                                      value={ownerContact}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const regex = /^[0-9]*$/;
                                        if (regex.test(input)) { setOwnerContact(e.target.value) }
                                      }}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Registration Number"
                                      sx={{ my: 1 }}
                                      value={addRegistrationNumber}
                                      onChange={(e) => setAddRegistrationNumber(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                      label="Vehicles Details"
                                      sx={{ my: 1 }}
                                      value={vehicleDetails}
                                      onChange={(e) => setvehicleDetails(e.target.value)}
                                      fullWidth
                                      size='small'
                                    />
                                  </Grid>
                                </Grid>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleCloseDialog1}>Cancel</Button>
                                <Button onClick={handleSaveRegistration}>Add</Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        }
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            label="Toll Charges"
                            value={tollCharges}
                            onChange={(e) => {
                              const input = e.target.value;
                              const regex = /^[0-9]*$/;
                              if (regex.test(input)) {
                                setTollCharges(e.target.value)
                              }
                            }}
                            fullWidth
                            sx={{ my: 1 }}
                            size='small'
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            label="Travel Charges"
                            value={ticketCharges}
                            type='text'
                            onChange={(e) => {
                              const input = e.target.value;
                              const regex = /^[0-9]*$/;
                              if (regex.test(input)) {
                                setTicketCharges(e.target.value)
                              }
                            }}
                            fullWidth
                            size='small'
                            sx={{ my: 1 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            label="Driver Tips"
                            value={driverTips}
                            onChange={(e) => {
                              const input = e.target.value;
                              const regex = /^[0-9]*$/;
                              if (regex.test(input)) {
                                setDriverTips(e.target.value)
                              }
                            }}
                            fullWidth
                            sx={{ my: 1 }}
                            size='small'
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            name="totalCharges"
                            label="Total Charges"
                            value={amount}
                            fullWidth
                            sx={{ my: 1 }}
                            size='small'
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            label="Curently Paid Amount"
                            value={advance}
                            onChange={(e) => {
                              const input = e.target.value;
                              const regex = /^[0-9]*$/;
                              if (regex.test(input)) {
                                setAdvance(e.target.value)
                              }
                            }}
                            fullWidth
                            sx={{ my: 1 }}
                            size='small'
                          />
                          {validationError2 && <p style={{ color: 'red' }}>{validationError2}</p>}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            label="Balance Amount"
                            value={balanceAmount}
                            onChange={(e) => {
                              const input = e.target.value;
                              const regex = /^[0-9]*$/;
                              if (regex.test(input)) {
                                setBalanceAmount(e.target.value)
                              }
                            }}
                            fullWidth
                            sx={{ my: 1 }}
                            size='small'
                          />
                        </Grid>
                      </>
                    )}
                  </>
              }
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  name="Image"
                  label="Bill"
                  type='file'
                  id='imagesProof'
                  onChange={(e) => setImagesProof(e.target.files[0])}
                  fullWidth
                  sx={{ my: 1 }}
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
              <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={comments}
                  options={commentList.map(i => i.suggestion)}
                  onChange={(event, value) => setComments(value)}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ my: 1 }}
                      fontSize="small"
                      label="Comments"
                      color="secondary"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  name="expenseDate"
                  label="Spend Date"
                  value={expenseDate}
                  type="date"
                  sx={{ my: 1 }}
                  onChange={handleDateChange}
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={options}
                  value={gst}
                  onChange={(event, value) => setGst(value)}
                  size='small'
                  autoComplete={false}
                  getOptionLabel={(option) => option} // For strings as options
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ my: 1 }}
                      fontSize='small'
                      label='GST/NON-GST'
                      color='secondary'
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </Grid>
              {
                (gst === 'GST' && gst !== null && gst !== undefined) && <>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextField
                      name="name"
                      label="SGST (%)"
                      fullWidth
                      sx={{ my: 1 }}
                      size='small'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // Regular expression for allowing decimal numbers
                        if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                          setSGST(newValue);
                        } else {
                          setSGST('');
                        }
                      }}
                      value={sgst}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextField
                      name="name"
                      label="CGST (%)"
                      fullWidth
                      size='small'
                      sx={{ my: 1 }}
                      value={cgst}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                          setCGST(newValue);
                        } else {
                          setCGST('');
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextField
                      name="name"
                      label="IGST (%)"
                      fullWidth
                      size='small'
                      sx={{ my: 1 }}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // Regular expression for allowing decimal numbers
                        if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                          setIGST(newValue);
                        } else {
                          setIGST('');
                        }
                      }}
                      value={igst}
                    />
                  </Grid>
                </>
              }

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={ledgerList}
                  value={LedgerName}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerName}
                  onChange={(event, value) => setLedgerName(value ?? value)}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualLedgerName(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Ledger' color='secondary' {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={groupList}
                  value={group}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerGroups}
                  onChange={(event, value) => setGroup(value ?? value)}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualGroup(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Select Group' color='secondary' {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category1List}
                  value={ledgerCategory1}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory1}
                  onChange={(event, value) => {
                    setCategory1(value ?? value)
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory1(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 1' color='secondary' {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category2List}
                  value={ledgerCategory2}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory2}
                  onChange={(event, value) => {
                    setCategory2(value ?? value);
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory2(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 2' color='secondary' {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category3List}
                  value={ledgerCategory3}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory3}
                  onChange={(event, value) => {
                    setCategory3(value ?? value);
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory3(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 3' color='secondary' {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category4List}
                  value={ledgerCategory4}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory4}
                  onChange={(event, value) => {
                    setCategory4(value ?? value);
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory4(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 4' color='secondary' {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category5List}
                  value={ledgerCategory5}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory5}
                  onChange={(event, value) => {
                    setCategory5(value ?? value);
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory5(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 5' color='secondary' {...params} />}
                />
              </Grid>


            </Grid>

            <Grid container display='flex' justifyContent='center' mt={1}>
              <Grid item xs={12} sm={6} md={6} lg={6} display='flex' justifyContent='center'>
                <Box display='flex' justifyContent='center' py={2}>
                  <Button variant='contained' color='secondary' type='submit' fullWidth>Submit Expense</Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </Box>

      </Container>
    </Box>
  )
}


