import React, { useState } from 'react'
import { Box, Button, Card, Grid, TextField, Typography, Container, Autocomplete, Snackbar, Alert, Grow } from "@mui/material";
import Heading from '../Components/Heading';
import Breadcrumbs from '../Components/Breadcrumbs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ProjectSiteAssignTab from '../Components/SiteProjectComps/ProjectSiteAssignTab';
import AssignedSiteProjectsTab from '../Components/SiteProjectComps/AssignedSiteProjectsTab';
import SitePage from '../Components/DashBoardComps/SiteTab';




export default function DashBoardPage() {


    const [value, setValue] = useState("1");
    const access = JSON.parse(localStorage.getItem('access'));
    const [isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen] = useState(false);
    const [Notification, setNotification] = useState("");
    const { AssingnedProjectSite, CreateProject } = access;
    // Tab functionality
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const role = JSON.parse(localStorage.getItem('role'));


    const handleCloseAddedCartSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsAddedCartSnackbarOpen(false);
    };

    function GrowTransition(props) {
        return <Grow {...props} direction="up" />;
    }


    return (
        <Box>

            <Box py={2} px={1}>
                <Breadcrumbs
                    previous={'Home'}
                    current={'Dash Board'}
                    link1={`/home`}
                    link2={'/home/dashboard'}
                    currentSection={'Dash Board'}

                />
            </Box>

            <Snackbar
                open={isAddedCartSnackbarOpen}
                autoHideDuration={1500}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                TransitionComponent={GrowTransition}
                onClose={handleCloseAddedCartSnackbar}>
                <Alert onClose={handleCloseAddedCartSnackbar} severity='success' variant="filled" sx={{ width: '100%' }}>
                    {Notification}
                </Alert>
            </Snackbar>

            <Container>
                <Box py={3}>
                    <Heading title={'Dash Board'} />
                </Box>
            </Container>


            {/* Table Panel */}

            <Container>
                <Box sx={{ p: 3 }}>
                    <TabContext value={value}>
                        <Box>
                            <TabList
                                variant="scrollable"
                                scrollButtons='auto'
                                onChange={handleChange} aria-label="tabs">

                                    <Tab
                                        sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5, }} label="Site" value="1" />
                            </TabList>
                        </Box>

                            <TabPanel value="1">
                                <Box>
                                    <SitePage />
                                </Box>
                            </TabPanel>
                    </TabContext>
                </Box>
            </Container>

        </Box>
    )
}