import React from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';

export const ExcelExport = ({ summary, fuel,rental,travel,transport, accommodation,food, maintenance, general, wages, purchase, utilization, others,fileName }) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';


    const exportToCSV = (summary, fuel,rental,travel,transport, accommodation,food, maintenance, general, wages, purchase, utilization, others) => {
        const summaryWithoutColumns = summary.map(item => {
            const { audio_file, images, ...rest } = item;
            return rest;
        });

        const ws = XLSX.utils.json_to_sheet(summaryWithoutColumns);
        const wf = XLSX.utils.json_to_sheet(fuel);
        const wr = XLSX.utils.json_to_sheet(rental);
        const wt = XLSX.utils.json_to_sheet(travel);
        const wp = XLSX.utils.json_to_sheet(transport);
        const wa = XLSX.utils.json_to_sheet(accommodation);
        const wo = XLSX.utils.json_to_sheet(food);
        const wm = XLSX.utils.json_to_sheet(maintenance);
        const wg = XLSX.utils.json_to_sheet(general);
        const ww = XLSX.utils.json_to_sheet(wages);
        const wc = XLSX.utils.json_to_sheet(purchase);
        const wu = XLSX.utils.json_to_sheet(utilization);
        const wh = XLSX.utils.json_to_sheet(others);
        const wb = {
            Sheets: { 'Summary': ws, 'Fuel': wf,'Rental': wr,'Travel': wt,'Transport': wp, 'Accommodation': wa,'Food': wo, 'Maintenance': wm, 'General': wg, 'Wages': ww, 'Purchase': wc, 'Utilization': wu, 'Others': wh},
            SheetNames: ['Summary', 'Fuel','Rental','Travel','Transport', 'Accommodation','Food', 'Maintenance', 'General', 'Wages', 'Purchase', 'Utilization', 'Others'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button variant="contained" sx={{my:1}}
            onClick={(e) => exportToCSV(summary, fuel,rental,travel,transport, accommodation,food, maintenance, general, wages, purchase, utilization, others, fileName)}
            startIcon={<ExitToAppIcon />}
        >
            Export
        </Button>
    )
}