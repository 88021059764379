import React from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container} from "@mui/material";
import Heading from '../Heading';
import Breadcrumbs from '../Breadcrumbs';
import { financepageControllerView, financepageEmployeeView } from '../../Variables/Variables';
import { Outlet, useNavigate } from 'react-router-dom';
import IconBreadcrumbs from '../Breadcrumbs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import FuelCreation from './CreateNewExpense/FuelCreation';
import RentalCreation from './CreateNewExpense/RentalCreation';
import TravelCreation from './CreateNewExpense/TravelCreation';
import TransportCreation from './CreateNewExpense/TransportCreation';
import AccomadateCreation from './CreateNewExpense/AccomadateCreation';
import FoodCreation from './CreateNewExpense/FoodCreation';
import MaintenanceCreation from './CreateNewExpense/MaintenanceCreation';
import GeneralCreation from './CreateNewExpense/GeneralCreation';
import WageCreation from './CreateNewExpense/WageCreation';
import PurchaseCreation from './CreateNewExpense/PurchaseCreation';
import UtilCreation from './CreateNewExpense/UtilCreation';
import OtherCreation from './CreateNewExpense/OtherCreation';
import CategoriesCreation from './CreateNewExpense/CategoriesCreation';



export default function CreateNewExpensesTab() {

  const [value, setValue] = useState("1");

  // Tab functionality
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };


    const role = JSON.parse(localStorage.getItem('role'));


  return (
    <Box>
    <Box>
    <TabContext value={value}>
      <Box>
          <TabList
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          onChange={handleChange} aria-label="tabs">
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Fuel" value="1" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="Rental" value="2" />
           <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Travel" value="3" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="Transport" value="4" />
           <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Accomodation" value="5" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="Food" value="6" />
           <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Repairs" value="7" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="General" value="8" />
           <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Wages" value="9" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="Purchases" value="10" />
           <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Utilization" value="11" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="Other" value="12" />
          </TabList>
          </Box>

          <TabPanel value="1">
          <Box>
          <FuelCreation />
          </Box>
          </TabPanel>

          <TabPanel value="2">
          <Box>
          <RentalCreation />
          </Box>
          </TabPanel>

          <TabPanel value="3">
          <Box>
          <TravelCreation />
          </Box>
          </TabPanel>

          <TabPanel value="4">
          <Box>
          <TransportCreation />
          </Box>
          </TabPanel>

          <TabPanel value="5">
          <Box>
          <AccomadateCreation />
          </Box>
          </TabPanel>

          <TabPanel value="6">
          <Box>
          <FoodCreation />
          </Box>
          </TabPanel>

          <TabPanel value="7">
          <Box>
          <MaintenanceCreation />
          </Box>
          </TabPanel>

          <TabPanel value="8">
          <Box>
          <GeneralCreation />
          </Box>
          </TabPanel>

          <TabPanel value="9">
          <Box>
          <WageCreation  />
          </Box>
          </TabPanel>

          <TabPanel value="10">
          <Box>
          <PurchaseCreation />
          </Box>
          </TabPanel>

          <TabPanel value="11">
          <Box>
          <UtilCreation />
          </Box>
          </TabPanel>

          <TabPanel value="12">
          <Box>
          <OtherCreation />
          </Box>
          </TabPanel>

        </TabContext>
      </Box>
  </Box>
  )
}
