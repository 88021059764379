import React, { useState } from 'react';
import { TextField, Container, Box, Button, Grid, Card, Autocomplete, IconButton, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SnackBar from '../../SnackBar';
import { add_sub1_category, add_sub2_category, add_sub3_category, add_sub4_category, add_sub5_category, addvendor, fetch_expense_category, fetch_sub1_category, fetch_sub2_category, fetch_sub3_category, fetch_sub4_category, fetch_sub5_category, fetch_sub6_category, getAllEmployeeName, getAllSiteProject, getGroupsDropdown, getLedgerCategory1Dropdown, getLedgerCategory2Dropdown, getLedgerCategory3Dropdown, getLedgerCategory4Dropdown, getLedgerCategory5Dropdown, getLedgerNameDropdown, insertFoodExpense, insertLedgerCreation, insertTransportExpense, methodGet, methodPost } from '../../../API_Service/API_Links';
import axios from 'axios';
import { useEffect } from 'react';
import { appendData } from '../../../Variables/Variables';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';

export default function CategoriesCreation() {
  const { handleSubmit, register, formState: { errors } } = useForm();

  const [sub1CategoryName, setSub1CategoryName] = useState('');
  const [sub2CategoryName, setSub2CategoryName] = useState('');
  const [sub3CategoryName, setSub3CategoryName] = useState('');
  const [sub4CategoryName, setSub4CategoryName] = useState('');
  const [sub5CategoryName, setSub5CategoryName] = useState('');

  const [sub1CategoryImage, setSub1CategoryImage] = useState('');
  const [sub2CategoryImage, setSub2CategoryImage] = useState('');
  const [sub3CategoryImage, setSub3CategoryImage] = useState('');
  const [sub4CategoryImage, setSub4CategoryImage] = useState('');
  const [sub5CategoryImage, setSub5CategoryImage] = useState('');


  const [Type, setType] = useState(null);
  const [category1, setCategory1] = useState(null);
  const [category2, setCategory2] = useState(null);
  const [category3, setCategory3] = useState(null);
  const [category4, setCategory4] = useState(null);
  const [category5, setCategory5] = useState(null);
  const [rentedOrOwned, setRentedOrOwned] = useState(null);
  const [Balance, setBalance] = useState('');

  const [people, setPeople] = useState(null);
  const [rate, setRate] = useState(null);
  const [amount, setAmount] = useState("");
  const [comments, setComments] = useState("");
  const [ImagesProof, setImagesProof] = useState(null);
  const [ExpenseDate, setExpenseDate] = useState(null);
  const options = ['GST', 'NON-GST'];
  const [gst, setGst] = useState('NON-GST');
  const [sgst, setSGST] = useState('');
  const [cgst, setCGST] = useState('');
  const [igst, setIGST] = useState('');
  const [ProjectList, setProjectList] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [input, setInput] = useState(false);
  const [EmpList, setEmpList] = useState([]);
  const [expenseCategory, setExpenseCategory] = useState(null)
  const [expenseCategoryList, setExpenseCategoryList] = useState([])

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);
  const [openDialog4, setOpenDialog4] = useState(false);
  const [openDialog5, setOpenDialog5] = useState(false);

  // List
  const [groupList, setGroupList] = useState([]);
  const [ledgerList, setLedgerList] = useState([]);
  const [TypeList, setTypeList] = useState([]);
  const [Category1List, setCategory1List] = useState([]);
  const [Category2List, setCategory2List] = useState([]);
  const [Category3List, setCategory3List] = useState([]);
  const [Category4List, setCategory4List] = useState([]);
  const [Category5List, setCategory5List] = useState([]);

  // Next Field Show
  const [showCategory1, setShowCategory1] = useState(false);
  const [showCategory2, setShowCategory2] = useState(false);
  const [showCategory3, setShowCategory3] = useState(false);
  const [showCategory4, setShowCategory4] = useState(false);
  const [showCategory5, setShowCategory5] = useState(false);
  const [showRent, setShowRent] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 2);
    const formattedDate = currentDate.toISOString().split('T')[0];
    setExpenseDate(formattedDate);
  }, []);

  const handleDateChange = (e) => {
    setExpenseDate(e.target.value);
  };

  const handleAddType = () => {
    setOpenDialog(true);
  };
  const handleCloseTypeDialog = () => {
    setOpenDialog(false);
  };


  const handleAddSub1Category = () => {
    setOpenDialog1(true);
  };

  const handleCloseSUb1CategoryDialog = () => {
    setOpenDialog1(false);
  };

  const handleAddSub2Category = () => {
    setOpenDialog2(true);
  };

  const handleCloseSUb2CategoryDialog = () => {
    setOpenDialog2(false);
  };

  const handleAddSub3Category = () => {
    setOpenDialog3(true);
  };

  const handleCloseSUb3CategoryDialog = () => {
    setOpenDialog3(false);
  };

  const handleAddSub4Category = () => {
    setOpenDialog4(true);
  };

  const handleCloseSUb4CategoryDialog = () => {
    setOpenDialog4(false);
  };

  const handleAddSub5Category = () => {
    setOpenDialog5(true);
  };

  const handleCloseSUb5CategoryDialog = () => {
    setOpenDialog5(false);
  };


  const handleSaveType = () => {
    const sendData = new FormData()
    sendData.append('ExpenseCategoryId', expenseCategory?.ExpenseCategoryId)
    sendData.append('Sub1CategoryName', sub1CategoryName);
    sendData.append('Sub1CategoryImage', sub1CategoryImage);
    axios({
      method: methodPost,
      url: add_sub1_category,
      data: sendData
    })
      .then((res) => {
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          handleTypeClose();
          fetchSub1Category();
          fetchsub2Category();
        } else {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  };

  const handleTypeClose = () => {
    setOpenDialog(false);
    setSub1CategoryName('');
    document.getElementById('sub1CategoryImage').value = '';
  }

  const handleSaveCategory1 = () => {
    const sendData = new FormData()
    sendData.append('ExpenseCategoryId', expenseCategory?.ExpenseCategoryId)
    sendData.append('Sub1CategoryId', Type?.Sub1CategoryId);
    sendData.append('Sub2CategoryName', sub2CategoryName);
    sendData.append('Sub2CategoryImage', sub2CategoryImage);
    axios({
      method: methodPost,
      url: add_sub2_category,
      data: sendData
    })
      .then((res) => {
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          fetchsub2Category();
          fetchsub3Category();
          handleCategory1Close();
        } else {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  };

  const handleCategory1Close = () => {
    setOpenDialog1(false);
    setSub2CategoryName('');
    document.getElementById('sub2CategoryImage').value = '';
  }

  const handleSaveCategory2 = () => {
    const sendData = new FormData()
    sendData.append('Sub2CategoryId', category1?.Sub2CategoryId)
    sendData.append('Sub3CategoryName', sub3CategoryName);
    sendData.append('Sub3CategoryImage', sub3CategoryImage);
    axios({
      method: methodPost,
      url: add_sub3_category,
      data: sendData
    })
      .then((res) => {
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          handleCategory2Close();
          fetchsub3Category()
        } else {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  };

  const handleCategory2Close = () => {
    setOpenDialog2(false);
    setSub3CategoryName('');
    document.getElementById('sub3CategoryImage').value = '';
    fetchsub3Category();
  }

  const handleSaveCategory3 = () => {
    const sendData = new FormData()
    sendData.append('Sub3CategoryId', category2?.Sub3CategoryId)
    sendData.append('Sub4CategoryName', sub4CategoryName);
    sendData.append('Sub4CategoryImage', sub4CategoryImage);
    axios({
      method: methodPost,
      url: add_sub4_category,
      data: sendData
    })
      .then((res) => {
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          handleCategory3Close();
        } else {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  };

  const handleCategory3Close = () => {
    setOpenDialog3(false);
    setSub4CategoryName('');
    document.getElementById('sub4CategoryImage').value = '';
    fetchsub4Category();
  }

  const handleSaveCategory4 = () => {
    const sendData = new FormData()
    sendData.append('Sub4CategoryId', category3?.Sub4CategoryId)
    sendData.append('Sub5CategoryName', sub5CategoryName);
    sendData.append('Sub5CategoryImage', sub5CategoryImage);
    axios({
      method: methodPost,
      url: add_sub5_category,
      data: sendData
    })
      .then((res) => {
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          handleCategory4Close();
        } else {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  };

  const handleCategory4Close = () => {
    setOpenDialog4(false);
    setSub5CategoryName('');
    document.getElementById('sub5CategoryImage').value = '';
    fetchsub5Category();
  }

  useEffect(() => {
    axios({
      method: methodGet,
      url: fetch_expense_category,
    })
      .then((res) => {
        if (res.data.error) {
          setExpenseCategoryList([])
        } else {
          setExpenseCategoryList(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
        console.log("chip1", err);
      });
  }, []);

  const fetchSub1Category = () => {
    const sendData = new FormData();
    sendData.append('ExpenseCategoryId', expenseCategory?.ExpenseCategoryId)
    axios({
      method: methodPost,
      url: fetch_sub1_category,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setStatus(false);
          // setColor(false);
          setTypeList([])
        } else {
          // setMessage(res.data.message);
          setTypeList(res.data.data);
          // setStatus(true);
          // setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
        console.log("chip1", err);
      });
  }

  useEffect(() => {
    fetchSub1Category();
  }, [expenseCategory]);


  const fetchsub2Category = () => {
    if (Type?.isSubCategory === 1 || Type?.isSubCategory !== null) {
      setCategory1(null);
      const sendData = new FormData();
      sendData.append('ExpenseCategoryId', expenseCategory?.ExpenseCategoryId)
      sendData.append('Sub1CategoryId', Type?.Sub1CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub2_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            // setShowCategory1(false);
            setCategory1List([])
          } else {
            // setMessage(res.data.message);
            setCategory1List(res.data.data);
            setShowCategory1(true)
            // setStatus(true);
            // setColor(true);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
    else {
      setShowCategory1(false);
    }
  }

  useEffect(() => {
    fetchsub2Category();
  }, [Type]);

  const fetchsub3Category = () => {
    if (category1?.isSubCategory === 1 || category1?.isSubCategory !== null) {
      setCategory2(null);
      const sendData = new FormData();
      sendData.append('Sub2CategoryId', category1?.Sub2CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub3_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            setCategory2List([])
          } else {
            setCategory2List(res.data.data);
            setShowCategory2(true)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
    else {
      setShowCategory2(false)
    }
  }
  useEffect(() => {
    fetchsub3Category();
  }, [category1]);

  const fetchsub4Category = () => {
    if (category2?.isSubCategory === 1 || category2?.isSubCategory !== null) {
      setCategory3(null)
      const sendData = new FormData();
      sendData.append('Sub3CategoryId', category2?.Sub3CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub4_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            setCategory3List([])
            // setShowCategory3(false)
          } else {
            // setMessage(res.data.message);
            setCategory3List(res.data.data);
            // setStatus(true);
            // setColor(true);
            setShowCategory3(true)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
          console.log("chip1", err);
        });
    }
    else {
      setShowCategory3(false)
    }
  }

  useEffect(() => {
    fetchsub4Category();
  }, [category2]);

  const fetchsub5Category = () => {
    if (category3?.isSubCategory === 1 || category3?.isSubCategory !== null) {
      setCategory4(null)
      const sendData = new FormData();
      sendData.append('Sub4CategoryId', category3?.Sub4CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub5_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            setCategory4List([])
            // setShowCategory4(false)
          } else {
            // setMessage(res.data.message);
            setCategory4List(res.data.data);
            // setStatus(true);
            // setColor(true);
            setShowCategory4(true)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
          console.log("chip1", err);
        });
    }
    else {
      setShowCategory4(false) 
    }
  }

  useEffect(() => {
    fetchsub5Category();
  }, [category3]);


  useEffect(() => {
    if (category4?.isSubCategory === 1 || category4?.isSubCategory !== null) {

      const sendData = new FormData();
      sendData.append('Sub5CategoryId', category4?.Sub5CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub6_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            setCategory5List([])
            setShowCategory5(false)
          } else {
            // setMessage(res.data.message);
            setCategory5List(res.data.data);
            // setStatus(true);
            // setColor(true);
            setShowCategory5(true)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
          console.log("chip1", err);
        });
    }
    else {
      setShowCategory5(false)
    }
  }, [category4]);

  return (
    <Box>
      <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
      <Container>
        <Box component={Card} p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={expenseCategoryList}
                    getOptionLabel={(option) => option.ExpenseCategoryName}
                    onChange={(event, value) => setExpenseCategory(value)}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Expence Category' color='secondary' {...params} />}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={TypeList}
                    getOptionLabel={(option) => option.Sub1CategoryName}
                    onChange={(event, value) => setType(value)}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Select Type' color='secondary' {...params} />}
                    required
                  />
                </Grid>
                <Grid xs={2} sm={2} md={2} lg={2}>
                  <InputAdornment sx={{ marginTop: '43px' }} position="end">
                    <IconButton onClick={handleAddType}>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                </Grid>
              </Grid>
            </Grid>
            <Dialog open={openDialog} onClose={handleCloseTypeDialog}>
              <DialogTitle>Add Select Type</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label="Select Type Name"
                      value={sub1CategoryName}
                      onChange={(e) => setSub1CategoryName(e.target.value)}
                      fullWidth
                      sx={{ my: 1 }}
                      size='small'
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label="Select Type Image"
                      type='file'
                      id='sub1CategoryImage'
                      onChange={(e) => setSub1CategoryImage(e.target.files[0])}
                      fullWidth
                      sx={{ my: 1 }}
                      size='small'
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseTypeDialog}>Cancel</Button>
                <Button onClick={handleSaveType}> Add</Button>
              </DialogActions>
            </Dialog>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category1List}
                    value={category1}
                    getOptionLabel={(option) => option.Sub2CategoryName}
                    onChange={(event, value) => setCategory1(value)}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 1' color='secondary' {...params} />}
                  />
                </Grid>
                <Grid xs={2} sm={2} md={2} lg={2}>
                  <InputAdornment sx={{ marginTop: '43px' }} position="end">
                    <IconButton onClick={handleAddSub1Category}>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                </Grid>
              </Grid>
            </Grid>

            <Dialog open={openDialog1} onClose={handleCloseSUb1CategoryDialog}>
              <DialogTitle>Add Category1</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label="Category1 Name"
                      value={sub2CategoryName}
                      onChange={(e) => setSub2CategoryName(e.target.value)}
                      fullWidth
                      sx={{ my: 1 }}
                      size='small'
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label="Category1 Image"
                      type='file'
                      id='sub2CategoryImage'
                      onChange={(e) => setSub2CategoryImage(e.target.files[0])}
                      fullWidth
                      sx={{ my: 1 }}
                      size='small'
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseSUb1CategoryDialog}>Cancel</Button>
                <Button onClick={handleSaveCategory1}>Add</Button>
              </DialogActions>
            </Dialog>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category2List}
                    value={category2}
                    getOptionLabel={(option) => option.Sub3CategoryName}
                    onChange={(event, value) => {
                      setCategory2(value);
                      setShowRent(value?.isSubCategory === 1 ? false : true);
                    }}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 2' color='secondary' {...params} />}
                  />
                </Grid>

                <Grid xs={2} sm={2} md={2} lg={2}>
                  <InputAdornment sx={{ marginTop: '43px' }} position="end">
                    <IconButton onClick={handleAddSub2Category}>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                </Grid>
              </Grid>
            </Grid>

            <Dialog open={openDialog2} onClose={handleCloseSUb2CategoryDialog}>
              <DialogTitle>Add Category2</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label="Category2 Name"
                      value={sub3CategoryName}
                      onChange={(e) => setSub3CategoryName(e.target.value)}
                      fullWidth
                      sx={{ my: 1 }}
                      size='small'
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label="Category2 Image"
                      type='file'
                      id='sub3CategoryImage'
                      onChange={(e) => setSub3CategoryImage(e.target.files[0])}
                      fullWidth
                      sx={{ my: 1 }}
                      size='small'
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseSUb2CategoryDialog}>Cancel</Button>
                <Button onClick={handleSaveCategory2}>Add</Button>
              </DialogActions>
            </Dialog>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category3List}
                    value={category3}
                    getOptionLabel={(option) => option.Sub4CategoryName}
                    onChange={(event, value) => {
                      setCategory3(value)
                      setShowRent(value?.isSubCategory === 1 ? false : true);
                    }}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 3' color='secondary' {...params} />}
                  />
                </Grid>
                <Grid xs={2} sm={2} md={2} lg={2}>
                  <InputAdornment sx={{ marginTop: '43px' }} position="end">
                    <IconButton onClick={handleAddSub3Category}>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                </Grid>
              </Grid>
            </Grid>

            <Dialog open={openDialog3} onClose={handleCloseSUb3CategoryDialog}>
              <DialogTitle>Add Category3</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label="Category3 Name"
                      value={sub4CategoryName}
                      onChange={(e) => setSub4CategoryName(e.target.value)}
                      fullWidth
                      sx={{ my: 1 }}
                      size='small'
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label="Category3 Image"
                      type='file'
                      id='sub4CategoryImage'
                      onChange={(e) => setSub4CategoryImage(e.target.files[0])}
                      fullWidth
                      sx={{ my: 1 }}
                      size='small'
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseSUb3CategoryDialog}>Cancel</Button>
                <Button onClick={handleSaveCategory3}>Add</Button>
              </DialogActions>
            </Dialog>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category4List}
                    value={category4}
                    getOptionLabel={(option) => option.Sub5CategoryName}
                    onChange={(event, value) => {
                      setCategory4(value)
                    }}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 4' color='secondary' {...params} />}
                  />
                </Grid>
                <Grid xs={2} sm={2} md={2} lg={2}>
                  <InputAdornment sx={{ marginTop: '43px' }} position="end">
                    <IconButton onClick={handleAddSub4Category}>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                </Grid>
              </Grid>
            </Grid>

            <Dialog open={openDialog4} onClose={handleCloseSUb4CategoryDialog}>
              <DialogTitle>Add Category4</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label="Category4 Name"
                      value={sub5CategoryName}
                      onChange={(e) => setSub5CategoryName(e.target.value)}
                      fullWidth
                      sx={{ my: 1 }}
                      size='small'
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label="Category4 Image"
                      type='file'
                      id='sub5CategoryImage'
                      onChange={(e) => setSub5CategoryImage(e.target.files[0])}
                      fullWidth
                      sx={{ my: 1 }}
                      size='small'
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseSUb4CategoryDialog}>Cancel</Button>
                <Button onClick={handleSaveCategory4}>Add</Button>
              </DialogActions>
            </Dialog>

          </Grid>
        </Box>
      </Container>
    </Box>
  )
}


