import React, { useState, useEffect } from 'react';
import { TextField, Container, Box, Button, Grid, Card, Autocomplete, InputAdornment, IconButton } from '@mui/material';
import SnackBar from '../../SnackBar';
import axios from 'axios';
import '../../../App.css'
import { addSuggestionBox, fetch_expense_category, getSuggestionBox } from '../../../API_Service/API_Links';

export default function CreateSuggestion() {
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [expenseCategoryList, setExpenseCategoryList] = useState([]);
    const [suggestionList, setSuggestionList] = useState([]);
    const [expenseCategory, setExpenseCategory] = useState(null);
    const [suggestion, setSuggestion] = useState(null);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        fetchExpenseCategory();
    }, []);

    const fetchExpenseCategory = async () => {
        try {
            const res = await axios.get(fetch_expense_category);
            setExpenseCategoryList(res.data.data);
        } catch (error) {
            console.log(error);
            setExpenseCategoryList([]);
        }
    };

    const fetchSuggestion = async (categoryName) => {
        // if (!categoryName) {
        //     setSuggestionList([]);
        //     return;
        // }
        const sendData = new FormData();
        sendData.append('Category', categoryName);
        try {
            const res = await axios.post(getSuggestionBox, sendData);
            setSuggestionList(res.data.data);
            console.log("sug", res.data.data)
        } catch (error) {
            console.log(error);
            setSuggestionList([]);
        }
    };

    const handleCategoryChange = (event, value) => {
        setExpenseCategory(value);
        fetchSuggestion(value?.ExpenseCategoryName);
    };

    const handleSuggestionChange = (event, value) => {
        setSuggestion(value);
    };

    const handleInputChange = (event, value) => {
        setInputValue(value);
    };
    const handleAddSuggestion = () => {
        const addSuggestion = async () => {
            const sendData = new FormData();
            sendData.append('Category', expenseCategory?.ExpenseCategoryName);
            sendData.append('Suggestion', suggestion?.suggestion || inputValue);
            try {
                const res = await axios.post(addSuggestionBox, sendData);
                if (!res.data.error) {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                    fetchExpenseCategory()
                    fetchSuggestion(expenseCategory?.ExpenseCategoryName);
                    setSuggestion(null);
                    setInputValue("");
                } else {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                }
            } catch (error) {
                console.log(error);
            }
        }
        addSuggestion()
    };


    return (
        <Box>
            <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
            <Container>
                <Box component={Card} p={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={10} sm={10} md={10} lg={10}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-expense-category"
                                        options={expenseCategoryList}
                                        getOptionLabel={(option) => option.ExpenseCategoryName}
                                        onChange={handleCategoryChange}
                                        size='small'
                                        renderInput={(params) => (
                                            <TextField sx={{ my: 1 }}
                                                fontSize='small'
                                                label='Expense Category'
                                                color='secondary'
                                                {...params}
                                            />
                                        )}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Grid container >
                                <Grid item xs={10} sm={10} md={10} lg={10}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-suggestion"
                                        options={suggestionList}
                                        value={suggestion}
                                        inputValue={inputValue}
                                        freeSolo
                                        getOptionLabel={(option) => option.suggestion}
                                        onChange={handleSuggestionChange}
                                        onInputChange={handleInputChange}
                                        size='small'
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{ my: 1 }}
                                                fontSize='small'
                                                label='Suggestion'
                                                color='secondary'
                                                {...params}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid xs={2} sm={2} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center', px: 2 }}>
                                    <Button className='searchButton' onClick={handleAddSuggestion}>Add</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}
