import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import axios from 'axios';
import { Box, Stack, Typography } from '@mui/material';
import { deleteuser, methodPost } from '../../../API_Service/API_Links';

export default function DeleteEmployeeDialog({ getAllEmployeeAtOnce, SelectedEmployeeId,  SelectedEmployee,  OpenDeleteDialog, setOpenDeleteDialog, setMessage, setOpen, setStatus, setColor }) {

    const handleClose = () => {
        setOpenDeleteDialog(false);
        getAllEmployeeAtOnce();
    };


    const deleteEmployee = () => {
        const sendData = new FormData()
        sendData.append('EmployeeId', SelectedEmployeeId)
        axios({
            method: methodPost,
            url: deleteuser,
            data: sendData
        })
            .then((res) => {
                if (res.data.error) {
                    setMessage(res.data.message);
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setMessage(res.data.message);
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                    handleClose();
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
            });
    }

    return (
        <div>
            <Dialog
                open={OpenDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ opacity: 1 }}
            >
                <DialogTitle>Delete Employee Record</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are You Sure that You Wanna Delete this Employee's Record ?
                    </DialogContentText>
                        <Stack p={2} spacing={1}>
                            <Typography>ID : {SelectedEmployee.EmployeeId}</Typography>
                            <Typography>Name : {SelectedEmployee.EmployeeName}</Typography>
                            <Typography>Role : {SelectedEmployee.role}</Typography>
                        </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={deleteEmployee}>Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}