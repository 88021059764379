import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Grid, Stack, TextField, Typography } from '@mui/material';
import { add_amount, expenseDocs, getLedgerNameDropdown, reject_leave, statusUpdateExpense } from '../../../API_Service/API_Links';
import { useState } from 'react';
import axios from 'axios';
import {  getAllSiteProject, getGroupsDropdown, getLedgerCategory1Dropdown, getLedgerCategory2Dropdown, getLedgerCategory3Dropdown, getLedgerCategory4Dropdown, getLedgerCategory5Dropdown, insertLedgerCreation, methodGet, methodPost } from "../../../API_Service/API_Links";
import { appendData } from '../../../Variables/Variables';
import { useEffect } from 'react';

export default function ApproveDialog({
    openApproveDialog ,
    setopenApproveDialog,
    data,
    expenseStatus,
    setOpen,
    setMessage,
    setStatus,
    setColor
  }) {


    const [LedgerName, setLedgerName] =useState(null);
    const [group , setGroup] = useState(null);
    const [gst , setGst] = useState(null);
    const [sgst, setSGST] = useState('');
  const [cgst, setCGST] = useState('');
  const [igst, setIGST] = useState('');

    const [category1, setCategory1] = useState(null);
    const [category2, setCategory2] = useState(null);
    const [category3, setCategory3] = useState(null);
    const [category4, setCategory4] = useState(null);
    const [category5, setCategory5] = useState(null);
    const [ledgerList  , setLedgerList] = useState([]);
    const [groupList  , setGroupList] = useState([]);
    const [Category1List  , setCategory1List] = useState([]);
    const [Category2List  , setCategory2List] = useState([]);
    const [Category3List  , setCategory3List] = useState([]);
    const [Category4List  , setCategory4List] = useState([]);
    const [Category5List  , setCategory5List] = useState([]);
    const[input, setInput] = useState(false);

    const [ManualLedgerName, setManualLedgerName] =useState(null);
    const [Manualgroup , setManualGroup] = useState(null);
    const [Manualcategory1, setManualCategory1] = useState(null);
    const [Manualcategory2, setManualCategory2] = useState(null);
    const [Manualcategory3, setManualCategory3] = useState(null);
    const [Manualcategory4, setManualCategory4] = useState(null);
    const [Manualcategory5, setManualCategory5] = useState(null);

  const handleClose = () => {
    setopenApproveDialog(false);
     expenseStatus();
     setCategory1(null);
     setCategory2(null);
     setCategory3(null);
     setCategory4(null);
     setCategory5(null);
     setManualCategory1(null)
     setManualCategory2(null)
     setManualCategory3(null)
     setManualCategory4(null)
     setManualCategory5(null)
     setManualLedgerName(null)
     setManualGroup(null)
     setGroup(null);
     setGst(null);
     setIGST('')
     setCGST('')
     setInput(true)
     setSGST('')
     setLedgerName(null);
  };



  useEffect(() => {
    axios({
      method: methodGet,
      url: getLedgerNameDropdown,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setLedgerList(res.data.data);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
}, []);

  useEffect(() => {
      const sendData = new FormData();
      sendData.append('LedgerNameId', (ManualLedgerName === null && LedgerName !== undefined && LedgerName !== null) ? LedgerName?.LedgerNameId : "" );
      axios({
        method: methodPost,
        url: getGroupsDropdown,
        data:sendData
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setStatus(false);
            setColor(false);
          } else {
            setMessage(res.data.message);
            setGroupList(res.data.data);
            if(LedgerName !== null){
              setGroup(res.data.data[0])
            }
            else{
              setGroup(null)
            }
            setStatus(true);
            setColor(true);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
  }, [LedgerName]);




  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerGroupsId', (Manualgroup === null && group !== undefined &&  group !== null) ? group?.LedgerGroupsId : "" )
    axios({
      method: methodPost,
      url: getLedgerCategory1Dropdown,
      data:sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setCategory1List(res.data.data);
          if(group !== null){
            setCategory1(res.data.data[0])
          }
          else{
            setCategory1(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
}, [group]);


useEffect(() => {
  const sendData = new FormData()
  sendData.append('LedgerCategoryId1', (Manualcategory1 === null && category1 !== undefined &&  category1 !== null) ?  category1?.LedgerCategoryId1 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory2Dropdown,
      data:sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setCategory2List(res.data.data);
          if(category1 !== null){
            setCategory2(res.data.data[0])
          }
          else{
            setCategory2(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
}, [category1]);

useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId2', (Manualcategory2 === null && category2 !== undefined &&  category2 !== null) ? category2?.LedgerCategoryId2 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory3Dropdown,
      data:sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setCategory3List(res.data.data);
          if(category2 !== null){
            setCategory3(res.data.data[0])
          }
          else{
            setCategory3(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
}, [category2]);

useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId3',(Manualcategory3 === null && category3 !== undefined &&  category3 !== null) ? category3?.LedgerCategoryId3 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory4Dropdown,
      data:sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setCategory4List(res.data.data);
          if(category3 !== null){
            setCategory4(res.data.data[0])
          }
          else{
            setCategory4(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
}, [category3]);

useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId4', (Manualcategory4 === null && category4 !== undefined &&  category4 !== null) ? category4?.LedgerCategoryId4 : "")
  axios({
    method: methodPost,
    url: getLedgerCategory5Dropdown,
    data:sendData
  })
    .then((res) => {
      if (res.data.error) {
        setMessage(res.data.message);
        setStatus(false);
        setColor(false);
      } else {
        setMessage(res.data.message);
        setCategory5List(res.data.data);
        if(category4 !== null){
          setCategory5(res.data.data[0])
        }
        else{
          setCategory5(null)
        }
        setStatus(true);
        setColor(true);
      }
    })
    .catch((err) => {
      alert("Oops something went wrong " + err);
    });
}, [category4]);


  const approvereq = () => {
    const sendData = new FormData()
    sendData.append('Id', data.id)
    sendData.append('ExpenseStatus', 1)
    sendData.append('mainCategoryId', data.mainCategoryId)
    sendData.append('EmployeeId', data.employeeId)
    sendData.append('projectCode', data.projectName)
    sendData.append('Category1', data.mainCategory)
    sendData.append('Types', gst)
    sendData.append('SGST', sgst)
    sendData.append('CGST', cgst)
    sendData.append('IGST', igst)
    sendData.append('LedgerNameId', LedgerName?.LedgerNameId || null)
    sendData.append('LedgerName', ManualLedgerName !== null ? ManualLedgerName : LedgerName?.LedgerName ? LedgerName?.LedgerName : "")
    sendData.append('LedgerGroups', Manualgroup !=null ? Manualgroup : group?.LedgerGroups ? group?.LedgerGroups : "")
    sendData.append('LedgerCategory1',Manualcategory1 !=null ? Manualcategory1 : category1?.LedgerCategory1 ? category1?.LedgerCategory1 : "")
    sendData.append('LedgerCategory2',Manualcategory2 !=null? Manualcategory2 : category2?.LedgerCategory2 ? category2?.LedgerCategory2 : "")
    sendData.append('LedgerCategory3', Manualcategory3 !=null? Manualcategory3 : category3?.LedgerCategory3 ? category3?.LedgerCategory3 : "")
    sendData.append('LedgerCategory4', Manualcategory4 !== null ? Manualcategory4 : category4?.LedgerCategory4 ? category4?.LedgerCategory4 : "")
    sendData.append('LedgerCategory5', Manualcategory5 !==null ? Manualcategory5 : category5?.LedgerCategory5 ? category5?.LedgerCategory5 : "")

    axios({
        method: methodPost,
        url: statusUpdateExpense,
        data:sendData
      })
        .then((res) => {
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true)
            setStatus(true)
            setColor(true)
            handleClose();
          } else {
            setMessage(res.data.message);
            setOpen(true)
            setStatus(false)
            setColor(false)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
   }


  return (
    <div>
      <Dialog
       fullWidth
       maxWidth={'lg'}
        open={openApproveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Approve This Request</DialogTitle>
        <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
          <img src={`${expenseDocs}${data?.Images}`} style={{ width: '100%', height: "100%" }} alt='img1' />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>

          <Grid container spacing={3}>

          <Grid item xs={12} sm={12} md={12} lg={12}>
           <Typography>GST/NON-GST</Typography>
          </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
     <Autocomplete
      disablePortal
      id="combo-box-demo"
      key={input}
      options={['GST', 'NON-GST']}
      value={gst}
      onChange={(event, value) => setGst(value)}
      size='small'
      renderInput={(params) => <TextField sx={{ width:'30ch'}} fontSize='small' label='GST/NON-GST' color='secondary' {...params} />}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
      </Grid>

{
  (gst === 'GST' && gst !== null && gst !== undefined ) && <>
      <Grid item xs={12} sm={12} md={6} lg={6}>
      <TextField
            name="name"
            label="SGST (%)"
            fullWidth
            size='small'
            onChange={(e) => {
              const newValue = e.target.value;
              // Regular expression for allowing decimal numbers
              if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                setSGST(newValue);
              } else {
                setSGST('');
              }
            }}
            value={sgst}
          />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
      <TextField
            name="name"
            label="CGST (%)"
            fullWidth
            size='small'
            value={cgst}
            onChange={(e) => {
              const newValue = e.target.value;
              // Regular expression for allowing decimal numbers
              if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                setCGST(newValue);
              } else {
                setCGST('');
              }
            }}
          />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
      <TextField
            name="name"
            label="IGST (%)"
            fullWidth
            size='small'
            onChange={(e) => {
              const newValue = e.target.value;
              // Regular expression for allowing decimal numbers
              if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                setIGST(newValue);
              } else {
                setIGST('');
              }
            }}
            value={igst}
          />
      </Grid>
      </>
}
</Grid>


         <Grid container spacing={3}>
         <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
           <Typography>GROUPING</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
          <Autocomplete
      disablePortal
      id="combo-box-demo"
      key={input}
      options={ledgerList}
      value={LedgerName}
      freeSolo
      getOptionLabel={(option)=>option.LedgerName}
      onChange={(event, value) => setLedgerName(value ?? value)}
      size='small'
      renderInput={(params) => <TextField onChange={(e)=>setManualLedgerName(e.target.value)}  sx={{my:1}} fontSize='small' label='Ledger' color='secondary' {...params} />}
        />
           </Grid>
           <Grid item xs={12} sm={12} md={6} lg={6}>
           <Autocomplete
      disablePortal
      id="combo-box-demo"
      key={input}
      options={groupList}
      value={group}
      freeSolo
      getOptionLabel={(option)=>option.LedgerGroups}
      onChange={(event, value) => setGroup(value)}
      size='small'
      renderInput={(params) => <TextField onChange={(e)=>setManualGroup(e.target.value)} fontSize='small' label='Select Group' color='secondary' {...params} />}
        />
           </Grid>
           <Grid item xs={12} sm={12} md={6} lg={6}>
           <Autocomplete
        disablePortal
        id="combo-box-demo"
        key={input}
        options={Category1List}
         value={category1}
         freeSolo
        getOptionLabel={(option)=>option.LedgerCategory1}
        onChange={(event, value) =>{
            setCategory1(value)
           }}
        size='small'
        renderInput={(params) => <TextField onChange={(e)=>setManualCategory1(e.target.value)} fontSize='small' label='Category 1' color='secondary' {...params} />}
        />
           </Grid>
           <Grid item xs={12} sm={12} md={6} lg={6}>
           <Autocomplete
          disablePortal
          id="combo-box-demo"
          key={input}
          options={Category2List}
          value={category2}
          freeSolo
          getOptionLabel={(option)=>option.LedgerCategory2}
          onChange={(event, value) =>{
          setCategory2(value);
          }}
          size='small'
          renderInput={(params) => <TextField onChange={(e)=>setManualCategory2(e.target.value)} fontSize='small' label='Category 2' color='secondary' {...params} />}
          />
           </Grid>
           <Grid item xs={12} sm={12} md={6} lg={6}>

<Autocomplete
          disablePortal
          id="combo-box-demo"
          key={input}
          options={Category3List}
          value={category3}
          freeSolo
          getOptionLabel={(option)=>option.LedgerCategory3}
          onChange={(event, value) => {
            setCategory3(value)
          }}
          size='small'
          renderInput={(params) => <TextField onChange={(e)=>setManualCategory3(e.target.value)}  fontSize='small' label='Category 3' color='secondary' {...params} />}
          />
           </Grid>

           <Grid item xs={12} sm={12} md={6} lg={6}>
           <Autocomplete
          disablePortal
          id="combo-box-demo"
          key={input}
          options={Category4List}
          value={category4}
          freeSolo
          getOptionLabel={(option)=>option.LedgerCategory4}
          onChange={(event, value) =>{
             setCategory4(value)
            }}
          size='small'
          renderInput={(params) => <TextField onChange={(e)=>setManualCategory4(e.target.value)} fontSize='small' label='Category 4' color='secondary' {...params} />}
          />
           </Grid>

           <Grid item xs={12} sm={12} md={6} lg={6}>
           <Autocomplete
          disablePortal
          id="combo-box-demo"
          key={input}
          options={Category5List}
          value={category5}
          freeSolo
          getOptionLabel={(option)=>option.LedgerCategory5}
          onChange={(event, value) =>{
             setCategory5(value)
            }}
          size='small'
          renderInput={(params) => <TextField onChange={(e)=>setManualCategory5(e.target.value)} fontSize='small' label='Category 5' color='secondary' {...params} />}
          />
           </Grid>

           </Grid>

          </Grid>
        </Grid>

        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleClose}>Cancel</Button>
          <Button variant='contained' color='primary' onClick={approvereq}>Approve</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}