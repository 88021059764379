import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  Container,
  Autocomplete,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import {
  add_employee_details,
  add_factory_project,
  get_department,
  get_employee_detail,
  get_factory_employees,
  getDesignationDynamic,
  getRoleDynamicDropdown,
  methodPost,
  updateuser,
} from "../../../API_Service/API_Links";
import { Form } from "react-bootstrap";
import axios from "axios";
import { appendData } from "../../../Variables/Variables";
import SnackBar from "../../SnackBar";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Heading from "../../Heading";
import Breadcrumbs from "../../Breadcrumbs";

export default function EditEmployeeEditForm({ id }) {
  const [showPassword, setShowPassword] = useState(true);
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [EmployeeType, setEmployeeType] = useState("");
  const [EmployeeId, setEmployeedId] = useState(location.state.EmployeeId);
  const [EmployeeNo, setEmployeeNo] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [EmployeeName, setEmployeeName] = useState(location.state.EmployeeName);
  const [Profile, setProfile] = useState("");
  const [JoinDate, setJoinDate] = useState("");
  const [GroupCompany, setGroupCompany] = useState("");
  const [Department, setDepartment] = useState("");
  const [Designation, setDesignation] = useState(null);
  const [DesignationList, setDesignationList] = useState([]);
  const [Role, setRole] = useState([]);
  const [RoleList, setRoleList] = useState([]);
  const [Gender, setGender] = useState("");
  const [Blood, setBlood] = useState("");
  const [AdhaarNo, setAdhaarNo] = useState("");
  const [Age, setAge] = useState("");
  const [DOB, setDOB] = useState("");
  const [Email, setemail] = useState("");
  const [PersonalPh, setPersonalPh] = useState("");
  const [PermanentAddress, setpermanentAddress] = useState("");
  const [PresentAddress, setpresentAddress] = useState("");
  const [Institute, setInstitute] = useState("");
  const [HighLevel, setHighLevel] = useState("");
  const [Course, setCourse] = useState("");
  const [JoinYear, setJoinYear] = useState("");
  const [PassingYear, setPassingYear] = useState("");
  const [Result, setResult] = useState("");
  const [BankName, setBankName] = useState("");
  const [BranchName, setBranchName] = useState("");
  const [AccountNo, setAccountNo] = useState("");
  const [ConfirmAccountNo, setConfirmAccountNo] = useState("");
  const [IFSC, setIFSC] = useState("");
  const [Holder, setHolder] = useState("");
  const [AdhaarCard, setAdhaarCard] = useState("");
  const [PANCard, setPANCard] = useState("");
  const [BloodReport, setBloodReport] = useState("");
  const [DL, setDL] = useState("");
  const [EducationCert, setEducationCart] = useState("");
  const [EmployeeCV, setEmployeeCV] = useState("");
  const [ExpYear, setExpYear] = useState("");
  const [PANno, setPANno] = useState("");
  const [PassportNo, setPassportNo] = useState("");
  const [DLNumber, setDLNumber] = useState("");
  const [DLExp, setDLExp] = useState("");
  const [DLBatch, setDLBatch] = useState("");
  const [HealthRelated, setHealthRelated] = useState("");
  const [EmergencyName, setEmergencyName] = useState("");
  const [EmergencyContact, setEmergencyContact] = useState("");
  const [Referred, setReferred] = useState("");
  const [ReferralContact, setReferralContact] = useState("");
  const [FatherName, setFatherName] = useState("");
  const [MotherName, setMontherName] = useState("");
  const [Marital, setMarital] = useState("");
  const [Spouse, setSpouse] = useState("");
  const [KidsName, setKidsName] = useState("");
  const [AdditonalContact, setAdditionalContact] = useState("");
  const [Insurance, setInsurance] = useState("");
  const [ExpLetter, setExpLetter] = useState("");
  const [RelieveLetter, setRelieveLetter] = useState("");
  const [OfferLetter, setOfferLetter] = useState("");
  const [remarks, setRemarks] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [courseCompletion, setCourseCompletion] = useState("");
  const [Degree, setDegree] = useState("");
  const [ComplainceRemarks, setComplainceRemarks] = useState("");
  const [Complaince, setComplaince] = useState("");
  const [Reporting, setReporting] = useState(null);
  const [reportingPerson, setreportingPerson] = useState(null);
  const [options, setOptions] = useState([]);
  const [Kid1Name, setKid1Name] = useState("");
  const [Kid2Name, setKid2Name] = useState("");
  const [ActiveEmp, setActiveEmp] = useState(true);

  const getParticularEmployeeData = () => {
    const sendData = new FormData();
    sendData.append("EmployeeName", EmployeeName);
    sendData.append("EmployeeId", EmployeeId);
    axios({
      method: "POST",
      url: get_employee_detail,
      data: sendData,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setEmployeeType(res.data.data.employeeType);
          setEmployeeNo(res.data.data.EmployeeNo);
          setProfile(res.data.data.profileImage);
          setJoinDate(res.data.data.dateOfJoining);
          setGroupCompany(res.data.data.groupCompany);
          setDepartment(res.data.data.department);
          setDesignation(res.data.data.designation);
          setRole(res.data.data.roles);
          setGender(res.data.data.gender);
          setBlood(res.data.data.bloodGroup);
          setAdhaarNo(res.data.data.aadharNum);
          setAge(res.data.data.age);
          setDOB(res.data.data.dateOfBirth);
          setemail(res.data.data.email);
          setPersonalPh(res.data.data.personalPhoneNum);
          setpermanentAddress(res.data.data.permanentAddress);
          setpresentAddress(res.data.data.presentAddress);
          setInstitute(res.data.data.instituteName);
          setHighLevel(res.data.data.highestLevel);
          setCourse(res.data.data.course);
          setJoinYear(res.data.data.yearOfJoining);
          setPassingYear(res.data.data.yearOfPassing);
          setResult(res.data.data.result);
          setBankName(res.data.data.bankName);
          setBranchName(res.data.data.branchName);
          setAccountNo(res.data.data.accountNo);
          setIFSC(res.data.data.IFSCcode);
          setHolder(res.data.data.accountHolder);
          setAdhaarCard(res.data.data.aadharCard);
          setPANCard(res.data.data.panCard);
          setDL(res.data.data.drivingLicense);
          setBloodReport(res.data.data.bloodReport);
          setEducationCart(res.data.data.educationalCertificate);
          setEmployeeCV(res.data.data.employeeCV);
          setExpYear(res.data.data.yearsOfExperience);
          setPANno(res.data.data.panNum);
          setPassportNo(res.data.data.passportNum);
          setDLNumber(res.data.data.drivingLicenseNum);
          setDLExp(res.data.data.drivingLicenseExpiration);
          setDLBatch(res.data.data.drivingLicenseBatch);
          setHealthRelated(res.data.data.healthIssues);
          setEmergencyName(res.data.data.emergencyContactName);
          setEmergencyContact(res.data.data.emergencyContactNum);
          setReferred(res.data.data.employeeReferredBy);
          setReferralContact(res.data.data.referralContactNum);
          setFatherName(res.data.data.fatherName);
          setMontherName(res.data.data.motherName);
          setMarital(res.data.data.maritalStatus);
          setSpouse(res.data.data.spouseName);
          setKidsName(res.data.data.kidsName);
          setAdditionalContact(res.data.data.additionalContactDetails);
          setInsurance(res.data.data.insurance);
          setExpLetter(res.data.data.experienceLetter);
          setRelieveLetter(res.data.data.relievingLetter);
          setOfferLetter(res.data.data.offerLetter);
          setRemarks(res.data.data.remarks);
          setCourseCompletion(res.data.data.isCourseCompleted);
          setDegree(res.data.data.degree);
          setComplainceRemarks(res.data.data.complainceRemarks);
          setComplaince(res.data.data.isComplaince);
          setReporting({
            ...Reporting,
            DepartmentId: res.data.data.reportingTo,
          });
          setreportingPerson({
            ...reportingPerson,
            EmployeeId: res.data.data.reportingPerson,
          });
          setKid1Name(res.data.data.kidsName1);
          setKid2Name(res.data.data.kidsName2);
          setUserName(res.data.data.mobileNum);
          setPassword(res.data.data.password);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  useEffect(() => {
    getParticularEmployeeData();
  }, []);

  useEffect(() => {
    const getDesignation = () => {
      axios({
        method: "GET",
        url: getDesignationDynamic,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          } else {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            setDesignationList(res.data.data);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    };

    getDesignation();
  }, []);

  useEffect(() => {
    if (Designation !== null && Designation !== "") {
      const sendData = new FormData();
      sendData.append("designationId", Designation?.id);
      axios({
        method: methodPost,
        url: getRoleDynamicDropdown,
        data: sendData,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
            setRoleList([]);
          } else {
            setMessage(res.data.message);
            setRoleList(res.data.data);
            setOpen(true);
            setStatus(true);
            setColor(true);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    } else {
      setRoleList([]);
    }
  }, [Designation]);

  useEffect(() => {
    axios({
      method: "GET",
      url: get_department,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setDepartmentList(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  useEffect(() => {
    if (Reporting !== null && Reporting !== undefined) {
      const sendData = new FormData();
      sendData.append("DepartmentId", Reporting?.DepartmentId);
      axios({
        method: methodPost,
        url: get_factory_employees,
        data: sendData,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
            setOptions([]);
          } else {
            setMessage(res.data.message);
            setOptions(res.data.data);
            setOpen(true);
            setStatus(true);
            setColor(true);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    } else {
      setOptions([]);
    }
  }, [Reporting]);

  const updateUserPassword = () => {
    const serverData = {
      MobileNum: userName,
      Password: password,
    };
    const sendData = appendData(serverData);
    axios({
      method: methodPost,
      url: updateuser,
      data: sendData,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  const selectedRole = Role.map((i) => {
    const obj = {
      roleId: i.id,
      role: i.role,
    };
    return obj;
  });

  const selectedDesignation = Designation
    ? [Designation]?.map((i) => {
        const obj = {
          designationId: i.id,
          designation: i.designation,
        };
        return obj;
      })
    : [];

  const serverData = {
    employeeName: EmployeeName,
    email: Email,
    employeeType: EmployeeType,
    department: Department,
    designation: JSON.stringify(selectedDesignation),
    roles: JSON.stringify(selectedRole),
    gender: Gender,
    bloodGroup: Blood,
    aadharNum: AdhaarNo,
    age: Age,
    groupCompany: GroupCompany,
    dateOfBirth: DOB,
    dateOfJoining: JoinDate,
    personalPhoneNum: PersonalPh,
    presentAddress: PresentAddress,
    permanentAddress: PermanentAddress,
    instituteName: Institute,
    highestLevel: HighLevel,
    course: Course,
    yearOfJoining: JoinYear,
    yearOfPassing: PassingYear,
    result: Result,
    aadharCard: AdhaarCard,
    panCard: PANCard,
    drivingLicense: DL,
    bloodReport: BloodReport,
    educationalCertificate: EducationCert,
    employeeCV: EmployeeCV,
    insurance: Insurance,
    experienceLetter: ExpLetter,
    relievingLetter: RelieveLetter,
    offerLetter: OfferLetter,
    bankName: BankName,
    branchName: BranchName,
    accountNo: AccountNo,
    confirmAccountNo: ConfirmAccountNo,
    IFSCcode: IFSC,
    accountHolder: Holder,
    yearsOfExperience: ExpYear,
    panNum: PANno,
    passportNum: PassportNo,
    drivingLicenseNum: DLNumber,
    drivingLicenseExpiration: DLExp,
    drivingLicenseBatch: DLBatch,
    healthIssues: HealthRelated,
    emergencyContactName: EmergencyName,
    emergencyContactNum: EmergencyContact,
    employeeReferredBy: Referred,
    referralContactNum: ReferralContact,
    fatherName: FatherName,
    motherName: MotherName,
    maritalStatus: Marital,
    spouseName: Spouse,
    kidsName1: Kid1Name,
    kidsName2: Kid2Name,
    additionalContactDetails: AdditonalContact,
    EmployeeId: EmployeeId,
    isCourseCompleted: courseCompletion,
    isComplaince: Complaince,
    complainceRemarks: ComplainceRemarks,
    degree: Degree,
    profileImage: Profile,
    reportingTo: Reporting?.DepartmentId,
    reportingPerson: reportingPerson?.EmployeeId,
    active: ActiveEmp ? 1 : 0,
  };
  const sendData = appendData(serverData);
  const onSubmit = () => {
    if (!navigator.onLine) {
      setMessage("Your internet is in Offline");
      setOpen(true);
      setStatus(false);
      setColor(false);
    } else {
      axios({
        method: methodPost,
        url: add_employee_details,
        data: sendData,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          } else {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            navigate(-1);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
  };

  const cancelClick = () => {
    navigate(-1);
  };

  return (
    <Box>
      <Box py={2} px={1}>
        <Breadcrumbs
          previous={"Home"}
          current={"onBoard"}
          link1={`/home`}
          link2={"/home/HRMS/onboard"}
          currentSection={"Edit Employee"}
        />
      </Box>

      <Container>
        <Box py={3}>
          <Heading title={"Update Employee Information"} />
        </Box>
      </Container>

      <Box p={3} sx={{ height: "90%" }} display="flex" alignItems="center">
        <SnackBar
          open={open}
          message={message}
          setOpen={setOpen}
          status={status}
          color={color}
        />

        <Box
          py={4}
          sx={{
            px: 5,
            backgroundColor: "#EDF4F4",
            borderRadius: "10px",
            mx: 2,
            my: 4,
            boxShadow: 11,
          }}
        >
          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item lg={12} sm={12} xl={12} xs={12} md={12} mt={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={EmployeeType}
                options={[
                  "Factory Employee",
                  "Site Helpers",
                  "Site Employee",
                  "Office Employee",
                ]}
                onChange={(index, value) => setEmployeeType(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Employee Type"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    color="secondary"
                  />
                )}
              />
            </Grid>
          </Grid>

          {EmployeeType !== "Site Helpers" ? (
            <Grid
              container
              justifyContent="center"
              sx={{ textAlign: "center" }}
              spacing={4}
            >
              <Grid item lg={6} xl={6}>
                <Box
                  sx={{
                    mt: 5,
                    px: 2,
                    border: "1px solid black",
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                  }}
                >
                  <Box sx={{ px: 2, textAlign: "left" }}>
                    <h5>UPDATE ACCOUNT</h5>
                  </Box>
                  <Grid container justifyContent="space-evenly" spacing={2}>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Mobile Number"
                        variant="outlined"
                        size="small"
                        type="tel"
                        color="secondary"
                        autoComplete="off"
                        value={userName}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        type={showPassword ? "password" : "text"}
                        label="Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={12}
                      xl={6}
                      xs={14}
                      md={6}
                      sx={{ py: 2 }}
                    >
                      <Button
                        sx={{ p: 2, color: "white" }}
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={updateUserPassword}
                      >
                        UPDATE EMPLOYEE ACCOUNT
                      </Button>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={12}
                      xl={6}
                      xs={14}
                      md={6}
                      sx={{ py: 2 }}
                    >
                      <Button
                        sx={{ p: 2 }}
                        fullWidth
                        color="error"
                        variant="contained"
                        onClick={() => setActiveEmp(!ActiveEmp)}
                      >
                        DEACTIVATE ACCOUNT
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                {/* Personal Info */}

                <Box
                  sx={{
                    border: "1px solid black",
                    p: 4,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 1,
                  }}
                >
                  <Box sx={{ pb: 2, textAlign: "left" }}>
                    <h5>BASIC INFORMATION</h5>
                  </Box>
                  <Grid container justifyContent="start" spacing={2}>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Employee ID"
                        variant="outlined"
                        size="small"
                        disabled
                        color="secondary"
                        value={EmployeeId}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Employee Number"
                        variant="outlined"
                        size="small"
                        disabled
                        color="secondary"
                        value={EmployeeNo}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Employee Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={EmployeeName}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setEmployeeName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Profile Picture"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setProfile(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="Services"
                        type="date"
                        label="Date Of Joining"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={JoinDate}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setJoinDate(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={[
                          "IGreen Technologies",
                          "IGreentec engineering. India Pvt. Ltd",
                        ]}
                        value={GroupCompany}
                        onChange={(event, value) => setGroupCompany(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Group Company"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={Department}
                        options={departmentList.map((i) => i.Department)}
                        onChange={(event, value) => setDepartment(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Department"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={DesignationList}
                        value={Designation}
                        onChange={(event, value) => setDesignation(value)}
                        getOptionLabel={(option) => option.designation}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Designation"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={RoleList}
                        multiple
                        value={Role}
                        onChange={(event, value) => setRole(value)}
                        getOptionLabel={(option) => option.role}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Role"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={
                          Reporting &&
                          departmentList?.find(
                            (i) =>
                              i.DepartmentId.toString() ===
                              Reporting.DepartmentId.toString()
                          )
                        }
                        options={departmentList}
                        freeSolo
                        getOptionLabel={(option) => option.Department}
                        onChange={(event, value) => setReporting(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Superior Department"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={
                          reportingPerson &&
                          options?.find(
                            (i) =>
                              i.EmployeeId.toString() ===
                              reportingPerson.EmployeeId.toString()
                          )
                        }
                        options={options}
                        freeSolo
                        onChange={(event, value) => setreportingPerson(value)}
                        getOptionLabel={(option) => option.EmployeeName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Reporting To"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={["Male", "Female", "Prefer Not to Say"]}
                        value={Gender}
                        onChange={(event, value) => setGender(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Gender"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Blood Group"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Blood}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setBlood(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Adhaar No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={AdhaarNo}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setAdhaarNo(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Age"
                        variant="outlined"
                        size="small"
                        type="tel"
                        color="secondary"
                        value={Age}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setAge(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="Services"
                        type="date"
                        label="Date Of Birth"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={DOB}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setDOB(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Email ID"
                        type="email"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Email}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Years Of Exp."
                        variant="outlined"
                        size="small"
                        type="tel"
                        color="secondary"
                        value={ExpYear}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setExpYear(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Personal Mobile No"
                        variant="outlined"
                        type="tel"
                        size="small"
                        color="secondary"
                        value={PersonalPh}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setPersonalPh(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={Complaince}
                        options={["Complaince", "Non-Complaince"]}
                        onChange={(event, value) => setComplaince(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Complaince/Non-Complaince"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Complaince Remarks"
                        variant="outlined"
                        type="tel"
                        size="small"
                        color="secondary"
                        value={ComplainceRemarks}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setComplainceRemarks(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12} mt={1}>
                      <Box sx={{ textAlign: "left" }}>
                        <h5>ADDRESS INFORMATION</h5>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 1 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Permanent Address"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={PermanentAddress}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setpermanentAddress(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Present Address"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={PresentAddress}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setpresentAddress(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                      <Box sx={{ pb: 1, textAlign: "left" }}>
                        <h5>EDUCATION INFORMATION</h5>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Name Of the Institute"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Institute}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setInstitute(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Highest Level"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={HighLevel}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setHighLevel(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Course/Stream"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Course}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setCourse(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={["YES", "NO"]}
                        size="small"
                        onChange={(e, value) => setCourseCompletion(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Course Completion"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={Degree}
                        options={[
                          "UG",
                          "PG",
                          "10th grade/SSLC",
                          "12th grade/HSC",
                        ]}
                        onChange={(event, value) => setDegree(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Highest Qualification"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    {/* <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="lastName"
                        label="Year Of Joining"
                        variant="outlined"
                        size="small"
                        type="date"
                        value={JoinYear}
                        InputLabelProps={{ shrink: true }}
                        color="secondary"
                        onChange={(e) => setJoinYear(e.target.value)}
                      />
                    </Grid> */}

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="lastName"
                        label="Year Of Passing"
                        variant="outlined"
                        size="small"
                        type="date"
                        value={PassingYear}
                        InputLabelProps={{ shrink: true }}
                        color="secondary"
                        onChange={(e) => setPassingYear(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="lastName"
                        label="Result"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Result}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setResult(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {/* (Financial Info) */}

              <Grid item lg={6} xl={6}>
                <Box
                  sx={{
                    border: "1px solid black",
                    px: 4,
                    pb: 5,
                    pt: 4,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 5,
                  }}
                >
                  <Box sx={{ pb: 3, textAlign: "left" }}>
                    <h5>BANKING INFORMATION</h5>
                  </Box>
                  <Grid container justifyContent="space-evenly" spacing={2}>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Bank Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={BankName}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setBankName(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Branch Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={BranchName}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setBranchName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Account No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={AccountNo}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setAccountNo(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Confirm Account No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={ConfirmAccountNo}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setConfirmAccountNo(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="IFSC Code"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={IFSC}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setIFSC(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Account Holder Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Holder}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setHolder(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12} mt={5}>
                      <Box sx={{ pb: 1, textAlign: "left" }}>
                        <h5>PROOF DOCUMENTS</h5>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Adhaar"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setAdhaarCard(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="PAN"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setPANCard(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Driver's License"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setDL(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Blood Report"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setBloodReport(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Educational Cert"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setEducationCart(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Employee CV"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setEmployeeCV(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12} mt={5}>
                      <Box sx={{ pb: 1, textAlign: "left" }}>
                        <h5>PERSONAL INFORMATION</h5>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="PAN No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={PANno}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setPANno(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Passport No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={PassportNo}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setPassportNo(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Driving License No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={DLNumber}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setDLNumber(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Driving License Expiration"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="date"
                        value={DLExp}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setDLExp(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Driving License Batch"
                        variant="outlined"
                        size="small"
                        value={DLBatch}
                        InputLabelProps={{ shrink: true }}
                        color="secondary"
                        onChange={(e) => setDLBatch(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Health Related Issues"
                        variant="outlined"
                        value={HealthRelated}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        color="secondary"
                        onChange={(e) => setHealthRelated(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Emergency ContactName"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={EmergencyName}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setEmergencyName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Emergency Contact No."
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={EmergencyContact}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setEmergencyContact(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Employee Referred By"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Referred}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setReferred(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Referral Contact No."
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={ReferralContact}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setReferralContact(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Father's Name"
                        variant="outlined"
                        size="small"
                        value={FatherName}
                        InputLabelProps={{ shrink: true }}
                        color="secondary"
                        onChange={(e) => setFatherName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Mother's Name"
                        variant="outlined"
                        value={MotherName}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        color="secondary"
                        onChange={(e) => setMontherName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Marital Status"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Marital}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setMarital(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Spouse Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Spouse}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setSpouse(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Kid1 Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Kid1Name}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setKid1Name(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Kid2 Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Kid1Name}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setKid2Name(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Additional Contact details"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={AdditonalContact}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setAdditionalContact(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12} mt={3}>
                      <Box sx={{ pb: 1, textAlign: "left" }}>
                        <h5>OFFER LETTER INFO</h5>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Insurance"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setInsurance(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Experience Lettter"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setExpLetter(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Relieving Letter"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setRelieveLetter(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Offer Letter"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setOfferLetter(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="center"
              sx={{ textAlign: "center" }}
              spacing={4}
            >
              <Grid item lg={6} xl={6}>
                {/* Personal Info */}

                <Box
                  sx={{
                    border: "1px solid black",
                    p: 4,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 5,
                  }}
                >
                  <Box sx={{ pb: 3, textAlign: "left" }}>
                    <h5>SITE HELPER INFORMATION 1</h5>
                  </Box>
                  <Grid container justifyContent="space-evenly" spacing={2}>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Labour Name"
                        variant="outlined"
                        required
                        size="small"
                        color="secondary"
                        value={EmployeeName}
                        onChange={(e) => setEmployeeName(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Employee No"
                        variant="outlined"
                        required
                        size="small"
                        color="secondary"
                        value={EmployeeId}
                        onChange={(e) => setEmployeedId(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Designation"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Designation}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setDesignation(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={Reporting}
                        options={departmentList.map((i) => i.Department)}
                        onChange={(event, value) => setReporting(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Reporting To"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Profile Picture"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setProfile(e.target.files[0])}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="Services"
                        type="date"
                        label="Date Of Birth"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={DOB}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setDOB(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Personal Mobile No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={PersonalPh}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setPersonalPh(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Remarks"
                        variant="outlined"
                        required
                        size="small"
                        color="secondary"
                        value={remarks}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Permanent Address"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={PermanentAddress}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setpermanentAddress(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item lg={6} xl={6}>
                <Box
                  sx={{
                    border: "1px solid black",
                    p: 4,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 5,
                  }}
                >
                  <Box sx={{ pb: 3, textAlign: "left" }}>
                    <h5>SITE HELPER INFORMATION 2</h5>
                  </Box>
                  <Grid container justifyContent="space-evenly" spacing={2}>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Adhaar No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={AdhaarNo}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setAdhaarNo(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="PAN No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={PANno}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setPANno(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Blood Group"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Blood}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setBlood(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Health Related Issues"
                        variant="outlined"
                        value={HealthRelated}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        color="secondary"
                        onChange={(e) => setHealthRelated(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Father's Name"
                        variant="outlined"
                        size="small"
                        value={FatherName}
                        InputLabelProps={{ shrink: true }}
                        color="secondary"
                        onChange={(e) => setFatherName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Mother's Name"
                        variant="outlined"
                        value={MotherName}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        color="secondary"
                        onChange={(e) => setMontherName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Marital Status"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={Marital}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setMarital(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Emergency Contact No."
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={EmergencyContact}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setEmergencyContact(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Additional Contact details"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={AdditonalContact}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setAdditionalContact(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}

          {/* {buttons}  */}

          <Grid
            container
            justifyContent="center"
            sx={{ textAlign: "center", mt: 3 }}
          >
            <Grid item lg={6} xl={6} xs={12}>
              <Grid container justifyContent="space-evenly" alignItems="center">
                <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      fullWidth
                      variant="outlined"
                      type="submit"
                      onClick={onSubmit}
                      sx={{
                        color: "white",
                        backgroundColor: "#7bc54c",
                        borderColor: "#7bc54c",
                        ":hover": { borderColor: "#7bc54c", color: "#000000" },
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Grid>

                <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={cancelClick}
                      type="cancel"
                      sx={{
                        color: "white",
                        backgroundColor: "#c62828",
                        borderColor: "#c62828",
                        ":hover": { borderColor: "#c62828", color: "#000000" },
                      }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
