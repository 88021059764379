import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, Grow, TableHead, TableRow, Paper, Snackbar, Alert, Grid, Box, Container, TablePagination, Button, Card } from "@mui/material";
import { approve_notify, expenseDocs, fundRequest, getPendingFundRequest, methodPost } from "../../API_Service/API_Links";
import axios from "axios";
import { FilterData, FilterFundReq, FilterThreeData } from "../FilterData/FilterData";
import FundRejectDialog from "./FundRejectDialog";
import FundApproveDialog from "./FundApproveDialog";
import QuotationDialog from "./QuotationDialog";
import SnackBar from "../SnackBar";
import Filter from "../FilterData/Filter";



export default function ApproveFundRequest() {

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [openRejectDialog, setopenRejectDialog] = useState(false);
  const [openApproveDialog, setopenApproveDialog] = useState(false);
  const [openQuotationDialog, setopenQuotationDialog] = useState(false);
  const [isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen] = useState(false);
  const [Notification, setNotification] = useState("");
  const [id, setId] = useState('');
  const [QuotationData, setQuotationData] = useState([]);
  const [employeeIdSearch, setemployeeIdSearch] = useState('');
  const [userNameSearch, setUserNameSearch] = useState('');
  const [projectCodeSearch, setProjectCodeSearch] = useState('');
  const [createdSearch, setCreatedSearch] = useState('');

  const [SelectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectedProjectList, setSelectedProjectList] = useState('');





  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const getAllPendinRequest = () => {
    axios({
      method: 'GET',
      url: getPendingFundRequest,
    }).then(res => {
      if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
      } else {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data);
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllPendinRequest();
  }, []);


  const handleOpenApproveDialog = (approveId, empId, project) => {
    setopenApproveDialog(!openApproveDialog);
    setId(approveId)
    setSelectedEmployeeId(empId)
    setSelectedProjectList(project)
  }

  const handleOpenRejectDialog = (rejectId) => {
    setopenRejectDialog(!openRejectDialog);
    setId(rejectId);
  }

  const handleOpenQuotationDialog = (quote) => {
    setopenQuotationDialog(!openQuotationDialog);
    setQuotationData(quote)
  }



  const handleCloseAddedCartSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsAddedCartSnackbarOpen(false);
  };

  function GrowTransition(props) {
    return <Grow {...props} direction="up" />;
  }



  return (
    <Box>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />
      <FundRejectDialog setIsAddedCartSnackbarOpen={setIsAddedCartSnackbarOpen} setNotification={setNotification} getAllPendinRequest={getAllPendinRequest} id={id} setId={setId} setopenRejectDialog={setopenRejectDialog} openRejectDialog={openRejectDialog} />
      <FundApproveDialog setSelectedProjectList={setSelectedProjectList} selectedProjectList={selectedProjectList} setSelectedEmployeeId={setSelectedEmployeeId} SelectedEmployeeId={SelectedEmployeeId} setIsAddedCartSnackbarOpen={setIsAddedCartSnackbarOpen} setNotification={setNotification} getAllPendinRequest={getAllPendinRequest} id={id} setId={setId} setopenApproveDialog={setopenApproveDialog} openApproveDialog={openApproveDialog} />
      <QuotationDialog data={QuotationData} setQuotationData={setQuotationData} setopenQuotationDialog={setopenQuotationDialog} openQuotationDialog={openQuotationDialog} />
      <Container>
        <Box component={Card} p={4} bgcolor='#EDF4F4' mb={2}>
          <Snackbar
            open={isAddedCartSnackbarOpen}
            autoHideDuration={1000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            TransitionComponent={GrowTransition}
            onClose={handleCloseAddedCartSnackbar}>
            <Alert onClose={handleCloseAddedCartSnackbar} severity='success' variant="filled" sx={{ width: '100%' }}>
              {Notification}
            </Alert>
          </Snackbar>
          <Box mt={2} py={4}>
            <Grid container spacing={1}>
              <TableContainer sx={{ border: '1px solid silver' }} >

                <Table>
                  <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
                    <TableRow sx={{ borderBottom: '1px solid silver' }}>
                      <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box sx={{ my: 'auto' }}>
                            Employee Id
                          </Box>
                          <Box>
                            <Filter search={employeeIdSearch} setSearch={setemployeeIdSearch} />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box sx={{ my: 'auto' }}>
                            Employee Name
                          </Box>
                          <Box>
                            <Filter search={userNameSearch} setSearch={setUserNameSearch} />
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box sx={{ my: 'auto' }}>
                            Project Code
                          </Box>
                          <Box>
                            <Filter search={projectCodeSearch} setSearch={setProjectCodeSearch} />
                          </Box>
                        </Box>

                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        Request To
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        Amount
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        Message From Employee
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box sx={{ my: 'auto' }}>
                            Request Date                        </Box>
                          <Box>
                            <Filter search={createdSearch} setSearch={setCreatedSearch} />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        Images
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        Audio File
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        View Quotation
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        Approve
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        Reject
                      </TableCell>

                    </TableRow>
                  </TableHead>

                  <TableBody>
                  {
                      FilterFundReq(data, employeeIdSearch, userNameSearch, projectCodeSearch, createdSearch).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((req, index) => {
                      return (
                        <TableRow sx={{ borderBottom: '1px solid silver' }}>
                          {/* <TableCell sx={{textAlign:'center',borderBottom:'1px solid silver'}}>{req.profile_image}</TableCell> */}
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{req?.employee_id}</TableCell>
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{req?.user_name}</TableCell>
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{req?.project_code}</TableCell>
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{req?.request_to}</TableCell>
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{req?.amount}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{req?.message}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{req?.created_at}
                          </TableCell>

                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>
                            {
                              req.images ?
                                <img src={`${fundRequest}${req?.images}`} style={{ width: 100, height: 100 }} alt={req?.images} />
                                :
                                '-'
                            }
                          </TableCell>

                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>
                            {
                              req.audio_file ?
                                <audio controls>
                                  <source src={`${fundRequest}${req?.audio_file}`} type="audio/mpeg" />
                                  Your browser does not support the audio element.
                                </audio>
                                :
                                '-'
                            }
                          </TableCell>


                          {
                            req.Quotation.length !== 0 ?
                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>
                                <Button onClick={() => handleOpenQuotationDialog(req?.Quotation)} sx={{ bgcolor: '#FF9900', color: '#333', ':hover': { bgcolor: '#616e80' } }}>
                                  Quotation
                                </Button>
                              </TableCell>
                              :
                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>
                                Quotation N/A
                              </TableCell>
                          }
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>
                            <Button onClick={() => handleOpenApproveDialog(req?.notication_id, req?.employee_id, req?.project_code)} sx={{ bgcolor: '#7bc54c', color: '#333', ':hover': { bgcolor: '#616e80' } }}>
                              Approve
                            </Button>
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>
                            <Button onClick={() => handleOpenRejectDialog(req?.notication_id)} sx={{ bgcolor: 'red', color: '#333', ':hover': { bgcolor: '#616e80' } }}>
                              Reject
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Box>
        </Box>
      </Container>


    </Box>
  )
}
