import React, { useEffect, useState } from 'react'
import { Card, Grid, TextField, InputLabel, FormControl, Select, MenuItem, Button, FormHelperText, Checkbox, InputAdornment, IconButton, Typography, Box, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Accordion, AccordionSummary, AccordionDetails, Container } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation, useNavigate } from 'react-router-dom';
import IconBreadcrumbs from '../Components/Breadcrumbs';
import Heading from '../Components/Heading';
import { appendData } from '../Variables/Variables';
import { add_access_page, get_access_page, get_department } from '../API_Service/API_Links';
import SnackBar from '../Components/SnackBar';

function EditAccess() {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [showPassword, setShowPassword] = useState(true);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    // const location = useLocation();
    // const { Id } = location.state;
    // const navigate = useNavigate();
    const [Access, setAccess] = useState(false);
    const [SiteProject, setSiteProject] = useState(false);
    const [AssingnedProjectSite, setAssingnedProjectSite] = useState(false);
    const [CreateProject, setCreateProject] = useState(false);
    const [FactoryProject, setFactoryProject] = useState(false);
    const [Store, setStore] = useState(false);
    const [Purchase, setPurchase] = useState(false);
    const [AssingnedProjectFactory, setAssingnedProjectFactory] = useState(false);
    const [ProjectCreation, setProjectCreation] = useState(false);
    const [MaterialInwards, setMaterialInwards] = useState(false);
    const [MaterialIssued, setMaterialIssued] = useState(false);
    const [Finance, setFinance] = useState(false);
    const [Expense, setExpense] = useState(false);
    const [PaySlip, setPaySlip] = useState(false);
    const [Incentives, setIncentives] = useState(false);
    const [PayOut, setPayOut] = useState(false);
    const [FundRequest, setFundRequest] = useState(false);
    const [HRMS, setHRMS] = useState(false);
    const [Profile, setProfile] = useState(false);
    const [Hierarchy, setHierarchy] = useState(false);
    const [Last3Payout, setLast3Payout] = useState(false);
    const [Leaves, setLeaves] = useState(false);
    const [CTC, setCTC] = useState(false);
    const [Form16, setForm16] = useState(false);
    const [OnBoard, setOnBoard] = useState(false);
    const [Compliants, setCompliants] = useState(false);
    const [Task, setTask] = useState(false);
    const [Announcement, setAnnouncement] = useState(false);
    const [CreateEvent, setCreateEvent] = useState(false);
    const [SeeAllEvents, setSeeAllEvents] = useState(false);
    const [ShowAccord, setShowAccord] = useState(false);
    const [departmentList, setDepartmentList] = useState([]);
    const [Department, setDepartment] = useState('');
    const [calendar, setCalendar] = useState(false);
    const [annualCalendar, setAnnualCalendar]= useState(false);
    const [updateCalendar, setUpdateCalendar] = useState(false);
    const [emiReminder, setEmiReminder]= useState(false);
    const [generalReminder, setGeneralReminder] = useState(false);
    const [allReminders, setAllReminders]= useState(false);
    const [bank, setBank]= useState(false);
    const [cashInHand, setCashInHand]= useState(false);
    const [officeExpense, setOfficeExpense] = useState(false);
    const [ledger, setLedger]= useState(false);

    useEffect(() => {
        axios({
            method: 'GET',
            url: get_department,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setDepartmentList(res.data.data);
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }, [])

const getAccess = () =>{
    if(Department !== ''){
    const sendData = new FormData()
    sendData.append('DeptmentName', Department);
    axios({
        method: 'POST',
        url: get_access_page,
        data:sendData
    }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
            setShowAccord(false);
        } else {
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setAccess(res.data.data.Access)
            setSiteProject(res.data.data.SiteProject)
            setAssingnedProjectSite(res.data.data.AssingnedProjectSite)
            setCreateProject(res.data.data.CreateProject)
            setFactoryProject(res.data.data.FactoryProject)
            setStore(res.data.data.Store)
            setPurchase(res.data.data.Purchase)
            setAssingnedProjectFactory(res.data.data.AssingnedProjectFactory)
            setProjectCreation(res.data.data.ProjectCreation)
            setMaterialInwards(res.data.data.MaterialInwards)
            setMaterialIssued(res.data.data.MaterialIssued)
            setFinance(res.data.data.Finance)
            setExpense(res.data.data.Expense)
            setPaySlip(res.data.data.PaySlip)
            setIncentives(res.data.data.Incentives)
            setPayOut(res.data.data.PayOut)
            setFundRequest(res.data.data.FundRequest)
            setHRMS(res.data.data.HRMS)
            setProfile(res.data.data.Profile)
            setHierarchy(res.data.data.Hierarchy)
            setLast3Payout(res.data.data.Last3Payout)
            setLeaves(res.data.data.Leaves)
            setCTC(res.data.data.CTC)
            setForm16(res.data.data.Form16)
            setOnBoard(res.data.data.OnBoard)
            setCompliants(res.data.data.Compliants)
            setTask(res.data.data.Task)
            setAnnouncement(res.data.data.Announcement)
            setCreateEvent(res.data.data.CreateEvent)
            setSeeAllEvents(res.data.data.SeeAllEvents)
            setCalendar(res.data.data.CreateCalendar)
            setAnnualCalendar(res.data.data.AnnualCalendar)
            setUpdateCalendar(res.data.data.UpdateCalendar)
            setEmiReminder(res.data.data.EMIReminder)
            setGeneralReminder(res.data.data.GeneralReminder)
            setAllReminders(res.data.data.AllReminders)
            setBank(res.data.data.Bank)
            setCashInHand(res.data.data.CashInHand)
            setOfficeExpense(res.data.data.OfficeExpense)
            setLedger(res.data.data.Ledger)
            setShowAccord(true);
        }
    }).catch(err => {
        alert('Oops something went wrong ' + err)
    });
}
}


    const onSubmit = () => {
        const obj = {
        Department: Department,
        Access: Access,
        SiteProject: SiteProject,
        AssingnedProjectSite: AssingnedProjectSite,
        CreateProject: CreateProject,
        FactoryProject: FactoryProject,
        Store: Store,
        Purchase: Purchase,
        AssingnedProjectFactory: AssingnedProjectFactory,
        ProjectCreation: ProjectCreation,
        MaterialInwards: MaterialInwards,
        MaterialIssued: MaterialIssued,
        Finance: Finance,
        Expense: Expense,
        PaySlip: PaySlip,
        Incentives: Incentives,
        PayOut: PayOut,
        FundRequest: FundRequest,
        HRMS: HRMS,
        Profile: Profile,
        Hierarchy: Hierarchy,
        Last3Payout: Last3Payout,
        Leaves: Leaves,
        CTC: CTC,
        Form16: Form16,
        OnBoard: OnBoard,
        Compliants: Compliants,
        Task: Task,
        Announcement: Announcement,
        CreateEvent: CreateEvent,
        SeeAllEvents: SeeAllEvents,
        CreateCalendar:calendar,
        AnnualCalendar:annualCalendar,
        UpdateCalendar:updateCalendar,
        EMIReminder:emiReminder,
        GeneralReminder:generalReminder,
        AllReminders:allReminders,
        Bank:bank,
        CashInHand:cashInHand,
        OfficeExpense:officeExpense,
        Ledger:ledger
        }
        const sendData = appendData(obj);
        axios({
            method: 'POST',
            url: add_access_page,
            data: sendData,
        }).then(res => {
            console.log(res)
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage("Access Updated Successfully")
                setOpen(true)
                setStatus(true)
                setColor(true)
                setShowAccord(false);
                setDepartment('');
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }



    return (
        <Box>
            <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
            <Box py={2} px={1}>
                <IconBreadcrumbs
                    previous={'Home'}
                    current={'Edit Access'}
                    currentSection={'Edit Access'}
                    link1={`/home`}
                    link2={'/home/HRMS'}

                />
            </Box>

            <Container>
                <Box py={3}>
                    <Heading title={'Edit Access'} />
                </Box>
            </Container>

            <Box py={2}>
                <Grid container justifyContent={'center'} spacing={2}>
                    <Grid item textAlign={'center'} xs={12} lg={8}>
                        <Box component={Card} sx={{ p: 2, boxShadow: 3, borderRadius: 5 }}>
                            <Form >
                                <Grid container justifyContent='start' spacing={1}>
                                    <Grid item xs={12} lg={6}>
                                        <Box sx={{ py: 1 }}>
                                            <FormControl fullWidth>
                                                <InputLabel>Role</InputLabel>
                                                <Select
                                                    disableUnderline
                                                    sx={{ textAlign: 'start' }}
                                                    label="Role"
                                                    size='small'
                                                    value={Department}
                                                    onChange={(e)=>setDepartment(e.target.value)}
                                                >
                                                    {
                                                        departmentList.map((i)=>{
                                                            return(
                                                                <MenuItem value={i.Department}>{i.Department}</MenuItem>  
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 , ml:1 }}>
                                            <Button variant='contained' fullWidth onClick={getAccess}>GET RESULT</Button>
                                        </Box>
                                    </Grid>
                                </Grid>


                                {
                                ShowAccord &&
                                <>
                                <Box sx={{ py: 2 }}>
                                    <Accordion sx={{ bgcolor:'#F4F5FA' }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Site Project</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Assigned Site Project
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={AssingnedProjectSite === 'true'} onChange={(e) => setAssingnedProjectSite((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Create Site Project
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={CreateProject === 'true'} onChange={(e) => setCreateProject((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>


                                <Box sx={{ py: 2 }}>
                                    <Accordion sx={{ bgcolor: '#F4F5FA' }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Factory Project</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Assigned Factory Projects
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={AssingnedProjectFactory === 'true'} onChange={(e) => setAssingnedProjectFactory((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Create Factory Project
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={ProjectCreation === 'true'} onChange={(e) => setProjectCreation((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Material Inwards
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={MaterialInwards === 'true'} onChange={(e) => setMaterialInwards((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Material Issued
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={MaterialIssued === 'true'} onChange={(e) => setMaterialIssued((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Store
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={Store === 'true'} onChange={(e) => setStore((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Purchase
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={Purchase === 'true'} onChange={(e) => setPurchase((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>


                                <Box sx={{ py: 2 }}>
                                    <Accordion sx={{ bgcolor: '#F4F5FA' }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Finance</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Expense
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={Expense === 'true'} onChange={(e) => setExpense((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Bank
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={bank === 'true'} onChange={(e) => setBank((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Ledger
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={ledger === 'true'} onChange={(e) => setLedger((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Cash In Hand
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={cashInHand === 'true'} onChange={(e) => setCashInHand((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            OfficeExpense
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={officeExpense === 'true'} onChange={(e) => setOfficeExpense((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Payslip
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={PaySlip === 'true'} onChange={(e) => setPaySlip((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Incentives
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={Incentives === 'true'} onChange={(e) => setIncentives((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Payout
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={PayOut === 'true'} onChange={(e) => setPayOut((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Fund Request
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={FundRequest === 'true'} onChange={(e) => setFundRequest((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion sx={{ bgcolor: '#F4F5FA' }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>HRMS</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Profile
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={Profile === 'true'} onChange={(e) => setProfile((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                               Hierarchy
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={Hierarchy === 'true'} onChange={(e) => setHierarchy((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Last 3 Payout
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={Last3Payout === 'true'} onChange={(e) => setLast3Payout((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Leaves
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={Leaves === 'true'} onChange={(e) => setLeaves((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                               CTC
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={CTC === 'true'} onChange={(e) => setCTC((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Form 16
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={Form16 === 'true'} onChange={(e) => setForm16((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                OnBoard
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={OnBoard === 'true'} onChange={(e) => setOnBoard((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                               Complaints
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            <Checkbox checked={Compliants === 'true'} onChange={(e) => setCompliants((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Access
                                                                    </TableCell>

                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={Access === 'true'} onChange={(e) => setAccess((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                                    </TableCell>
                                                                </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion sx={{ bgcolor: '#F4F5FA' }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Tasks</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Tasks
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                             <Checkbox checked={Task === 'true'} onChange={(e) => setTask((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion sx={{ bgcolor: '#F4F5FA' }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Announcement</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Create Event
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={CreateEvent === 'true'} onChange={(e) => setCreateEvent((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                See All Events
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={SeeAllEvents === 'true'} onChange={(e) => setSeeAllEvents((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Create Calendar
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={calendar === 'true'} onChange={(e) => setCalendar((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>


                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Annual Calendar
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={annualCalendar === 'true'} onChange={(e) => setAnnualCalendar((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>


                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Update Calendar
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={updateCalendar === 'true'} onChange={(e) => setUpdateCalendar((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>


                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            EMI Reminder
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={emiReminder === 'true'} onChange={(e) => setEmiReminder((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>


                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            General Reminder
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={generalReminder === 'true'} onChange={(e) => setGeneralReminder((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>


                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            All Reminders
                                                            </TableCell>

                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox checked={allReminders === 'true'} onChange={(e) => setAllReminders((prevValue) => prevValue === 'true' ? 'false' : 'true')} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                
                                
                                <Box sx={{ py: 2, textAlign: 'center' }}>
                                    <Button onClick={onSubmit} variant="contained" endIcon={<SendIcon />}>Submit</Button>
                                </Box>

                                    </>
                                }
                            </Form>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default EditAccess