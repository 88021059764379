import React, { useState, useEffect } from "react";
import { Table, TableBody,Card, TableCell, TableContainer, TableHead, TableRow, Paper , Grid, Box, Container, TablePagination, IconButton, Button, TextField, Autocomplete, Dialog } from "@mui/material";
import { bankimages, expenseDocs, getAccountDetails, getAllEmployeeName, getBalanceDetails, getBankTrancationDetails, get_all_food_expense, get_all_rental_expense, get_all_transport_expense,
   get_all_travel_expense, 
   methodGet, 
   methodPost} from "../../../API_Service/API_Links";
import axios from "axios";
import { FilterData, FilterSumCat, FilterTwoData } from "../../FilterData/FilterData";
import Filter from "../../FilterData/Filter";
import SnackBar from "../../SnackBar";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteBank from "./DeleteBank";
import { LedgerExport } from "../Ledger/LedgerExport";
import ImageUpdateDialog from "./ImageUpdateDialog";

export default function Debit() {

    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
   const [search, setSearch] = useState("");
   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
   const [dialogData  , setDialogData] = useState([]);
   const [openImageDialog, setOpenImageDialog] = useState(false);
   const [selectedImage , setSelectedImage] = useState('')

   const navigate = useNavigate();

   const [empname, setEmpname] = useState([]);
   const [EmployeeName, setEmployeeName] = useState("");
   const [EmployeeId, setEmployeeId] = useState(null);
   const [isFilterOn, setIsFilterOn] = useState(false);
   const [fromDate, setFromDate] = useState('');
   const [toDate, setToDate] = useState('');
   const [overallSearch, setOverallSearch] = useState('');
   const [allSummary, setAllSummary] = useState([]);
   const [openUpdateDialog, setopenUpdateDialog] = useState(false);
   const[input , setInput] = useState(false);


   useEffect(() => {
    axios({
        method: methodGet,
        url: getAllEmployeeName,
    })
        .then((res) => {
            if (res.data.error) {
                setMessage(res.data.message);
                setOpen(true);
                setStatus(false);
                setColor(false);
            } else {
                setMessage(res.data.message);
                setEmpname(res.data.data);
                setOpen(true);
                setStatus(true);
                setColor(true);
            }
        })
        .catch((err) => {
            alert("Oops something went wrong " + err);
            console.log("chip1", err);
        });
}, []);

  const getAllSummary = () => {
    const sendData = new FormData()
    sendData.append('EmployeeId', '')
    sendData.append('FromDate', '')
    sendData.append('ToDate', '')
    sendData.append('Search', '')
    axios({
        method: methodPost,
        url: getBankTrancationDetails,
        data: sendData
    })
        .then((res) => {
            if (!res.data.error) {
                setMessage(res.data.message);
                setStatus(true);
                setColor(true);
                setOpen(true);
                setAllSummary(res.data.data);
            } else {
                setMessage(res.data.message);
                setStatus(false);
                setColor(false);
            }
        })
        .catch((err) => {
            alert("Oops something went wrong " + err);
        });
}

useEffect(() => {
    getAllSummary();
}, []);


const getFilteredSummary = () => {
        const sendData = new FormData()
        sendData.append('FromDate', fromDate)
        sendData.append('ToDate', toDate)
        sendData.append('Search', overallSearch)
        sendData.append('EmployeeId', EmployeeId)
        axios({
            method: methodPost,
            url: getBankTrancationDetails,
            data: sendData
        })
            .then((res) => {
                if (!res.data.error) {
                    setMessage(res.data.message);
                    setStatus(true);
                    setColor(true);
                    setOpen(true);
                    setAllSummary(res.data.data);
                } else {
                    setMessage(res.data.message);
                    setStatus(false);
                    setColor(false);
                    setOpen(true);
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
            });
    }


    const keys = Object.keys(allSummary[0] || {}).filter((key) => key !== "Id");

    const removeFilter = () =>{
      setFromDate("")
      setToDate("")
      getAllSummary();
      setEmployeeId(null)
      setInput(true);
  }


      const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };



    const handleOpenDialog = (i) => {
      setDialogData(i);
      setopenUpdateDialog(true);
      }

      const handleImageDialog = (data) =>{
        setOpenImageDialog(!openImageDialog);
        setSelectedImage(data)
      }


  return (
      <Box boxShadow={4} bgcolor='#EDF4F4' py={2}>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />

<ImageUpdateDialog getAllSummary={getAllSummary}  setMessage={setMessage} setStatus={setStatus} setOpen={setOpen} setColor={setColor} setopenUpdateDialog={setopenUpdateDialog} openUpdateDialog={openUpdateDialog} setDialogData={setDialogData} dialogData={dialogData} />

<Dialog open={openImageDialog} onClose={() => setOpenImageDialog(false)}>
      <img src={`${bankimages}${selectedImage}`} alt='alt' />
      </Dialog>

<Container>
                <Box component={Card} p={4}>
                    <Grid container spacing={3} justifyContent='start'>
                    <Grid item xs={6} sm={4} md={3} lg={3}>
                            <Box mt={1}>

                                <Autocomplete
                                clearText="Clear"
                                    disablePortal
                                    size='small'
                                    id="combo-box-demo"
                                    options={empname}
                                    getOptionLabel={(option) => `${option.EmployeeName} (${option.EmployeeId})`}
                                    onChange={(event, value) => setEmployeeId(value ? value.EmployeeId : '')}
                                    renderInput={(params) => <TextField color='secondary' label='Employee Name' {...params} />}
                                />
                            </Box>
                        </Grid>
                <Grid item xs={6} sm={4} md={2} lg={3}>
                <Box mt={1}>
                <TextField
                fullWidth
                id="EmployeeName"
                label="Start Date"
                type="date"
                variant="outlined"
                size="small"
                color="secondary"
                value={fromDate}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setFromDate(e.target.value)}

                />
                </Box>
                </Grid>

                <Grid item xs={6} sm={4} md={3} lg={3}>
                <Box mt={1}>
                <TextField
                id="EmployeeName"
                label="To Date"
                type="date"
                fullWidth
                variant="outlined"
                size="small"
                color="secondary"
                value={toDate}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setToDate(e.target.value)}
                 inputProps={{ min: fromDate }}
                />
                </Box>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={3}>
                            <Box mt={1}>
                            <TextField
                            name="category"
                            label="Search"
                            fullWidth
                            value={overallSearch}
                            size='small'
                            onChange={(e)=>setOverallSearch(e.target.value)}
                            />
                            </Box>
                        </Grid>


                {
                isFilterOn ?
                <Grid item xs={6} sm={4} md={3} lg={3}>
                <Box display='flex' justifyContent='center'>
                <Button  fullWidth onClick={removeFilter} variant='contained' sx={{ bgcolor: 'red' }} >Remove Filter</Button>
                </Box>
                </Grid>
                :
                <Grid item xs={6} sm={4} md={3} lg={3}>
                <Box display='flex' justifyContent='center'>
                <Button fullWidth onClick={getFilteredSummary} variant='contained' sx={{ bgcolor: 'secondary.main'}} >FILTER DATA</Button>
                </Box>
                </Grid>
                }


              <Grid item xs={6} sm={4} md={3} lg={3}>
                <Box display='flex' justifyContent='center'>
                <LedgerExport  fileName={'Debit Report'} csvData={allSummary} />
                </Box>
                </Grid>
                </Grid>


                </Box>
 </Container>

   <Container>
    <Box pt={3} pb={4}>

    <Grid container spacing={1}>
      <TableContainer sx={{border:'1px solid silver'}} >
         <Table>
         <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
          <TableRow>
            {keys.map((key) => (
             <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }} key={key}>
                <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                {key.split(/(?=[A-Z])/).join(' ')}
                </Box>
            </Box>
             </TableCell>
            ))}
            <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center', textTransform: 'uppercase'  }}>Update</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {allSummary.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow key={index}>
                {keys.map((key) => (
                  <TableCell align="center" key={key}>
                    {key === "Images" ? (
                      row[key] ? (
                        <img src={`${bankimages}${row[key]}`} onClick={()=>handleImageDialog(row[key])} style={{ width: 100, height: 100 }} alt={row[key]} />
                      ) : (
                        "-"
                      )
                    ) : (
                      row[key]
                    )}
                  </TableCell>
                ))}
                <TableCell align="center">
                <Button onClick={()=>handleOpenDialog(row)} variant="contained" >UPDATE</Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={allSummary.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
          </Grid>
          </Box>


</Container>
  </Box>
  )
}
