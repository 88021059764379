import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Card, Container, Grid } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LayersIcon from '@mui/icons-material/Layers';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import Heading from '../Heading';
import IconBreadcrumbs from '../Breadcrumbs';
import { get_employee_activities, methodPost } from '../../API_Service/API_Links';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';


export default function ProfileEmployeeDialog() {

  const [Task, setTask] = useState([]);
  const [Projects, setProject] = useState([]);
  const [Leaves, setLeaves] = useState([]);
  const [EmployeeName, setEmployeeName] = useState("");
  const [Designation, setDesignation] = useState('')
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const EmployeeId = JSON.parse(localStorage.getItem('EmployeeId'));

  useEffect(() => {
    const sendData = new FormData()
    sendData.append('EmployeeId', EmployeeId)
    axios({
      method: methodPost,
      url: get_employee_activities,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setEmployeeName(res.data.data.EmployeeName)
          setDesignation(res.data.data.Designation)
          setTask(res.data.data.Task)
          setProject(res.data.data.Projects)
          setLeaves(res.data.data.Leaves)
          setOpen(true);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

   

  return (
    <div>

      <Box py={2} px={1}>
    <IconBreadcrumbs
    previous={'Home'}
    current={'HRMS'}
    currentSection={'Profile'}
    link1={`/home`}
    link2={'/home/HRMS'}
    
    />
    </Box>

    <Container>
      <Box py={3}>
      <Heading  title={'Your Profile'}/>
      </Box>
    </Container>

          <Container>
            <Box p={3}>
          <Grid container spacing={4} rowSpacing={4}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box component={Card} p={4} alignItems='center'
                display='flex' flexDirection='row'>
                <Box>
                  <img alt='profile' src='https://wallpapers.com/images/featured/s52z1uggme5sj92d.jpg'
                    style={{ objectFit: 'contain', width: 100, height: 100, borderRadius: 10 }} />
                </Box>
                <Box px={2}>
                  <Typography color='#28313c' fontWeight={600} fontSize={17}>{EmployeeName}</Typography>
                  <Typography color='#616e80' variant='subtitle1'>{Designation}</Typography>
                  <Typography color='#616e80' variant='caption'>Emp Id : {EmployeeId}</Typography>
                </Box>
              </Box>

              <Box mt={2} component={Card} p={2} display='flex' justifyContent='space-between' flexDirection='column'>
                <Box display='flex' justifyContent='space-between' flexDirection='row'>
                  <Typography fontWeight={600}>Leaves</Typography>
                  <Box alignSelf='center'>
                    <HolidayVillageIcon sx={{ color: '#616e80' }} fontSize='large' />
                  </Box>
                </Box>
                <Box display='flex' flexDirection='row' mt={3} justifyContent='space-evenly'>
                  <Box textAlign='center'>
                    <Typography color='#3860b5'>{Leaves.Approved}</Typography>
                    <Typography color='#3860b5'>Approved</Typography>
                  </Box>
                  <Box textAlign='center'>
                    <Typography color='#c2312f'>{Leaves.Rejected}</Typography>
                    <Typography color='#c2312f'>Rejected</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box component={Card} p={3} display='flex' justifyContent='space-between' flexDirection='column'>
                <Box display='flex' justifyContent='space-between' flexDirection='row'>
                  <Typography fontWeight={600}>Tasks</Typography>
                  <Box alignSelf='center'>
                    <FormatListBulletedIcon sx={{ color: '#616e80' }} fontSize='large' />
                  </Box>
                </Box>
                <Box display='flex' flexDirection='row' mt={3} justifyContent='space-evenly'>
                  <Box mr={2} textAlign='center'>
                    <Typography color='#3860b5'>{Task.Progress}</Typography>
                    <Typography color='#3860b5'>In Progress</Typography>
                  </Box>
                  <Box ml={2} textAlign='center'>
                    <Typography color='#c2312f'>{Task.Overdue}</Typography>
                    <Typography color='#c2312f'>Overdue</Typography>
                  </Box>

                </Box>

              </Box>

              <Box mt={3} component={Card} p={2} display='flex' justifyContent='space-between' flexDirection='column'>
                <Box display='flex' justifyContent='space-between' flexDirection='row'>
                  <Typography fontWeight={600}>Projects</Typography>
                  <Box alignSelf='center'>
                    <LayersIcon sx={{ color: '#616e80' }} fontSize='large' />
                  </Box>
                </Box>
                <Box display='flex' flexDirection='row' mt={3} justifyContent='space-evenly'>
                  <Box mr={2} textAlign='center'>
                    <Typography color='#3860b5'>{Projects.Completed}</Typography>
                    <Typography color='#3860b5'>Completed</Typography>
                  </Box>
                  <Box ml={2} textAlign='center'>
                    <Typography color='#c2312f'>{Projects.Overdue}</Typography>
                    <Typography color='#c2312f'>Overdue</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>




          </Grid>
            </Box>
          </Container>



          <Container>
            <Box textAlign='center' component={Card} py={3} mx={3}>
                <Typography  fontWeight={600} variant='h6'>Your Hierarchy</Typography>
            </Box>
             <Box className="tree" py={3}>
	<ul>
  <li>
    <a href="#">Employee Name</a>
    <ul>
      <li>
        <a href="#">Team Lead Name</a>
        <ul>
          <li>
            <a href="#">Manager</a>
          </li><li>
            <a href="#">Assistant Manager</a>
            <ul>
              <li>
                <a href="#">President</a>
              </li><li>
                <a href="#">Vice President</a>
              </li>
            </ul>
          </li>
        </ul>
      </li><li>
        <a href="#">Team Lead Name</a>
        <ul>
          <li>
            <a href="#">Senior Manager</a>
            <ul>
              <li>
                <a href="#">Director</a>
              </li>
            </ul>
          </li><li>
            <a href="#">Manager</a>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>
</Box>
          </Container>
    </div>
  );
}