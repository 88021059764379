import React, { useEffect, useState } from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import Heading from '../../Heading';
import Breadcrumbs from '../../Breadcrumbs';
import { assignedProjectsempTable, LeaveApprovaltable, paysliptable } from '../../../Variables/Variables';
import Filter from '../../FilterData/Filter';
import {FilterData, FilterMaterial} from '../../FilterData/FilterData';
import { useNavigate } from 'react-router-dom';
import { get_all_factory_project, methodGet, get_project_for_store, getmaterial, getCommercialFromSales, updateCommercialToTechnical, methodPost, getOnDutyApprovel, statusUpdateExpense, updateOndutyDetails } from '../../../API_Service/API_Links';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import axios from 'axios';
import SnackBar from '../../SnackBar';

export default function OnDutyPendingPage() {

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [dialogData ,setDialogData]= useState();
  const [openApproveDialog, setopenApproveDialog] = useState(false);

  const [materialCodeSearch, setmaterialCodeSearch] = useState('');
  const [materialNameSearch, setmaterialNameSearch] = useState('');


  const expenseStatus = () =>{
    axios({
         method:'GET',
         url: getOnDutyApprovel,
     }).then(res => {
         if (res.data.error) {
             setMessage(res.data.message)
             setOpen(true)
             setStatus(false)
             setColor(false)
         } else {
             setdata(res.data.data);
             console.log(res.data.data);
         }
     }).catch(err => {
         alert('Oops something went wrong ' + err)
     });
   }
   useEffect(()=>{
     expenseStatus();
 },[]);

  const statusMapping = {
    1: 'In-Progress',
    2: 'Purchase Pending',
    3: 'Finance Pending',
    4: 'Finance Approved',
    5: 'Raw Material Available',
    6: 'Assigned To Employee',
    7: 'Completed',
  };

const handleChangePage = (event, newPage) => {
    setPage(newPage);
};
const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

 const handleOpenEdit = (i) =>{
   navigate('viewstore', {state:{id:i.ProjectId}});
 }

 const keys = Object.keys(data[0] || {}).filter((key) => key !== "MaterialId");

 const Reject  = (row) =>{
    const sendData = new FormData()
    sendData.append('Id', row.id)
    sendData.append('ondutyStatus', "Rejected")
    axios({
        method: methodPost,
        url: updateOndutyDetails,
        data:sendData
      })
        .then((res) => {
          if (!res.data.error) {
            setMessage(res.data.message);
            setStatus(true);
            setColor(true);
            setOpen(true);
            expenseStatus()
          } else {
            setMessage(res.data.message);
            setOpen(true)
            setStatus(false)
            setColor(false)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
   }

 const Approve  = (row) =>{
  const sendData = new FormData()
  sendData.append('Id', row.id)
  sendData.append('ondutyStatus', "Approved")
  axios({
      method: methodPost,
      url: updateOndutyDetails,
      data:sendData
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setOpen(true);
          expenseStatus()
        } else {
          setMessage(res.data.message);
          setOpen(true)
          setStatus(false)
          setColor(false)
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
 }

  return (
    <Box boxShadow={4} bgcolor='#EDF4F4'>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />
    <Container>
    <Box mt={2} py={4}  >
    <Grid container>


        <TableContainer sx={{border:'1px solid silver'}} >
         <Table>
         <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
          <TableRow>
            {keys.map((key) => (
             <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }} key={key}>
                <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                {key.split(/(?=[A-Z])/).join(' ')}
                </Box>
                  {key === "MaterialCode" && (
                    <Box>
                      <Filter search={materialCodeSearch} setSearch={setmaterialCodeSearch} setPage={setPage} />
                    </Box>
                  )
                  }
                  {key === "MaterialName" && (
                    <Box>
                      <Filter search={materialNameSearch} setSearch={setmaterialNameSearch} setPage={setPage} />
                    </Box>
                  )
                  }
            </Box>
             </TableCell>
            ))}
             <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center', textTransform: 'uppercase' }}>Approve</TableCell>
             <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }}>Reject</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {FilterMaterial(data, materialNameSearch, materialCodeSearch)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow key={index}>
                {keys.map((key) => (
                  <TableCell align="center" key={key}>
                    {row[key]}
                  </TableCell>
                ))}
                <TableCell align="center">
                <Button onClick={()=>Approve(row , 1)} variant="contained" >Approve</Button>
                </TableCell>
                <TableCell align="center">
                <Button onClick={()=>Reject(row)} color="error" variant="contained" >Reject</Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
              </Table>
            </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={FilterMaterial(data, materialCodeSearch , materialNameSearch).length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Grid>
                  </Box>
                </Container>
  </Box>
  )
}



