import React, { useState } from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import Heading from '../Heading';
import Breadcrumbs from '../Breadcrumbs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import ProfileDialog from './ProfileDialog';
import Filter from '../FilterData/Filter';
import {FilterData, FilterThreeData, filterEmpOrders} from '../FilterData/FilterData';
import IconBreadcrumbs from '../Breadcrumbs';
import { get_all_employee_profile, methodGet } from '../../API_Service/API_Links';
import axios from 'axios';
import SnackBar from '../SnackBar';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Profile() {

  const [value, setValue] = React.useState(dayjs('2022-04-07'));
  const { formState: { errors }, handleSubmit } = useForm();
  const [EmployeeId, setEmployeeId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openTable, setOpenTable] = useState(false);
  const options = ['Approved', 'Pending', 'Rejected'];
  const[openDialog, setOpenDialog]= useState(false);
    const [search, setSearch] = useState("");
  const [allProfileTable, setallProfileTable] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
};
const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

const openProfile = (id) =>{
   setOpenDialog(true);
   setEmployeeId(id);
}

  useEffect(() => {
    axios({
      method: methodGet,
      url: get_all_employee_profile,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setallProfileTable(res.data.data);
          setOpen(true);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  return (
    <Box>

      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />
      <ProfileDialog setMessage={setMessage} setColor={setColor} setStatus={setStatus} setOpen={setOpen} openDialog={openDialog} EmployeeId={EmployeeId} setEmployeeId={setEmployeeId} setOpenDialog={setOpenDialog} />

      <Box py={2} px={1}>
    <IconBreadcrumbs
    previous={'Home'}
    current={'HRMS'}
    currentSection={'Profile'}
    link1={`/home`}
    link2={'/home/HRMS'}
    
    />
    </Box>

    <Container>
      <Box py={3}>
      <Heading  title={'View All Profile'}/>
      </Box>
    </Container>

   <Container>
   <Box component={Card} p={4} mb={2} bgcolor='#EDF4F4'>
    <Box mt={2}>
    <Grid container spacing={1}>
                    <TableContainer sx={{border:'1px solid silver'}} >

                    <Table>
                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
                    <TableRow sx={{borderBottom:'1px solid silver'}}>
                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600  }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box sx={{ my: 'auto' }}>
                            Employee ID
                          </Box>
                          <Box>
                            <Filter search={search} setSearch={setSearch} />
                          </Box>
                        </Box>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                     <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                      <Box sx={{ my: 'auto' }}>
                            Employee Name
                      </Box>
                      <Box>
                     <Filter search={search} setSearch={setSearch} />
                      </Box>
                  </Box>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                      <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                      <Box sx={{ my: 'auto' }}>
                         Joining Date
                      </Box>
                      <Box>
                     <Filter search={search} setSearch={setSearch} />
                      </Box>
                  </Box>
                    </TableCell> 
                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                      Employment Status
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                      View Profile
                    </TableCell>
                    </TableRow>
                    </TableHead>


                    <TableBody>
                   
                      {
                      allProfileTable.filter((data) => FilterThreeData(data, search, {
                          searchFeildOne: data.EmployeeId,
                          searchFeildTwo: data.EmployeeName,
                          searchFeildThree: data.DateOfJoining,
                                })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i,index)=>{
                          return(
                    <TableRow key={index} sx={{borderBottom:'1px solid silver'}}>
                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.EmployeeId}</TableCell>
                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.EmployeeName}</TableCell>
                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.DateOfJoining}</TableCell>
                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.EmploymentStatus}</TableCell>
                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>
                                <Button onClick={() => openProfile(i.EmployeeId)} sx={{ bgcolor: '#7bc54c', ':hover': { bgcolor: '#616e80' } }}>View</Button>
                              </TableCell>
                    </TableRow>
                          )
                        })
                      }

                    </TableBody>
                    </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={2}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Grid>
                  </Box>
                </Box>
                </Container>
                

  </Box>
  )
}
