import React from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container} from "@mui/material";
import Heading from '../Heading';
import Breadcrumbs from '../Breadcrumbs';
import { financepageControllerView, financepageEmployeeView } from '../../Variables/Variables';
import { Outlet, useNavigate } from 'react-router-dom';
import IconBreadcrumbs from '../Breadcrumbs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import FuelCreation from './CreateNewExpense/FuelCreation';
import RentalCreation from './CreateNewExpense/RentalCreation';
import TravelCreation from './CreateNewExpense/TravelCreation';
import TransportCreation from './CreateNewExpense/TransportCreation';
import AccomadateCreation from './CreateNewExpense/AccomadateCreation';
import FoodCreation from './CreateNewExpense/FoodCreation';
import MaintenanceCreation from './CreateNewExpense/MaintenanceCreation';
import GeneralCreation from './CreateNewExpense/GeneralCreation';
import WageCreation from './CreateNewExpense/WageCreation';
import PurchaseCreation from './CreateNewExpense/PurchaseCreation';
import UtilCreation from './CreateNewExpense/UtilCreation';
import OtherCreation from './CreateNewExpense/OtherCreation';
import CategoriesCreation from './CreateNewExpense/CategoriesCreation';
import CreateNewExpensesTab from './CreateNewExpensesTab';
import CreateSuggestion from './CreateNewExpense/CreateNewSuggestion';
import CreateListTab from './CreateListTab';



export default function NewExpensesTab() {

  const [value, setValue] = useState("1");

  // Tab functionality
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };


    const role = JSON.parse(localStorage.getItem('role'));


  return (
    <Box>

    <Box py={2} px={1}>
    <IconBreadcrumbs
    previous={'Home'}
    current={'Expense'}
    link1={`/home`}
    link2={'/home/finance/expense'}
    currentSection={'New Expense'}
    />
    </Box>

    <Box>
    <TabContext value={value}>
      <Box>
          <TabList
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          onChange={handleChange} aria-label="tabs">
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Create New Expenses" value="1" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="Create List" value="2" />
          {/* <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="Create New Suggestion" value="3" /> */}
          </TabList>
          </Box>

          <TabPanel value="1">
          <Box>
          <CreateNewExpensesTab />
          </Box>
          </TabPanel>

          <TabPanel value="2">
          <Box>
          <CreateListTab />
          </Box>
          </TabPanel>
          {/* <TabPanel value="3">
          <Box>
          <CreateSuggestion />
          </Box>
          </TabPanel> */}
        </TabContext>
      </Box>
  </Box>
  )
}
