import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper , Grid, Box, Container, TablePagination, IconButton, Button } from "@mui/material";
import { expenseDocs, getAccountDetails, getOfficeExpense, get_all_food_expense, get_all_rental_expense, get_all_transport_expense,
   get_all_travel_expense } from "../../../API_Service/API_Links";
import axios from "axios";
import { FilterData, FilterSumCat, FilterTwoData } from "../../FilterData/FilterData";
import Filter from "../../FilterData/Filter";
import SnackBar from "../../SnackBar";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import OfficeApproveDialog from "./OfficeApproveDialog";

export default function AllOfficeExpense() {

    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState("");
    const [openApproveDialog, setOpenApproveDialog] = useState(false);
    const [dialogData  , setDialogData] = useState([]);



    const navigate = useNavigate();

 const getOffice = () =>{
  axios({
    method:'GET',
    url: getOfficeExpense,
}).then(res => {
    if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
    } else {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data);
    }
}).catch(err => {
    alert('Oops something went wrong ' + err)
});
 }

  useEffect(()=>{
    getOffice()
  },[]);

    const keys = Object.keys(data[0] || {}).filter((key) => key !== "Id");

      const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleOpenEdit = (i) =>{
    navigate('updatebank' , {state:{data:i}})
    }

    const handleOpenDialog = (i) => {
      setDialogData(i);
      setOpenApproveDialog(true);
      }

  return (
      <Box boxShadow={4} bgcolor='#EDF4F4'>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />
      <OfficeApproveDialog getOffice={getOffice} openApproveDialog={openApproveDialog} setOpenApproveDialog={setOpenApproveDialog} setMessage={setMessage} setStatus={setStatus} setOpen={setOpen} setColor={setColor} data={dialogData} />
   <Container>
    <Box pt={3} pb={4}>

    <Grid container spacing={1}>
      <TableContainer sx={{border:'1px solid silver'}} >
         <Table>
         <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
          <TableRow>
            {keys.map((key) => (
             <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }} key={key}>
                <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                {key.split(/(?=[A-Z])/).join(' ')}
                </Box>
            </Box>
             </TableCell>
            ))}
            <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }}>APPROVE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow key={index}>
                {keys.map((key) => (
                  <TableCell align="center" key={key}>
                    {row[key]}
                  </TableCell>
                ))}
                <TableCell  align="center" >
                <Button onClick={()=>handleOpenDialog(row)} 
                sx={{ bgcolor: '#7bc54c', color: '#333', ':hover': { bgcolor: '#616e80' } }}>
                   Approve
                 </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
          </Grid>
          </Box>


</Container>
  </Box>
  )
}
