import React,{ useState } from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container, Divider} from "@mui/material";
import Heading from '../../../Components/Heading';
import Breadcrumbs from '../../../Components/Breadcrumbs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useNavigate } from 'react-router-dom';
import CreateCalender from './Calender';
import AnnualCalender from './AnnualCalendar';
import UpdateCalender from './UpdateCalendar';

export default function CalendarTab() {

    const userName = JSON.parse(localStorage.getItem('user'));
    const role = JSON.parse(localStorage.getItem('role'));

      const [value, setValue] = useState("1");

  // Tab functionality
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const navigate = useNavigate();

  const handleClick = (path) =>{
   navigate(`${path}`)
  }

  const access = JSON.parse(localStorage.getItem('access'));
  const { CreateCalendar , AnnualCalendar , UpdateCalendar } = access;


  return (
    <Box>

    <Box py={2} px={1}>
    <Breadcrumbs 
    previous={'Home'}
    current={'Announcement'}
    link1={`/home`}
    link2={'/home/announcement'}
    currentSection={'Calendar'}
    />
    </Box>

    <Container>
      <Box py={3}>
      <Heading  title={'Calendar'}/>
      </Box>
    </Container>



 <Container>

    <Box >
    <TabContext value={value}>
      <Box>
          <TabList
                     variant="scrollable"
                     scrollButtons
                     allowScrollButtonsMobile
          onChange={handleChange} aria-label="tabs">
                 {
            CreateCalendar === 'true' && 
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginLeft: 5 }} label="Create Calendar" value="1" />
            }
                   {
            AnnualCalendar === 'true' && 
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginLeft: 5 }} label="Annual Calendar" value="2" />
            }
            {
            UpdateCalendar === 'true' && 
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginLeft: 5 }} label="Update Calendar" value="3" />
            }
          </TabList>
          </Box>
          {
        CreateCalendar === 'true' &&
        <TabPanel value="1">
        <Box>
        <CreateCalender />
        </Box>
        </TabPanel>
        }
                        {
   AnnualCalendar=== 'true' &&
        <TabPanel value="2">
        <Box>
        <AnnualCalender />
        </Box>
        </TabPanel>
        }
       {
       UpdateCalendar === 'true' &&
        <TabPanel value="3">
        <Box>
        <UpdateCalender />
        </Box>
        </TabPanel>
        }
        </TabContext>
      </Box>
    </Container>

  </Box>
  )
}
