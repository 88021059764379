import React, { useState } from 'react'
import { Box, Button, Grid, TextField, Typography, Container, Autocomplete, FormControlLabel, Switch } from "@mui/material";
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';

import { Form } from 'react-bootstrap';
import axios from 'axios';
import { appendData } from '../../../Variables/Variables';
import SnackBar from '../../SnackBar';
import { useLocation, useNavigate } from 'react-router-dom';
import IconBreadcrumbs from '../../Breadcrumbs';
import Heading from '../../Heading';
import { useEffect } from 'react';
import { getUnderMaintenanceId, updateUnderMaintenance } from '../../../API_Service/API_Links';

export default function UnderMaintenanceEditForm() {
  const { formState: { errors }, handleSubmit } = useForm();
  const [maintenanceCategoryId, setMaintenanceCategoryId] = useState(null);
  const [maintenanceCategoryName, setMaintenanceCategoryName] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState(null);
  const [mechineDetails, setMechineDetails] = useState(null);
  const [vehiclesDetails, setVehiclesDetails] = useState(null);
  const [subCategoryImages, setSubCategoryImages] = useState(null);

  const underMaintenanceOptions = ['Machine', 'Vehicle'];

  const handleUnderMaintenanceChange = (event, value) => {
    setMaintenanceCategoryId(value);
  };
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const RoleId = '7';
  const [fileTypeError, setFileTypeError] = useState('');

  const location = useLocation();

  const id = location.state.id;

  // GET Current Project
  useEffect(() => {
    const serverData = new FormData()
    serverData.append('Id', id);
    axios({
      method: 'POST',
      url: getUnderMaintenanceId,
      data: serverData
    }).then(res => {
      if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
      } else {
        setMessage(res.data.message)
        setMaintenanceCategoryId(res.data.data.maintenance_category_id)
        setMaintenanceCategoryName(res.data.data.maintenance_category_id)
        setSubCategoryName(res.data.data.sub_category_name)
        setMechineDetails(res.data.data.mechine_details)
        setVehiclesDetails(res.data.data.vehicles_details)
        setOpen(true)
        setStatus(true)
        setColor(true)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }, [])

  const cancelClick = () => {
    navigate('/home/finance/expense/maintenance');
  }

  const onSubmit = () => {
    const obj = {
      id: id,
      maintenanceCategoryId: maintenanceCategoryId,
      subCategoryName: subCategoryName,
      mechineDetails: mechineDetails,
      vehiclesDetails: vehiclesDetails,
      subCategoryImages: subCategoryImages,
    }
    const ldata = appendData(obj);
    axios({
      method: 'POST',
      url: updateUnderMaintenance,
      data: ldata,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        document.getElementById('subCategoryImages').value = '';
      } else {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  return (
    <Box>
      <Box py={2} px={1}>
        <IconBreadcrumbs
          previous={'Home'}
          current={'Expense'}
          link1={`/home`}
          link2={'/home/finance/expense'}
          currentSection={'Under Maintenance Edit Form'}
        />
      </Box>
      <Container>
        <Box py={3}>
          <Heading title={'Update Under Maintenance'} />
        </Box>
      </Container>
      <Box p={3}>
        <SnackBar
          open={open}
          message={message}
          setOpen={setOpen}
          status={status}
          color={color}
        />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              px: 5,
              backgroundColor: "#EDF4F4",
              borderRadius: "10px",
              mx: 2,
              my: 4,
              boxShadow: 11,
            }}
          >

            <Grid
              container
              justifyContent="center"
              sx={{ textAlign: "center" }}
              spacing={4}
            >
              <Grid item lg={12} xl={12}>
                <Box
                  sx={{
                    border: "1px solid black",
                    p: 4,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 5,
                  }}
                >
                  <Grid container justifyContent="start" spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Autocomplete
                        disablePortal
                        id="time-combo-box"
                        options={underMaintenanceOptions}
                        value={maintenanceCategoryName}
                        onChange={handleUnderMaintenanceChange}
                        size="small"
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ my: 1 }}
                            fontSize="small"
                            label="Maintenance Category"
                            color="secondary"
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                          />
                        )}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <TextField
                        label="SubCategory Name"
                        value={subCategoryName}
                        onChange={(e) => setSubCategoryName(e.target.value)}
                        fullWidth
                        sx={{ my: 1 }}
                        size='small'
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    {maintenanceCategoryId === 'Vehicle' ? (
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                          name="Vehicles Details"
                          label="Vehicles Details"
                          value={vehiclesDetails}
                          onChange={(e) => setVehiclesDetails(e.target.value)}
                          fullWidth
                          sx={{ my: 1 }}
                          size="small"
                          required
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                          label="Mechine Details"
                          value={mechineDetails}
                          onChange={(e) => setMechineDetails(e.target.value)}
                          fullWidth
                          sx={{ my: 1 }}
                          size='small'
                          required
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <TextField
                        name="Image"
                        label="SubCategory Images"
                        type='file'
                        id='subCategoryImages'
                        onChange={(e) => setSubCategoryImages(e.target.files[0])}
                        fullWidth
                        sx={{ my: 1 }}
                        size='small'
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                  </Grid>
                </Box>
              </Grid>
            </Grid>


            {/* {buttons}  */}
            <Grid
              container
              justifyContent="center"
              sx={{ textAlign: "center", mt: 3 }}
            >
              <Grid item lg={6} xl={6} xs={12}>
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
                    <Stack spacing={2} direction="row">
                      <Button
                        fullWidth
                        variant="outlined"
                        type="submit"
                        sx={{
                          color: "white",
                          backgroundColor: "#7bc54c",
                          borderColor: "#7bc54c",
                          ":hover": {
                            borderColor: "#7bc54c",
                            color: "#000000",
                          },
                        }}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </Grid>

                  <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
                    <Stack spacing={2} direction="row">
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={cancelClick}
                        type="cancel"
                        sx={{
                          color: "white",
                          backgroundColor: "#c62828",
                          borderColor: "#c62828",
                          ":hover": {
                            borderColor: "#c62828",
                            color: "#000000",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Box>
        </Form>
      </Box>
    </Box>
  );
}
