import React, { useState } from "react";
import { Box, Tab, Grid } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CreateSuggestion from "./CreateNewExpense/CreateNewSuggestion";
import CategoriesCreation from "./CreateNewExpense/CategoriesCreation";
import CreateDuration from "./CreateList/CreateDuration";

export default function CreateListTab() {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <TabContext value={value}>
        <Grid container>
          <Grid item xs={3}>
            <TabList
              orientation="vertical"
              variant="scrollable"
              onChange={handleChange}
              aria-label="Vertical tabs"
              sx={{ borderRight: 1, borderColor: "divider", height: "100%" }}
            >
              <Tab
                sx={{ fontSize: 17, fontWeight: 700, color: "#040404", m: 2 }}
                label="Category"
                value="1"
              />
              <Tab
                sx={{ fontSize: 17, fontWeight: 700, color: "#040404", m: 2 }}
                label="Suggestion"
                value="2"
              />
              <Tab
                sx={{ fontSize: 17, fontWeight: 700, color: "#040404", m: 2 }}
                label="Duration"
                value="3"
              />
            </TabList>
          </Grid>
          <Grid item xs={9}>
            <TabPanel value="1">
              <CategoriesCreation />
            </TabPanel>
            <TabPanel value="2">
              <CreateSuggestion />
            </TabPanel>
            <TabPanel value="3">
              <CreateDuration />
            </TabPanel>
          </Grid>
        </Grid>
      </TabContext>
    </Box>
  );
}
