import React, { useEffect, useState } from 'react';
import {
    Box, Button, Card, Grid, TextField, Typography, Container,
    Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, IconButton,
    Autocomplete
} from "@mui/material";
import Heading from '../Heading';
import Breadcrumbs from '../Breadcrumbs';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { siteprojectTable } from '../../Variables/Variables';
import Filter from '../FilterData/Filter';
import { FilterAssignedSite, FilterData, FilterSite } from '../FilterData/FilterData';
import { getAllSiteProject, getDashboardExpense } from '../../API_Service/API_Links';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import axios from 'axios';
import '../../App.css'

export default function SitePage() {
    const [value, setValue] = useState(dayjs('2022-04-07'));
    const { formState: { errors }, handleSubmit } = useForm();
    const [EmployeeName, setEmployeeName] = useState("");
    const [EmployeeId, setEmployeeId] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openTable, setOpenTable] = useState(false);
    const [openViewTab, setOpenViewTab] = useState(false);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const options = ['Approved', 'Pending', 'Rejected'];
    const [search, setSearch] = useState("");
    const [allProjects, setAllProjects] = useState([]);
    const [allSite, setAllSite] = useState([]);
    const [ProjectList, setProjectList] = useState([]);
    const [projectCode, setProjectCode] = useState([]);

    const [projectIdSearch, setprojectIdSearch] = useState('');
    const [teamSearch, setTeamSearch] = useState('');

    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleSearchClick = () => {
        setPage(0);
        fetchSiteDetails()
    }

    const fetchSiteDetails = async () => {
        const sendData = new FormData();
        sendData.append("projectCode", projectCode ? projectCode : "");
        try {
            const res = await axios.post(getDashboardExpense, sendData);
            setAllSite(res.data.data);
            console.log(res.data.data, "dashboard");
        } catch (error) {
            console.log(error);
            setAllSite([]);
        }
    };

    // GET ALL PROJECTS
    useEffect(() => {
        fetchSiteDetails();
    }, []);

    useEffect(() => {
        const fetchProjectCode = async () => {
            try {
                const res = await axios.get(getAllSiteProject)
                setProjectList(res.data.data)
            } catch (error) {
                console.log(error);
                setProjectList([])
            }
        }
        fetchProjectCode()
    }, [])

    // Slice data for pagination
    const paginatedData = allSite.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box boxShadow={4} bgcolor='#EDF4F4'>
            <Container>
                <Box mt={2} py={4} id='assignedSiteFirstTab'>
                    <Grid container spacing={1}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={3} md={3} sm={12} >
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={ProjectList.map((i) => i.projectCode)}
                                    onChange={(event, value) => setProjectCode(value)}
                                    size='small'
                                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Project Code' color='secondary' {...params} />}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} lg={3} md={3} sm={12} >
                                <Button className='searchButton' sx={{ mt: 1 }} onClick={handleSearchClick}>Search</Button>
                            </Grid>
                        </Grid>

                        <TableContainer sx={{ border: '1px solid silver' }}>
                            <Table>
                                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
                                    <TableRow sx={{ borderBottom: '1px solid silver' }}>
                                        <TableCell sx={{ color: 'white', fontWeight: 600, textAlign: 'center' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    Project Code
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                            Tender Amount
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    Expense Amount
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedData.map((i, index) => (
                                        <TableRow key={index} sx={{ borderBottom: '1px solid silver' }}>
                                            <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.projectCode}</TableCell>
                                            <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.tenderAmount}</TableCell>
                                            <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.expenseAmount}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={allSite.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}
