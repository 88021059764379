import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Box, Container, TablePagination, Dialog } from "@mui/material";
import { expenseDocs, get_all_maintenance_expense, get_all_others_expense, get_all_rental_expense } from "../../../API_Service/API_Links";
import axios from "axios";
import { FilterData, FilterSumCat, FilterTwoData } from "../../FilterData/FilterData";
import Filter from "../../FilterData/Filter";
import SnackBar from "../../SnackBar";
import { SummaryExport } from "./SummaryExport";

export default function OtherTable() {

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");


  const [employeeIdSearch, setEmployeeIdSearch] = useState('');
  const [projectCodeSearch, setProjectCodeSearch] = useState('');
  const [spendDateSearch, setspendDateSearch] = useState('');

  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage , setSelectedImage] = useState('')
  
  const handleImageDialog = (data) =>{
    setOpenImageDialog(!openImageDialog);
    setSelectedImage(data)
  }

  useEffect(() => {
    axios({
      method: 'GET',
      url: get_all_others_expense,
    }).then(res => {
      if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
      } else {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data);
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }, []);

  const keys = Object.keys(data[0] || {}).filter((key) => key !== "othersId");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box boxShadow={4} bgcolor='#EDF4F4'>
         <Dialog open={openImageDialog} onClose={() => setOpenImageDialog(false)}>
        <img src={`${expenseDocs}${selectedImage}`} alt='Fuel' />
        </Dialog>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />
      <Container>
        <Box pt={2} pb={4}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            my={2}
          ><SummaryExport data={data} fileName='Other Expense' /> </Grid>
          <Grid container spacing={1}>
            <TableContainer sx={{ border: '1px solid silver' }} >
              <Table>
                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
                  <TableRow>
                    {keys.map((key) => (
                      <TableCell sx={{ color: 'white', fontWeight: 500, textAlign: 'center' }} key={key}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                            {key}
                          </Box>
                          {key === "employeeId" && (
                            <Box>
                              <Filter search={employeeIdSearch} setSearch={setEmployeeIdSearch} />
                            </Box>
                          )
                          }
                          {key === "projectCode" && (
                            <Box>
                              <Filter search={projectCodeSearch} setSearch={setProjectCodeSearch} />
                            </Box>
                          )
                          }
                          {key === "spendDate" && (
                            <Box>
                              <Filter search={spendDateSearch} setSearch={setspendDateSearch} />
                            </Box>
                          )
                          }
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {FilterSumCat(data, employeeIdSearch, projectCodeSearch, spendDateSearch)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={index}>
                        {keys.map((key) => (
                          <TableCell align="center" key={key}>
                            {key === "othersImages" ? (
                             row[key] ? (
                              <img src={`${expenseDocs}${row[key]}`} onClick={()=>handleImageDialog(row[key])} style={{ width: 100, height: 100 }} alt={row[key]} />
                            ): (
                                "-"
                              )
                            ) : key === "othersAudio" ? (
                              row[key] ? (
                                <audio controls>
                                  <source src={`${expenseDocs}${row[key]}`} type="audio/mpeg" />
                                  Your browser does not support the audio element.
                                </audio>
                              ) : (
                                "-"
                              )
                            ) : (
                              row[key]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={FilterSumCat(data, employeeIdSearch, projectCodeSearch, spendDateSearch).length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>


      </Container>
    </Box>
  )
}
