import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Grid, TextField, Typography, Container, Autocomplete, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { add_factory_project, get_factory_employees, get_factory_project_items, get_size, methodGet, methodPost } from "../../API_Service/API_Links";
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { appendData } from '../../Variables/Variables';
import SnackBar from '../SnackBar';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ChipInputTable1 from '../ChipInputTable/ChipInputTable1';
import ChipInputTable2 from '../ChipInputTable/ChipInputTable2';
import TeamChipInput from '../ChipInputTable/TeamChipInput';
import ProjectManagerChip from '../ChipInputTable/ProjectManagerChip';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function FactoryProjectAssignTab({ setValue, isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen, setNotification }) {


  const { formState: { errors }, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [projectdocument, setProjectDocument] = useState(null);
  const [CustomerName, setCustomerName] = useState("");
  const [customerLocation, setCustomerLocation] = useState("");
  const [commercialUpload, setCommercialUpload] = useState("");
  const [TechnicalUpload, setTechnicalUpload] = useState("");
  const [MobileNum, setMobileNum] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");
  const [Email, setEmail] = useState("");
  const [materialFromClient, setMaterialFromClient] = useState("");
  const [process, setProcess] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [websiteDetails, setWebsiteDetails] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyAddress, setCompanyAddress] = useState("");
  const [GstNum, setGstNum] = useState("");
  const [Services, setServices] = useState("");
  const [ProjectName, setProjectName] = useState("");
  const [ProjectDescp, setProjectDescp] = useState("");
  const [ExpectDate, setExpectDate] = useState("");
  const [ProjectBudget, setProjectBudget] = useState("");
  const [ProjectStart, setProjectStart] = useState("");
  const [ProjectEnd, setProjectEnd] = useState("");
  const [projectType, setProjectType] = useState([]);
  const [projectCode, setProjectCode] = useState("");
  const [deadLine, setDeadLine] = useState('');
  const [input, setInput] = useState(false);
  // const [teamMember, setTeamMember] = useState('');
  // const [staffMember, setStaffMember] = useState('');
  const navigate = useNavigate();
  const [managerId, setManagerId] = useState(null);
  const [managername, setManagername] = useState(null);
  const [options, setOptions] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [itemCodeOptions, setItemCodeOptions] = useState([]);
  const [orderConfirmedDate, setOrderConfirmedDate] = useState('');
  const [customerRequiredDate, setCustomerRequiredDate] = useState('');
  const [dispatchCommittedDate, setDispatchCommittedDate] = useState('');
  const [demand, setDemand] = useState('');
  const [rawMaterialGrade, setRawMaterialGrade] = useState('');
  const [orderValue, setOrderValue] = useState(0);
  const [shipmentAddress, setShipmentAddress] = useState('');
  const [transportationScope, setTransportationScope] = useState('');
  const [itemsList, setItemsList] = useState([]);

  const [itemCode, setItemCode] = useState(null);
  const [itemDescription, setItemDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const [thread, setThread] = useState('');
  const [itemName, setItemName] = useState('');
  const [equipmentDetail, setEquipmentDetail] = useState('');
  const [itemValue, setItemValue] = useState('');
  const [size, setSize] = useState(null);

  const RoleId = '7';
  const [fileTypeError, setFileTypeError] = useState('');

  const allowedExtensions = ['pdf', 'ppt', 'png', 'jpeg', 'jpg', 'gif', 'txt', 'xls'];


  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setProjectDocument(selectedFile);
        setFileTypeError('');
      } else {
        setProjectDocument(null);
        setFileTypeError('Only F, PPT, PNG, JPEG, JPG, GIF, TEXT, and XLS files are allowed.');
      }
    }
  };



  const serverData = {
    CustomerName: CustomerName,
    MobileNum: MobileNum,
    Email: Email,
    CompanyName: CompanyName,
    CompanyAddress: CompanyAddress,
    GstNum: GstNum,
    Services: Services,
    PickupAddress: pickupAddress,
    CommercialUpload: commercialUpload,
    TechnicalUpload: TechnicalUpload,
    MaterialFromClient: materialFromClient,
    process: process,
    ProjectName: ProjectName,
    ProjectType: projectType,
    CustomerLocation: customerLocation,
    SecondaryContact: secondaryContact,
    PickupAddress: pickupAddress,
    WebsiteDetails: websiteDetails,
    DeliveryAddress: deliveryAddress,
    ProjectDescp: ProjectDescp,
    ProjectBudget: ProjectBudget,
    ProjectDoc: projectdocument,
    EmployeeId: managerId,
    DepartmentId: RoleId,
    StartDate: ProjectStart,
    EndDate: ProjectEnd,
    OrderConfirmedDate: orderConfirmedDate,
    CustomerRequiredDate: customerRequiredDate,
    DispatchCommittedDate: dispatchCommittedDate,
    OrderValue: orderValue,
    ShipmentAddress: shipmentAddress,
    TranspotationScope: transportationScope,
    itemsList: JSON.stringify(itemsList),
  }
  const sendData = appendData(serverData);
  const onSubmit = () => {
    if (!navigator.onLine) {
      setMessage('Your internet is in Offline')
      setOpen(true)
      setStatus(false)
      setColor(false)
    }
    if (CustomerName !== '' && ProjectName !== '' && managername !== '') {
      axios({
        method: methodPost,
        url: add_factory_project,
        data: sendData,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          } else {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            setCompanyAddress("");
            setMaterialFromClient('');
            setCompanyName("");
            setPickupAddress("");
            setCustomerName("");
            setCommercialUpload('');
            setTechnicalUpload('');
            setWebsiteDetails('');
            setSecondaryContact('');
            setDeadLine("");
            setEmail("");
            setExpectDate("");
            setGstNum("");
            setMessage("");
            setMobileNum("");
            setProjectCode("");
            setProjectBudget("");
            setCustomerLocation('');
            setProjectDescp("");
            setDeadLine("");
            setProjectStart("");
            setProjectEnd("");
            setServices("");
            setInput(true);
            setProjectName("");
            document.getElementById("projectdocument").value = "";
            setManagerId('');
            setManagername('');
            setProjectType([]);
            setIsAddedCartSnackbarOpen(true);
            setNotification(res.data.message);
            setValue('1');
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
    else {
      setMessage('Please Fill All the Details')
    }
  }

  const cancelClick = () => {
    setValue('1');
  }


  useEffect(() => {
    if (RoleId !== null) {
      const sendData = new FormData();
      sendData.append("DepartmentId", RoleId);

      axios({
        method: methodPost,
        url: get_factory_employees,
        data: sendData,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
            setOptions([]);
          } else {
            setMessage(res.data.message);
            setOptions(res.data.data);
            setStatus(true);
            setColor(true);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
          console.log("chip2", err);
        });
    }
    else {
      setOptions([]);
    }
  }, [RoleId]);



  useEffect(() => {
    const sendData = new FormData();
    sendData.append("Id", "");
    sendData.append("ItemCode", "");
    sendData.append("ItemDescription", "");
    sendData.append("ItemName", "");
    axios({
      method: methodPost,
      url: get_factory_project_items,
      data: sendData,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
          setItemCodeOptions([]);
        } else {
          setMessage(res.data.message);
          setItemCodeOptions(res.data.data);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);


  useEffect(() => {
    axios({
      method: methodGet,
      url: get_size,
      data: sendData,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
          setSizeOptions([]);
        } else {
          setMessage(res.data.message);
          setSizeOptions(res.data.data);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  const saveItemList = () => {
    setOrderValue(orderValue + itemValue);
    const newItemList = {
      "ItemCode": itemCode,
      "ItemDescription": itemDescription,
      "Quantity": quantity,
      "Size": size?.Size,
      "ItemName": itemName,
      "ItemValue": itemValue,
      "RawMaterialGrade": rawMaterialGrade
    }
    setItemsList([...itemsList, newItemList]);
    setItemCode(null);
    setItemDescription('');
    setQuantity('');
    setSize(null);
    setItemName('');
    setItemValue('');
    setRawMaterialGrade('');
    setInput(!input);
  }



  return (
    <Box>
      <Box display="flex" alignItems="center">
        <SnackBar
          open={open}
          message={message}
          setOpen={setOpen}
          status={status}
          color={color}
        />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              px: 5,
              backgroundColor: "#F5F7F7",
              borderRadius: "10px",
              mx: 2,
              my: 4,
              boxShadow: 11,
            }}
          >

            <Grid
              container
              justifyContent="center"
              sx={{ textAlign: "center" }}
              spacing={3}
            >
              <Grid item lg={12} xl={12}>
                <Box
                  sx={{
                    border: "1px solid black",
                    p: 4,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 5,
                  }}
                >
                  <Box sx={{ pb: 3, textAlign: "left" }}>
                    <h5>CUSTOMER DETAILS</h5>
                  </Box>
                  <Grid container justifyContent="space-evenly" spacing={2}>
                  <Grid item lg={4} sm={12} xl={4} xs={12} md={6} sx={{ py: 2 }} >
                      <TextField
                        fullWidth
                        id="CustomerName"
                        label="Customer Name"
                        variant="outlined"
                        required
                        value={CustomerName}
                        size="small"
                        color="secondary"
                        error={errors.CustomerName ? true : false}
                        helperText={
                          errors.CustomerName &&
                            errors.CustomerName.type === "required"
                            ? "Customer Name is required"
                            : ""
                        }
                        onChange={(e) => setCustomerName(e.target.value)}
                        {...("CustomerName", { required: true })}
                      />
                    </Grid>

                    <Grid item lg={4} sm={12} xl={4} xs={12} md={6} sx={{ py: 2 }} >
                      <TextField
                        fullWidth
                        id="MobileNum"
                        label="Mobile number"
                        required
                        value={MobileNum}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        error={errors.MobileNum ? true : false}
                        helperText={
                          errors.MobileNum && errors.MobileNum.type === "required"
                            ? "Mobile Number is required"
                            : ""
                        }
                        inputProps={{
                          maxLength: 10,
                          type: 'text',
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setMobileNum(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={4} sm={12} xl={4} xs={12} md={6} sx={{ py: 2 }} >
                      <TextField
                        fullWidth
                        id="MobileNum"
                        label="Secondary Contact"
                        required
                        value={secondaryContact}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        // error={errors.secondaryContact ? true : false}
                        // helperText={
                        //   errors.secondaryContact && errors.secondaryContact.type === "required"
                        //     ? "Mobile Number is required"
                        //     : ""
                        // }
                        inputProps={{
                          maxLength: 10,
                          type: 'text',
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setSecondaryContact(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={4} sm={12} xl={4} xs={12} md={6} sx={{ py: 2 }} >
                      <TextField
                        fullWidth
                        id="Email"
                        label="Email id"
                        variant="outlined"
                        size="small"
                        type='email'
                        value={Email}
                        color="secondary"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={4} sm={12} xl={4} xs={12} md={6} sx={{ py: 2 }} >
                      <TextField
                        fullWidth
                        id="customerLocation"
                        label="Customer Location"
                        variant="outlined"
                        size="small"
                        type='text'
                        value={customerLocation}
                        color="secondary"
                        onChange={(e) => setCustomerLocation(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={4} sm={12} xl={4} xs={12} md={6} sx={{ py: 2 }} >
                      <TextField
                        fullWidth
                        id="WebsiteDetails"
                        label="Website Details"
                        variant="outlined"
                        size="small"
                        type='text'
                        value={websiteDetails}
                        color="secondary"
                        onChange={(e) => setWebsiteDetails(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={4} sm={12} xl={4} xs={12} md={6} sx={{ py: 2 }} >
                      <TextField
                        fullWidth
                        id="DeliveryAddress"
                        label="Delivery Address"
                        variant="outlined"
                        size="small"
                        type='text'
                        value={deliveryAddress}
                        color="secondary"
                        onChange={(e) => setDeliveryAddress(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={4} sm={12} xl={4} xs={12} md={6} sx={{ py: 2 }} >
                      <TextField
                        fullWidth
                        id="CompanyName"
                        label="Company Name"
                        variant="outlined"
                        value={CompanyName}
                        size="small"
                        color="secondary"
                        error={errors.CompanyName ? true : false}
                        helperText={
                          errors.CompanyName &&
                            errors.CompanyName.type === "required"
                            ? "Company Name is required"
                            : ""
                        }
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={4} sm={12} xl={4} xs={12} md={6} sx={{ py: 2 }} >
                      <TextField
                        fullWidth
                        id="CompanyAddress"
                        label="Company address"
                        variant="outlined"
                        value={CompanyAddress}
                        size="small"
                        color="secondary"
                        error={errors.CompanyAddress ? true : false}
                        helperText={
                          errors.CompanyAddress &&
                            errors.CompanyAddress.type === "required"
                            ? "Company Address is required"
                            : ""
                        }
                        onChange={(e) => setCompanyAddress(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={4} sm={12} xl={4} xs={12} md={6} sx={{ py: 2 }} >
                      <TextField
                        fullWidth
                        id="GstNum"
                        label="Gst"
                        variant="outlined"
                        value={GstNum}
                        size="small"
                        color="secondary"
                        error={errors.GstNum ? true : false}
                        helperText={
                          errors.GstNum && errors.GstNum.type === "required"
                            ? " Gst Number is required"
                            : ""
                        }
                        onChange={(e) => setGstNum(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={4} sm={12} xl={4} xs={12} md={6} sx={{ py: 2 }} >
                      <TextField
                        fullWidth
                        id="Services"
                        label="Service Domain"
                        variant="outlined"
                        value={Services}
                        size="small"
                        color="secondary"
                        error={errors.Services ? true : false}
                        helperText={
                          errors.Services && errors.Services.type === "required"
                            ? " Services is required"
                            : ""
                        }
                        onChange={(e) => setServices(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={4} sm={12} xl={4} xs={12} md={6} sx={{ py: 2 }} >
                      <TextField
                        fullWidth
                        id="ProjectBudget"
                        label="Approx Budget"
                        variant="outlined"
                        value={ProjectBudget}
                        size="small"
                        color="secondary"
                        error={errors.ProjectBudget ? true : false}
                        helperText={
                          errors.ProjectBudget &&
                            errors.ProjectBudget.type === "required"
                            ? " Approx Budget is required"
                            : ""
                        }
                        inputProps={{
                          maxLength: 20,
                          type: 'text',
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setProjectBudget(e.target.value)}
                      />
                    </Grid>
                    </Grid>
                    <Box display='flex'>
                    <Grid item lg={4} sm={12} xl={12} xs={12} md={12} sx={{ py: 2, mt:1 }} justifyContent="start" display='flex' >
                      <FormControlLabel
                        control={<Switch
                          value={materialFromClient}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setMaterialFromClient('Yes')
                            }
                            else {
                              setMaterialFromClient('No')
                            }
                          }} />}
                        labelPlacement="end"
                        label="Material From Client"
                      />
                    </Grid>
                    {
                    materialFromClient === 'Yes' &&
                    <Grid item lg={4} sm={12} xl={4} xs={12} md={6} sx={{ py: 2 }}  display='flex' justifyContent="start">
                    <TextField
                      fullWidth
                      id="pickup"
                      label="Pickup Address"
                      type="text"
                      value={pickupAddress}
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onChange={(e) => setPickupAddress(e.target.value)}
                    />
                  </Grid>
}</Box>
                </Box>
              </Grid>


              {/* (project Enquiries) */}

              <Grid item lg={6} xl={6}>
                <Box
                  sx={{
                    border: "1px solid black",
                    px: 3,
                    pb: 1,
                    pt: 1,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 2,
                  }}
                >
                  <Box sx={{ textAlign: "left" }}>
                    <h5>PROJECT DETAILS</h5>
                  </Box>
                  <Grid container justifyContent="start" spacing={2}>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Project name"
                        variant="outlined"
                        size="small"
                        value={ProjectName}
                        color="secondary"
                        error={errors.ProjectName ? true : false}
                        helperText={
                          errors.ProjectName &&
                            errors.ProjectName.type === "required"
                            ? " Project Name is required"
                            : ""
                        }
                        onChange={(e) => setProjectName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={['Factory', 'Field']}
                        defaultValue=""
                        value={projectType}
                        onChange={(event, value) => setProjectType(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Project Type"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                            error={errors.ExpectDate ? true : false}
                            helperText={
                              errors.ExpectDate &&
                                errors.ExpectDate.type === "required"
                                ? "Expected date is required"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>



                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Documents"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={handleFileChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 3 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectDescp"
                        rows={3}
                        label="Project Description"
                        color="secondary"
                        value={ProjectDescp}
                        variant="outlined"
                        size="small"
                        multiline
                        error={errors.ProjectDescp ? true : false}
                        helperText={
                          errors.ProjectDescp &&
                            errors.ProjectDescp.type === "required"
                            ? "Project Description is required"
                            : ""
                        }
                        onChange={(e) => setProjectDescp(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {/* (Order) */}
              <Grid item lg={6} xl={6}>
                <Box
                  sx={{
                    border: "1px solid black",
                    px: 3,
                    pb: 3,
                    pt: 3,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 2,
                  }}
                >

                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                    md={12}
                    sx={{ py: 1 }}
                  >
                    <Box sx={{ textAlign: "left" }}>
                      <h5>ORDER</h5>
                    </Box>
                  </Grid>

                  <Grid container justifyContent="start" spacing={1} minHeight={210}>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="Confirm"
                        label="Order Confirmed Date"
                        type="date"
                        value={orderConfirmedDate}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setOrderConfirmedDate(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="CustomerRequire"
                        label="Customer Require Date"
                        type="date"
                        value={customerRequiredDate}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setCustomerRequiredDate(e.target.value)}
                        InputProps={{ inputProps: { min: orderConfirmedDate } }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="Dispatch"
                        label="Dispatch Committed Date"
                        type="date"
                        value={dispatchCommittedDate}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setDispatchCommittedDate(e.target.value)}
                        InputProps={{ inputProps: { min: orderConfirmedDate } }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="shipmentAddress"
                        label="Shipment address"
                        variant="outlined"
                        size="small"
                        value={shipmentAddress}
                        color="secondary"
                        onChange={(e) => setShipmentAddress(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 2 }}
                      justifyContent="start"
                      display='flex'
                    >
                      <FormControlLabel
                        control={<Switch
                          value={transportationScope}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setTransportationScope('Yes')
                            }
                            else {
                              setTransportationScope('No')
                            }
                          }} />}
                        labelPlacement="end"
                        label="Transportation Scope"
                      />
                    </Grid>


                  </Grid>
                </Box>
              </Grid>



            </Grid>
            <Grid item lg={12} xl={12} >
              <Box
                sx={{
                  border: "1px solid black",
                  px: 4,
                  pb: 2,
                  pt: 2,
                  borderColor: "#d2cbcb;",
                  borderRadius: "4px",
                  ":hover": { boxShadow: 2 },
                  mt: 2,
                }}
              >

                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  md={12}
                  sx={{ py: 1 }}
                >

                </Grid>

                <Grid container justifyContent="start" spacing={1}>



                  <Grid item lg={6} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }} >
                    <TextField
                      fullWidth
                      id="CommercialUpload"
                      label="Commercial Upload"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      type="file"
                      onChange={commercialUpload}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}
                  </Grid>
                  <Grid item lg={6} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }} >
                    <TextField
                      fullWidth
                      id="TechnicalUpload"
                      label="Technical Upload"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      type="file"
                      onChange={TechnicalUpload}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}
                  </Grid>

                  <Grid item lg={6} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }} >
                    <TextField
                      fullWidth
                      id="process"
                      label="Process"
                      type="text"
                      value={process}
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onChange={(e) => setProcess(e.target.value)}
                    />
                  </Grid>

                </Grid>
              </Box>
            </Grid>


            {/* {buttons}  */}

            <Grid
              container
              justifyContent="center"
              sx={{ textAlign: "center", mt: 3 }}
            >
              <Grid item lg={6} xl={6} xs={12}>
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
                    <Stack spacing={2} direction="row">
                      <Button
                        fullWidth
                        variant="outlined"
                        type="submit"
                        sx={{
                          color: "white",
                          backgroundColor: "#7bc54c",
                          borderColor: "#7bc54c",
                          ":hover": {
                            borderColor: "#7bc54c",
                            color: "#000000",
                          },
                        }}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </Grid>

                  <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
                    <Stack spacing={2} direction="row">
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={cancelClick}
                        type="cancel"
                        sx={{
                          color: "white",
                          backgroundColor: "#c62828",
                          borderColor: "#c62828",
                          ":hover": {
                            borderColor: "#c62828",
                            color: "#000000",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Box>
        </Form>
      </Box>
    </Box>
  );
}
