import React, { useState } from 'react';
import { TextField, Container, Box, Button, Grid, Card, Autocomplete } from '@mui/material';
import SnackBar from '../../SnackBar';
import { addform16, getAllEmployeeName, methodGet } from '../../../API_Service/API_Links';
import axios from 'axios';
import { useEffect } from 'react';
import { appendData } from '../../../Variables/Variables';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

export default function Form16Add() {
  const { handleSubmit, register, formState: { errors } } = useForm();
  const [ImagesProof, setImagesProof] = useState(null);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [input, setInput] = useState(false);
  const [EmpList, setEmpList] = useState([]);

  const [empname, setEmpname] = useState([]);
  const [EmpId, setEmpId] = useState(null);


  useEffect(() => {
    axios({
      method: methodGet,
      url: getAllEmployeeName,
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setEmpList(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

 
  const onSubmit = () => {
    const obj = {
    EmployeeId: EmpId?.EmployeeId,
    form16Upload:ImagesProof,
    }
    const ldata = appendData(obj);
    axios({
      method: 'POST',
      url: addform16,
      data: ldata,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setEmpId(null);
        setEmpname(null);
        document.getElementById('Imagesproof').value = '';
      } else {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
        setInput(false);
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  return (
    <Box>
      <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
      <Container>
        <Box component={Card} p={4}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  size='small'
                  id="combo-box-demo"
                  options={EmpList}
                  value={EmpId}
                  getOptionLabel={(option) => `${option.EmployeeName} (${option.EmployeeId})`}
                  onChange={(event, value) => setEmpId(value)}
                  sx={{ my: 1 }}
                  renderInput={(params) => <TextField color='secondary' label='Employee Name' {...params} required />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  name="Image"
                  label="Form Upload"
                  type='file'
                  id='Imagesproof'
                  onChange={(e) => setImagesProof(e.target.files[0])}
                  fullWidth
                  sx={{ my: 1 }}
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container display='flex' justifyContent='center' mt={1}>
              <Grid item xs={12} sm={6} md={6} lg={6} display='flex' justifyContent='center'>
                <Box display='flex' justifyContent='center' py={2}>
                  <Button variant='contained' color='secondary' type='submit' fullWidth>Submit</Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </Box>

      </Container>
    </Box>
  )
}