import React from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container} from "@mui/material";
import Heading from '../Heading';
import Breadcrumbs from '../Breadcrumbs';
import { financepageControllerView, financepageEmployeeView } from '../../Variables/Variables';
import { Outlet, useNavigate } from 'react-router-dom';
import IconBreadcrumbs from '../Breadcrumbs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import FuelTable from './AllExpenses/FuelTable';
import RentalTable from './AllExpenses/RentalTable';
import AccomodationTable from './AllExpenses/AccomodationTable';
import FoodTable from './AllExpenses/FoodTable';
import GeneralTable from './AllExpenses/GeneralTable';
import OtherTable from './AllExpenses/OtherTable';
import WageTable from './AllExpenses/WageTable';
import PurchasesTable from './AllExpenses/PurchasesTable';
import TransportTable from './AllExpenses/TransportTable';
import UtilizationTable from './AllExpenses/UtilizationTable';
import MaintenanceTable from './AllExpenses/MaintenanceTable';
import TravelTable from './AllExpenses/TravelTable';
import SummaryTable from './AllExpenses/SummaryTable';
import AccountExpense from './AllExpenses/AccountExpense';
import Transaction from './AllExpenses/Transaction';
import NewSummaryExpense from './AllExpenses/NewSummaryExpense';
import ApproveExpense from './AllExpenses/ApproveExpense';
import RejectedList from './AllExpenses/RejectedList';


export default function ExpensesTab() {

  const [value, setValue] = useState("1");

  // Tab functionality
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };


    const role = JSON.parse(localStorage.getItem('role'));


  return (
    <Box>

    <Box py={2} px={1}>
    <IconBreadcrumbs
    previous={'Home'}
    current={'Expense'}
    link1={`/home`}
    link2={'/home/finance/expense'}
    currentSection={'Summary'}
    />
    </Box>

    <Container>
      <Box py={3}>
      <Heading  title={'Summary'}/>
      </Box>
    </Container>



    <Box sx={{ p: 1}}>
    <TabContext value={value}>
      <Box>
          <TabList
           variant="scrollable"
         scrollButtons
         allowScrollButtonsMobile
          onChange={handleChange} aria-label="tabs">
         <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Account" value="1" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Transaction" value="2" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Approval" value="3" />
         <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Approved List" value="4" />
         <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Rejected List" value="5" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Fuel" value="6" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="Rental" value="7" />
           <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Travel" value="8" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="Transport" value="9" />
           <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Accomodation" value="10" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="Food" value="11" />
           <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Maintenance" value="12" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="General" value="13" />
           <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Wages" value="14" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="Purchases" value="15" />
           <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Utilization" value="16" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2 }} label="Other" value="17" />
          </TabList>
          </Box>
         <TabPanel value="1">
            <Box>
                 <AccountExpense setValue={setValue} />
            </Box>
        </TabPanel>
            <TabPanel value="2">
            <Box>
            <Transaction />
            </Box>
            </TabPanel>
            <TabPanel value="3">
            <Box>
            <ApproveExpense />
            </Box>
            </TabPanel>
           <TabPanel value="4">
            <Box>
             <NewSummaryExpense />
            </Box>
        </TabPanel>
        <TabPanel value="5">
            <Box>
             <RejectedList />
            </Box>
        </TabPanel>
          <TabPanel value="6">
            <Box>
                 <FuelTable />
            </Box>
        </TabPanel>
        <TabPanel value="7">
            <Box>
             <RentalTable />
            </Box>
        </TabPanel>
        <TabPanel value="8">
            <Box>
             <TravelTable />
            </Box>
        </TabPanel>
        <TabPanel value="9">
            <Box>
             <TransportTable />
            </Box>
        </TabPanel>
                <TabPanel value="10">
            <Box>
             <AccomodationTable />
            </Box>
        </TabPanel>
                <TabPanel value="11">
            <Box>
             <FoodTable />
            </Box>
        </TabPanel>
                <TabPanel value="12">
            <Box>
             <MaintenanceTable />
            </Box>
        </TabPanel>
                <TabPanel value="13">
            <Box>
             <GeneralTable />
            </Box>
        </TabPanel>
                <TabPanel value="14">
            <Box>
             <WageTable />
            </Box>
        </TabPanel>
                <TabPanel value="15">
            <Box>
             <PurchasesTable />
            </Box>
        </TabPanel>
                <TabPanel value="16">
            <Box>
             <UtilizationTable />
            </Box>
        </TabPanel>
          <TabPanel value="17">
            <Box>
             <OtherTable />
            </Box>
        </TabPanel>
        </TabContext>
      </Box>
  </Box>
  )
}
