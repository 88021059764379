import React, { useState } from "react";
import {
  TextField,
  Container,
  Box,
  Button,
  Grid,
  Card,
  Autocomplete,
} from "@mui/material";
import SnackBar from "../../SnackBar";
import {
  createDynamicDropdown,
  fetch_expense_category,
  fetch_sub1_category,
  methodGet,
  methodPost,
} from "../../../API_Service/API_Links";
import axios from "axios";
import { useEffect } from "react";
import { Form } from "react-bootstrap";

export default function CreateDuration() {
  const [Type, setType] = useState(null);
  const [category1, setCategory1] = useState(null);
  const [category2, setCategory2] = useState(null);
  const [category3, setCategory3] = useState(null);

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [input, setInput] = useState(false);
  const [expenseCategory, setExpenseCategory] = useState(null);
  const [expenseCategoryList, setExpenseCategoryList] = useState([]);
  const [expenseSubCategory, setExpenseSubCategory] = useState(null);
  const [expenseSubCategoryList, setExpenseSubCategoryList] = useState([]);
  const [duration, setDuration] = useState("");
  const [durationList, setDurationList] = useState([]);
  const [durationType, setDurationType] = useState(null);

  // List
  const [TypeList, setTypeList] = useState([]);
  const [Category1List, setCategory1List] = useState([]);
  const [Category2List, setCategory2List] = useState([]);
  const [Category3List, setCategory3List] = useState([]);

  // Next Field Show
  const [showCategory1, setShowCategory1] = useState(false);
  const [showCategory2, setShowCategory2] = useState(false);
  const [showCategory3, setShowCategory3] = useState(false);
  const [showCategory4, setShowCategory4] = useState(false);
  const [showCategory5, setShowCategory5] = useState(false);
  const [showRent, setShowRent] = useState(false);

  useEffect(() => {
    axios({
      method: methodGet,
      url: fetch_expense_category,
    })
      .then((res) => {
        if (res.data.error) {
          setExpenseCategoryList([]);
        } else {
          setExpenseCategoryList(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
        console.log("chip1", err);
      });
  }, []);

  const fetchSub1Category = (value) => {
    const sendData = new FormData();
    sendData.append("ExpenseCategoryId", value?.ExpenseCategoryId);
    axios({
      method: methodPost,
      url: fetch_sub1_category,
      data: sendData,
    })
      .then((res) => {
        if (res.data.error) {
          setExpenseSubCategoryList([]);
        } else {
          setExpenseSubCategoryList(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
        console.log("chip1", err);
      });
  };

  // const fetchsub2Category = (value) => {
  //   if (value?.isSubCategory === 1 || value?.isSubCategory !== null) {
  //     setCategory1(null);
  //     const sendData = new FormData();
  //     sendData.append("ExpenseCategoryId", expenseCategory?.ExpenseCategoryId);
  //     sendData.append("Sub1CategoryId", value.Sub1CategoryId);
  //     axios({
  //       method: methodPost,
  //       url: fetch_sub2_category,
  //       data: sendData,
  //     })
  //       .then((res) => {
  //         if (res.data.error) {
  //           setCategory1List([]);
  //         } else {
  //           setCategory1List(res.data.data);
  //           setShowCategory1(true);
  //         }
  //       })
  //       .catch((err) => {
  //         alert("Oops something went wrong " + err);
  //       });
  //   } else {
  //     setShowCategory1(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchsub2Category();
  // }, [Type]);

  // const fetchsub3Category = () => {
  //   if (category1?.isSubCategory === 1 || category1?.isSubCategory !== null) {
  //     setCategory2(null);
  //     const sendData = new FormData();
  //     sendData.append("Sub2CategoryId", category1?.Sub2CategoryId);
  //     axios({
  //       method: methodPost,
  //       url: fetch_sub3_category,
  //       data: sendData,
  //     })
  //       .then((res) => {
  //         if (res.data.error) {
  //           setCategory2List([]);
  //         } else {
  //           setCategory2List(res.data.data);
  //           setShowCategory2(true);
  //         }
  //       })
  //       .catch((err) => {
  //         alert("Oops something went wrong " + err);
  //       });
  //   } else {
  //     setShowCategory2(false);
  //   }
  // };
  // useEffect(() => {
  //   fetchsub3Category();
  // }, [category1]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sendData = new FormData();
    sendData.append("Category1", expenseCategory.ExpenseCategoryName ?? "");
    sendData.append("Category2", expenseSubCategory.Sub1CategoryName ?? "");
    sendData.append("Category3", "");
    sendData.append("Category4", "");
    sendData.append("Duration", duration);
    try {
      const res = await axios.post(createDynamicDropdown, sendData);
      if (!res.data.error) {
        setOpen(true);
        setStatus(true);
        setColor(true);
        setMessage(res.data.message);
        setExpenseCategory(null);
        setExpenseSubCategory(null);
        setDuration("");
        setInput(true);
      } else {
        setOpen(true);
        setStatus(false);
        setColor(false);
        setMessage(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchDurationList = async () => {
  //   const sendData = new FormData();
  //   sendData.append("Category1", "Fuel");
  //   sendData.append("Category2", Type?.Sub1CategoryName ?? "");
  //   sendData.append("Category3", category1?.Sub2CategoryName ?? "");
  //   try {
  //     const res = await axios.post(getDynamicDropdown, sendData);
  //     if (!res.data.error) {
  //       setDurationList(res.data.data);
  //       setOpen(true);
  //       setStatus(true);
  //       setColor(true);
  //       setMessage(res.data.message);
  //     } else {
  //       setOpen(true);
  //       setStatus(false);
  //       setColor(false);
  //       setMessage(res.data.message);
  //       setDurationList([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <Box>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />
      <Container>
        <Form onSubmit={handleSubmit}>
          <Box component={Card} p={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={expenseCategoryList}
                  value={expenseCategory}
                  getOptionLabel={(option) => option.ExpenseCategoryName}
                  onChange={(event, value) => {
                    fetchSub1Category(value);
                    setExpenseCategory(value);
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ my: 1 }}
                      fontSize="small"
                      label="Expence Category"
                      color="secondary"
                      {...params}
                    />
                  )}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={expenseSubCategoryList}
                  value={expenseSubCategory}
                  getOptionLabel={(option) => option.Sub1CategoryName}
                  onChange={(event, value) => setExpenseSubCategory(value)}
                  size="small"
                  key={input}
                  renderInput={(params) => (
                    <TextField
                      sx={{ my: 1 }}
                      fontSize="small"
                      label="Expence Sub Category"
                      color="secondary"
                      {...params}
                    />
                  )}
                  required
                />
              </Grid>

              {/*     <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={TypeList}
                  getOptionLabel={(option) => option.Sub1CategoryName}
                  onChange={(event, value) => setType(value)}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ my: 1 }}
                      fontSize="small"
                      label="Select Type"
                      color="secondary"
                      {...params}
                    />
                  )}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category1List}
                  value={category1}
                  getOptionLabel={(option) => option.Sub2CategoryName}
                  onChange={(event, value) => setCategory1(value)}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ my: 1 }}
                      fontSize="small"
                      label="Category 1"
                      color="secondary"
                      {...params}
                    />
                  )}
                />
              </Grid>  */}

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label="Duration Type"
                  value={duration}
                  type="text"
                  onChange={(e) => setDuration(e.target.value)}
                  fullWidth
                  required
                  size="small"
                  sx={{ my: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Button variant="contained" type="submit" sx={{ my: 1 }}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Container>
    </Box>
  );
}
