import React, { useState } from 'react';
import { TextField, Container, Box, Button, Grid, Card, Autocomplete } from '@mui/material';
import SnackBar from '../../SnackBar';
import { fetch_sub1_category, fetch_sub2_category, fetch_sub3_category, fetch_sub4_category, fetch_sub5_category, fetch_sub6_category, getAllEmployeeName, getAllSiteProject, getEmployeeProjectCode, getGroupsDropdown, getLedgerCategory1Dropdown, getLedgerCategory2Dropdown, getLedgerCategory3Dropdown, getLedgerCategory4Dropdown, getLedgerCategory5Dropdown, getLedgerNameDropdown, getSuggestionBox, insertFoodExpense, insertLedgerCreation, insertRepairsMaintenanceExpense, insertTransportExpense, methodGet, methodPost } from '../../../API_Service/API_Links';
import axios from 'axios';
import { useEffect } from 'react';
import { appendData } from '../../../Variables/Variables';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

export default function MaintenanceCreation() {
  const { handleSubmit, register, formState: { errors } } = useForm();
  // const EmployeeId = localStorage.getItem('EmployeeId');
  const [projectCode, setProjectCode] = useState('');
  const [Type, setType] = useState(null);
  const [advance, setAdvance] = useState('');
  const [balanceAmount, setBalanceAmount] = useState('');
  const [category1, setCategory1] = useState(null);
  const [category2, setCategory2] = useState(null);
  const [category3, setCategory3] = useState(null);
  const [category4, setCategory4] = useState(null);
  const [category5, setCategory5] = useState(null);

  const [dueDate, setDueDate] = useState(getTodayDate());
  const [amount, setAmount] = useState("");
  const [comments, setComments] = useState("");
  const [ImagesProof, setImagesProof] = useState(null);
  const [ExpenseDate, setExpenseDate] = useState(null);
  const options = ['GST', 'NON-GST'];
  const [gst, setGst] = useState('NON-GST');
  const [sgst, setSGST] = useState('');
  const [cgst, setCGST] = useState('');
  const [igst, setIGST] = useState('');
  const [ProjectList, setProjectList] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [input, setInput] = useState(false);
  const [EmpList, setEmpList] = useState([]);

  // List
  const [groupList, setGroupList] = useState([]);
  const [ledgerList, setLedgerList] = useState([]);
  const [TypeList, setTypeList] = useState([]);
  const [Category1List, setCategory1List] = useState([]);
  const [Category2List, setCategory2List] = useState([]);
  const [Category3List, setCategory3List] = useState([]);
  const [Category4List, setCategory4List] = useState([]);
  const [Category5List, setCategory5List] = useState([]);

  // Next Field Show
  const [showCategory1, setShowCategory1] = useState(false);
  const [showCategory2, setShowCategory2] = useState(false);
  const [showCategory3, setShowCategory3] = useState(false);
  const [showCategory4, setShowCategory4] = useState(false);
  const [showCategory5, setShowCategory5] = useState(false);
  const [showRent, setShowRent] = useState(false);



  const [LedgerName, setLedgerName] = useState(null);
  const [offficeCategory, setoffficeCategory] = useState(null);
  const [group, setGroup] = useState(null);
  const [ledgerCategory1, setLedgerCategory1] = useState(null);
  const [ledgerCategory2, setLedgerCategory2] = useState(null);
  const [ledgerCategory3, setLedgerCategory3] = useState(null);
  const [ledgerCategory4, setLedgerCategory4] = useState(null);
  const [ledgerCategory5, setLedgerCategory5] = useState(null);


  const [ManualOfficecategory1, setManualOfficeCategory1] = useState(null);
  const [ManualLedgerName, setManualLedgerName] = useState(null);
  const [Manualgroup, setManualGroup] = useState(null);
  const [Manualcategory1, setManualCategory1] = useState(null);
  const [Manualcategory2, setManualCategory2] = useState(null);
  const [Manualcategory3, setManualCategory3] = useState(null);
  const [Manualcategory4, setManualCategory4] = useState(null);
  const [Manualcategory5, setManualCategory5] = useState(null);
  const [empname, setEmpname] = useState([]);
  const [EmpId, setEmpId] = useState(null);

  const [showError, setShowError] = useState(false);
  const [showError1, setShowError1] = useState(false);
  const [showError2, setShowError2] = useState(false);
  const [showError3, setShowError3] = useState(false);
  const [showError4, setShowError4] = useState(false);
  const [showError5, setShowError5] = useState(false);

  const [validationError, setValidationError] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);
  const [commentList, setCommentList] = useState([]);

  useEffect(() => {
    const fetchSuggestion = async () => {
      const sendData = new FormData();
      sendData.append('Category', 'Repairs and Maintenance');
      try {
        const res = await axios.post(getSuggestionBox, sendData);
        setCommentList(res.data.data);
        console.log('sug', res.data.data);
      } catch (error) {
        console.log(error);
        setCommentList([]);
      }
    };
    fetchSuggestion();
  }, []);

// default today date selection
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 2); // Subtract 2 days
    const formattedDate = currentDate.toISOString().split('T')[0]; // Get date in "YYYY-MM-DD" format
    setExpenseDate(formattedDate);
  }, []);

  const handleDateChange = (e) => {
    setExpenseDate(e.target.value);
  };

  const handleEmpChange = (event, value) => {
    setEmpId(value);
    if (value) {
      fetchProjectCode(value.EmployeeId);
    } else {
      setProjectList([]);
    }
  };

  const fetchProjectCode = async (Emp) => {
    const sendData = new FormData()
    sendData.append("EmployeeId", Emp)
    try {
      const res = await axios.post(getEmployeeProjectCode, sendData)
      setProjectList(res.data.data)
    } catch (error) {
      setProjectList([])
    }
  }


  useEffect(() => {
    const sendData = new FormData();
    sendData.append('ExpenseCategoryId', 7)
    axios({
      method: methodPost,
      url: fetch_sub1_category,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setStatus(false);
          // setColor(false);
          setTypeList([])
        } else {
          // setMessage(res.data.message);
          setTypeList(res.data.data);
          // setStatus(true);
          // setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
        console.log("chip1", err);
      });
  }, [Type]);

  useEffect(() => {
    if (Type?.isSubCategory === 1 && Type?.isSubCategory !== null) {
      setCategory1(null);
      const sendData = new FormData();
      sendData.append('ExpenseCategoryId', 7)
      sendData.append('Sub1CategoryId', Type?.Sub1CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub2_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            // setShowCategory1(false);
            setCategory1List([])
          } else {
            // setMessage(res.data.message);
            setCategory1List(res.data.data);
            setShowCategory1(true)
            // setStatus(true);
            // setColor(true);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
    else {
      setShowCategory1(false);
    }
  }, [Type]);


  useEffect(() => {
    if (category1?.isSubCategory === 1 && category1?.isSubCategory !== null) {
      setCategory2(null);
      const sendData = new FormData();
      sendData.append('Sub2CategoryId', category1?.Sub2CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub3_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            // setShowCategory2(false)
            setCategory2List([])
          } else {
            // setMessage(res.data.message);
            setCategory2List(res.data.data);
            // setStatus(true);
            // setColor(true);
            setShowCategory2(true)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
    else {
      setShowCategory2(false)
    }
  }, [category1]);

  useEffect(() => {
    if (category2?.isSubCategory === 1 && category2?.isSubCategory !== null) {
      setCategory3(null)
      const sendData = new FormData();
      sendData.append('Sub3CategoryId', category2?.Sub3CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub4_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            setCategory3List([])
            // setShowCategory3(false)
          } else {
            // setMessage(res.data.message);
            setCategory3List(res.data.data);
            // setStatus(true);
            // setColor(true);
            setShowCategory3(true)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
          console.log("chip1", err);
        });
    }
    else {
      setShowCategory3(false)
    }
  }, [category2]);


  useEffect(() => {
    if (category3?.isSubCategory === 1 && category3?.isSubCategory !== null) {
      setCategory4(null)
      const sendData = new FormData();
      sendData.append('Sub4CategoryId', category3?.Sub4CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub5_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            setCategory4List([])
            // setShowCategory4(false)
          } else {
            // setMessage(res.data.message);
            setCategory4List(res.data.data);
            // setStatus(true);
            // setColor(true);
            setShowCategory4(true)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
          console.log("chip1", err);
        });
    }
    else {
      setShowCategory4(false)
    }
  }, [category3]);


  useEffect(() => {
    if (category4?.isSubCategory === 1 && category4?.isSubCategory !== null) {
      setCategory4(null)
      const sendData = new FormData();
      sendData.append('Sub5CategoryId', category4?.Sub5CategoryId)
      axios({
        method: methodPost,
        url: fetch_sub6_category,
        data: sendData
      })
        .then((res) => {
          if (res.data.error) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
            setCategory5List([])
            setShowCategory5(false)
          } else {
            // setMessage(res.data.message);
            setCategory5List(res.data.data);
            // setStatus(true);
            // setColor(true);
            setShowCategory5(true)
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
          console.log("chip1", err);
        });
    }
    else {
      setShowCategory5(false)
    }
  }, [category4]);


  useEffect(() => {
    axios({
      method: methodGet,
      url: getAllEmployeeName,
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setOpen(true);
          // setStatus(false);
          // setColor(false);
        } else {
          // setMessage(res.data.message);
          setEmpList(res.data.data);
          // setOpen(true);
          // setStatus(true);
          // setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  useEffect(() => {
    axios({
      method: methodGet,
      url: getLedgerNameDropdown,
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setStatus(false);
          // setColor(false);
        } else {
          // setMessage(res.data.message);
          setLedgerList(res.data.data);
          // setStatus(true);
          // setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  useEffect(() => {
    const sendData = new FormData();
    sendData.append('LedgerNameId', (ManualLedgerName === null && LedgerName !== undefined && LedgerName !== null) ? LedgerName?.LedgerNameId : "");
    axios({
      method: methodPost,
      url: getGroupsDropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setStatus(false);
          // setColor(false);
        } else {
          // setMessage(res.data.message);
          setGroupList(res.data.data);
          if (LedgerName !== null) {
            setGroup(res.data.data[0])
          }
          else {
            setGroup(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [LedgerName]);





  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerGroupsId', (Manualgroup === null && group !== undefined && group !== null) ? group?.LedgerGroupsId : "")
    axios({
      method: methodPost,
      url: getLedgerCategory1Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setStatus(false);
          // setColor(false);
        } else {
          // setMessage(res.data.message);
          setCategory1List(res.data.data);
          if (group !== null) {
            setLedgerCategory1(res.data.data[0])
          }
          else {
            setLedgerCategory1(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [group]);

  console.log("Ledger", LedgerName, "group", group)
  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId1', (Manualcategory1 === null && ledgerCategory1 !== undefined && ledgerCategory1 !== null) ? ledgerCategory1?.LedgerCategoryId1 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory2Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          if (ledgerCategory1 !== null) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
          }
        } else {
          // setMessage(res.data.message);
          setCategory2List(res.data.data);
          if (ledgerCategory1 !== null) {
            setLedgerCategory2(res.data.data[0])
          }
          else {
            setLedgerCategory2(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [ledgerCategory1]);

  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId2', (Manualcategory2 === null && ledgerCategory2 !== undefined && ledgerCategory2 !== null) ? ledgerCategory2?.LedgerCategoryId2 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory3Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          if (ledgerCategory2 !== null) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
          }
        } else {
          // setMessage(res.data.message);
          setCategory3List(res.data.data);
          if (ledgerCategory2 !== null) {
            setLedgerCategory3(res.data.data[0])
          }
          else {
            setLedgerCategory3(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [ledgerCategory2]);

  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId3', (Manualcategory3 === null && ledgerCategory3 !== undefined && ledgerCategory3 !== null) ? ledgerCategory3?.LedgerCategoryId3 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory4Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          if (ledgerCategory3 !== null) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
          }
        } else {
          // setMessage(res.data.message);
          setCategory4List(res.data.data);
          if (ledgerCategory3 !== null) {
            setLedgerCategory4(res.data.data[0])
          }
          else {
            setLedgerCategory4(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [ledgerCategory3]);

  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId4', (Manualcategory4 === null && ledgerCategory4 !== undefined && ledgerCategory4 !== null) ? ledgerCategory4?.LedgerCategoryId4 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory5Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          if (ledgerCategory4 !== null) {
            // setMessage(res.data.message);
            // setStatus(false);
            // setColor(false);
          }
        } else {
          // setMessage(res.data.message);
          setCategory5List(res.data.data);
          if (ledgerCategory4 !== null) {
            setLedgerCategory5(res.data.data[0])
          }
          else {
            setLedgerCategory5(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [ledgerCategory4]);

  useEffect(() => {
    const parseTotalAmount = parseFloat(amount) || 0;
    const parseAdvance = parseFloat(advance) || 0;
    const calculateTotalCharges = Math.floor(parseTotalAmount - parseAdvance);
    const remainingBalance = Math.max(calculateTotalCharges, 0);

    if (parseAdvance > parseTotalAmount) {
      setValidationError('Advance amount cannot be higher than the total amount');
      setIsFormValid(false);
    } else {
      setBalanceAmount(remainingBalance.toString());
      setValidationError('');
      setIsFormValid(true);
    }
  }, [amount, advance]);

  const onSubmit = () => {
    const obj = {
      EmployeeId: EmpId?.EmployeeId,
      projectCode: projectCode,
      category1:"Repairs",
      category2: Type?.Sub1CategoryName !== undefined ? Type?.Sub1CategoryName : "",
      category3: category1?.Sub2CategoryName !== undefined ? category1?.Sub2CategoryName : "",
      category4: category2?.Sub3CategoryName !== undefined ? category2?.Sub3CategoryName : "",
      category5: category3?.Sub4CategoryName !== undefined ? category3?.Sub4CategoryName : "",
      category6: category4?.Sub5CategoryName !== undefined ? category4?.Sub5CategoryName : "",
      nextDueDate: dueDate,
      amount: amount,
      currentlyPaidAmount: advance,
      comments: comments,
      balanceAmount: balanceAmount,
      purchaseId:"",
      Images: ImagesProof,
      expenseAmount: advance,
      expenseDate: ExpenseDate,
      addedFrom: "web",
      expenseStatus: "1",
      OverallExpenseStatus:"1",
      Types: gst,
      SGST: sgst,
      CGST: cgst,
      IGST: igst,
      LedgerNameId: LedgerName?.LedgerNameId || null,
      LedgerName: ManualLedgerName !== null ? ManualLedgerName : LedgerName?.LedgerName ? LedgerName?.LedgerName : null,
      LedgerGroups: Manualgroup != null ? Manualgroup : group?.LedgerGroups ? group?.LedgerGroups : null,
      LedgerCategory1: Manualcategory1 != null ? Manualcategory1 : ledgerCategory1?.LedgerCategory1 ? ledgerCategory1?.LedgerCategory1 : null,
      LedgerCategory2: Manualcategory2 != null ? Manualcategory2 : ledgerCategory2?.LedgerCategory2 ? ledgerCategory2?.LedgerCategory2 : null,
      LedgerCategory3: Manualcategory3 != null ? Manualcategory3 : ledgerCategory3?.LedgerCategory3 ? ledgerCategory3?.LedgerCategory3 : null,
      LedgerCategory4: Manualcategory4 !== null ? Manualcategory4 : ledgerCategory4?.LedgerCategory4 ? ledgerCategory4?.LedgerCategory4 : null,
      LedgerCategory5: Manualcategory5 !== null ? Manualcategory5 : ledgerCategory5?.LedgerCategory5 ? ledgerCategory5?.LedgerCategory5 : null,
    }
    if (isFormValid) {
      const ldata = appendData(obj);
      axios({
        method: 'POST',
        url: insertRepairsMaintenanceExpense,
        data: ldata,
      }).then(res => {
        if (!res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          setEmpId('');
          setProjectCode([]);
          setType(null);
          setCategory1(null);
          setCategory2(null);
          setCategory3(null);
          setCategory4(null);
          setCategory5(null);
          setDueDate(null)
          setAmount('');
          setAdvance('');
          setBalanceAmount('');
          setComments('');
          setManualCategory1(null)
          setManualCategory2(null)
          setManualCategory3(null)
          setManualCategory4(null)
          setManualCategory5(null)
          setManualLedgerName(null)
          setManualGroup(null)
          setGroup(null);
          setIGST('')
          setCGST('')
          setSGST('')
          setLedgerName(null);
          setProjectList([]);
          setInput(true);
          setTypeList([]);
          setCategory1List([]);
          setCategory2List([]);
          setCategory3List([]);
          setCategory4List([]);
          setCategory5List([]);
          setShowCategory1(false);
          setShowCategory2(false);
          setShowCategory3(false);
          setShowCategory4(false);
          setShowCategory5(false);
          setShowRent(false);
          document.getElementById('Imagesproof').value = '';
          setLedgerCategory1(null);
          setLedgerCategory2(null);
          setLedgerCategory3(null);
          setLedgerCategory4(null);
          setLedgerCategory5(null);
        } else {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
          setInput(false);
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    } else {
      alert('Form validation failed. Please correct the errors.');
    }
  }

  return (
    <Box>
      <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
      <Container>
        <Box component={Card} p={4}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  size='small'
                  id="combo-box-demo"
                  options={EmpList}
                  value={EmpId}
                  getOptionLabel={(option) => `${option.EmployeeName} (${option.EmployeeId})`}
                  onChange={handleEmpChange}
                  sx={{ my: 1 }}
                  renderInput={(params) => <TextField color='secondary' label='Employee Name' {...params} required />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={ProjectList.map((i) => i.projectCode)}
                  onChange={(event, value) => setProjectCode(value)}
                  size='small'
                  renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Project Code' color='secondary' {...params} required />}
                />
              </Grid>


              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={TypeList}
                  getOptionLabel={(option) => option.Sub1CategoryName}
                  onChange={(event, value) => setType(value)}
                  size='small'
                  renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Select Type' color='secondary' {...params} required />}
                />
              </Grid>

              {
                showCategory1 &&
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category1List}
                    value={category1}
                    getOptionLabel={(option) => option.Sub2CategoryName}
                    onChange={(event, value) => setCategory1(value)}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 1' color='secondary' {...params} />}
                  />
                </Grid>
              }

              {
                showCategory2 &&
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category2List}
                    value={category2}
                    getOptionLabel={(option) => option.Sub3CategoryName}
                    onChange={(event, value) => {
                      setCategory2(value);
                      setShowRent(value?.isSubCategory === 1 ? false : true);
                    }}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 2' color='secondary' {...params} />}
                  />
                </Grid>
              }

              {
                showCategory3 &&
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category3List}
                    value={category3}
                    getOptionLabel={(option) => option.Sub4CategoryName}
                    onChange={(event, value) => {
                      setCategory3(value)
                      setShowRent(value?.isSubCategory === 1 ? false : true);
                    }}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 3' color='secondary' {...params} />}
                  />
                </Grid>
              }
              {
                showCategory4 &&
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category4List}
                    value={category4}
                    getOptionLabel={(option) => option.Sub5CategoryName}
                    onChange={(event, value) => {
                      setCategory4(value)
                      setShowRent(value?.isSubCategory === 1 ? false : true);
                    }}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 4' color='secondary' {...params} />}
                  />
                </Grid>
              }

              {
                showCategory5 &&
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    key={input}
                    options={Category5List}
                    value={category5}
                    getOptionLabel={(option) => option.Sub6CategoryName}
                    onChange={(event, value) => {
                      setCategory5(value)
                      setShowRent(value?.isSubCategory === 1 ? false : true);
                    }}
                    size='small'
                    renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='Category 5' color='secondary' {...params} />}
                  />
                </Grid>
              }

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  label="Next Due Date"
                  value={dueDate}
                  type='date'
                  onChange={(e) => setDueDate(e.target.value)}
                  fullWidth
                  size='small'
                  sx={{ my: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  label="Total Amount"
                  value={amount}
                  type='text'
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[0-9]*$/;
                    if (regex.test(input)) { setAmount(e.target.value) }
                  }}
                  fullWidth
                  size='small'
                  sx={{ my: 1 }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  label="Currently Paided Amount"
                  value={advance}
                  type='text'
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[0-9]*$/;
                    if (regex.test(input)) {
                      setAdvance(e.target.value)
                    }
                  }}
                  fullWidth
                  size='small'
                  sx={{ my: 1 }}
                />
                {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  label="Balance Amount"
                  value={balanceAmount}
                  type='text'
                  fullWidth
                  size='small'
                  sx={{ my: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
              <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={comments}
                  options={commentList.map(i => i.suggestion)}
                  onChange={(event, value) => setComments(value)}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ my: 1 }}
                      fontSize="small"
                      label="Comments"
                      color="secondary"
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  name="Image"
                  label="Expense Image"
                  type='file'
                  id='Imagesproof'
                  onChange={(e) => setImagesProof(e.target.files[0])}
                  fullWidth
                  sx={{ my: 1 }}
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>



              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  name="expenseDate"
                  label="Spend Date"
                  value={ExpenseDate}
                  type="date"
                  sx={{ my: 1 }}
                  onChange={handleDateChange}
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={options}
                  value={gst}
                  onChange={(event, value) => setGst(value)}
                  size='small'
                  autoComplete={false} // Disable browser autocomplete
                  getOptionLabel={(option) => option} // For strings as options
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ my: 1 }}
                      fontSize='small'
                      label='GST/NON-GST'
                      color='secondary'
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true, // Make the input field read-only
                      }}
                    />
                  )}
                  required
                />
              </Grid>
              {
                (gst === 'GST' && gst !== null && gst !== undefined) && <>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextField
                      name="name"
                      label="SGST (%)"
                      fullWidth
                      sx={{ my: 1 }}
                      size='small'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // Regular expression for allowing decimal numbers
                        if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                          setSGST(newValue);
                        } else {
                          setSGST('');
                        }
                      }}
                      value={sgst}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextField
                      name="name"
                      label="CGST (%)"
                      fullWidth
                      size='small'
                      sx={{ my: 1 }}
                      value={cgst}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // Regular expression for allowing decimal numbers
                        if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                          setCGST(newValue);
                        } else {
                          setCGST('');
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextField
                      name="name"
                      label="IGST (%)"
                      fullWidth
                      size='small'
                      sx={{ my: 1 }}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // Regular expression for allowing decimal numbers
                        if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                          setIGST(newValue);
                        } else {
                          setIGST('');
                        }
                      }}
                      value={igst}
                    />
                  </Grid>
                </>
              }

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={ledgerList}
                  value={LedgerName}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerName}
                  onChange={(event, value) => {
                    setLedgerName(value ?? value);
                    setShowError(false);
                    setShowError1(false);
                    setShowError2(false);
                    setShowError3(false);
                    setShowError4(false);
                    setShowError5(false);
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualLedgerName(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Ledger' color='secondary' {...params} required />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={groupList}
                  value={group}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerGroups}
                  onChange={(event, value) => {
                    if (LedgerName === null) {
                      // Show an error message or handle the error condition here
                      setShowError(true);
                      // You can set an error state or show a message to the user
                    } else {
                      // If the value is not null, set the LedgerName
                      setGroup(value ?? value);

                    }
                  }}
                  size='small'
                  renderInput={(params) => (
                    <TextField
                      onChange={(e) => setManualGroup(e.target.value)}
                      sx={{ my: 1 }}
                      fontSize='small'
                      label='Select Group'
                      color='secondary'
                      {...params}
                    />
                  )}
                />
                {showError && (
                  <span style={{ color: 'red', alignSelf: 'center' }}>After Ledger You Can Select this One</span>
                )}
              </Grid>


              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category1List}
                  value={ledgerCategory1}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory1}
                  onChange={(event, value) => {
                    if (LedgerName === null) {
                      setShowError1(true);
                    } else {
                      setLedgerCategory1(value ?? value);
                    }

                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory1(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 1' color='secondary' {...params} />}
                />
                {showError1 && (
                  <span style={{ color: 'red', alignSelf: 'center' }}>After Ledger You Can Select this One</span>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category2List}
                  value={ledgerCategory2}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory2}
                  onChange={(event, value) => {
                    if (LedgerName === null) {
                      setShowError2(true);
                    } else {
                      setLedgerCategory2(value ?? value);
                    }
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory2(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 2' color='secondary' {...params} />}
                />
                {showError2 && (
                  <span style={{ color: 'red', alignSelf: 'center' }}>After Ledger You Can Select this One</span>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category3List}
                  value={ledgerCategory3}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory3}
                  onChange={(event, value) => {
                    if (LedgerName === null) {
                      setShowError3(true);
                    } else {
                      setLedgerCategory3(value ?? value);
                    }
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory3(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 3' color='secondary' {...params} />}
                />
                {showError3 && (
                  <span style={{ color: 'red', alignSelf: 'center' }}>After Ledger You Can Select this One</span>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category4List}
                  value={ledgerCategory4}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory4}
                  onChange={(event, value) => {
                    if (LedgerName === null) {
                      setShowError4(true);
                    } else {
                      setLedgerCategory4(value ?? value);
                    }
                    setLedgerCategory4(value ?? value);
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory4(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 4' color='secondary' {...params} />}
                />
                {showError4 && (
                  <span style={{ color: 'red', alignSelf: 'center' }}>After Ledger You Can Select this One</span>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category5List}
                  value={ledgerCategory5}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory5}
                  onChange={(event, value) => {
                    if (LedgerName === null) {
                      setShowError5(true);
                    } else {
                      setLedgerCategory5(value ?? value);
                    }
                    setLedgerCategory5(value ?? value);
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory5(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 5' color='secondary' {...params} />}
                />
                {showError5 && (
                  <span style={{ color: 'red', alignSelf: 'center' }}>After Ledger You Can Select this One</span>
                )}
              </Grid>

            </Grid>

            <Grid container display='flex' justifyContent='center' mt={1}>
              <Grid item xs={12} sm={6} md={6} lg={6} display='flex' justifyContent='center'>
                <Box display='flex' justifyContent='center' py={2}>
                  <Button variant='contained' color='secondary' type='submit' fullWidth>Submit Expense</Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </Box>

      </Container>
    </Box>
  )
}


