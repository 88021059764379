import React from 'react';
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

// THis is to export by category , each category in diff sheet


export const SummaryExport = ({ data, fileName }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = () => {
        const exportWithoutColumns = data.map(item => {
            const { starting_image,ending_image,fuelImages, fuelAudio,rentalImages, rentalAudio,travelImages, travelAudio, transportImages,transportAudio, accommodationImages, accommodationAudio, foodImages, foodAudio, maintenanceImages, maintenanceAudio, generalImages, generalAudio, wagesImages, wagesAudio, purchaseImages, purchaseAudio, utilizationImages, utilizationAudio,othersImages, othersAudio, ...rest } = item;
            return rest;
        });

        // Create a workbook
        const wb = XLSX.utils.book_new();

        // Create a single sheet for all the data
        const ws = XLSX.utils.json_to_sheet(exportWithoutColumns);

        // Add the sheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, fileName);

        // Generate the Excel file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const excelData = new Blob([excelBuffer], { type: fileType });

        // Save the file using FileSaver.js
        FileSaver.saveAs(excelData, fileName + fileExtension);
    };


    return (
        <Button
            variant="contained"
            onClick={exportToCSV}
            startIcon={<ExitToAppIcon />}
            sx={{ my: 1 }}
        >
            Export
        </Button>
    );
};
