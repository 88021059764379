import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper , Grid, Box, Container, TablePagination, Button, Dialog } from "@mui/material";
import { expenseDocs, getAllExpenseStatus, get_all_food_expense, get_all_rental_expense,
  get_all_transport_expense, get_all_travel_expense, methodPost, statusUpdateExpense } from "../../../API_Service/API_Links";
import axios from "axios";
import { FilterData, FilterSumCat, FilterTwoData } from "../../FilterData/FilterData";
import Filter from "../../FilterData/Filter";
import SnackBar from "../../SnackBar";
import { SummaryExport } from "./SummaryExport";
import ApproveDialog from "./ApproveDialog";

export default function ApproveExpense() {

    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
   const [search, setSearch] = useState("");
   const [openApproveDialog, setopenApproveDialog] = useState(false);
  const [employeeIdSearch, setEmployeeIdSearch] = useState('');
  const [projectNameSearch, setprojectNameSearch] = useState('');
  const [mainCategorySearch, setmainCategorySearch] = useState('');
  const [dialogData ,setDialogData]= useState();

  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage , setSelectedImage] = useState('')

  const handleImageDialog = (data) =>{
    setOpenImageDialog(!openImageDialog);
    setSelectedImage(data)
  }

    const expenseStatus = () =>{
     axios({
          method:'GET',
          url: getAllExpenseStatus,
      }).then(res => {
          if (res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(false)
              setColor(false)
          } else {
              setData(res.data.data);
          }
      }).catch(err => {
          alert('Oops something went wrong ' + err)
      });
    }
    useEffect(()=>{
      expenseStatus();
  },[]);

  const keys = Object.keys(data[0] || {}).filter((key) => key !== "id");

    const handleChangePage = (event, newPage) => {
    setPage(newPage);
};
const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};



 const UpdateStatus = (row) =>{
  setDialogData(row);
  setopenApproveDialog(true);
 }

 const Reject  = (row) =>{
  const sendData = new FormData()
  sendData.append('Id', row.id)
  sendData.append('ExpenseStatus', 2)
  sendData.append('mainCategoryId', row.mainCategoryId)
  sendData.append('EmployeeId', row.employeeId)
  sendData.append('projectCode', row.projectName)
  sendData.append('Category1', row.mainCategory)
  // sendData.append('mainCategoryId', row.mainCategoryId)
  axios({
      method: methodPost,
      url: statusUpdateExpense,
      data:sendData
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setOpen(true);
          expenseStatus()
        } else {
          setMessage(res.data.message);
          setOpen(true)
          setStatus(false)
          setColor(false)
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
 }



  return (
      <Box boxShadow={4} bgcolor='#EDF4F4'>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />

<Dialog open={openImageDialog} onClose={() => setOpenImageDialog(false)}>
        <img src={`${expenseDocs}${selectedImage}`} alt='Fuel' />
        </Dialog>


      <ApproveDialog  setMessage={setMessage} setStatus={setStatus} setOpen={setOpen} setColor={setColor}  expenseStatus={ expenseStatus} openApproveDialog={openApproveDialog} setopenApproveDialog={setopenApproveDialog} data={dialogData} />

   <Container>
    <Box pt={2} pb={4}>
    <Grid
  container
  direction="row"
  justifyContent="flex-end"
  alignItems="center"
  my={2}
>

<SummaryExport data={data} fileName='Pending For Approval' /> </Grid>

    <Grid container spacing={1}>
      <TableContainer sx={{border:'1px solid silver' , width:'100%'}} >
         <Table>
         <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
          <TableRow>
            {keys.map((key) => (
             <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }} key={key}>
                <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                <Box sx={{ my: 'auto', textTransform: 'capitalize' }}>
                {key.split(/(?=[A-Z])/).join(' ')}
                </Box>
                  {key === "employeeId" && (
                    <Box>
                      <Filter search={employeeIdSearch} setSearch={setEmployeeIdSearch} />
                    </Box>
                  )
                  }
                  {key === "projectName" && (
                    <Box>
                      <Filter search={projectNameSearch} setSearch={setprojectNameSearch} />
                    </Box>
                  )
                  }
                  {key === "mainCategory" && (
              <Box>
              <Filter search={mainCategorySearch} setSearch={setmainCategorySearch} />
              </Box>
              )
              }
            </Box>
             </TableCell>
            ))}
            <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }}>Approve</TableCell>
            <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }}>Reject</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {FilterSumCat(data, employeeIdSearch, projectNameSearch, mainCategorySearch)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow key={index}>
                {keys.map((key) => (
                  <TableCell align="center" key={key}>
                    {key === "Images" ? (
                      row[key] ? (
                        <img src={`${expenseDocs}${row[key]}`} onClick={()=>handleImageDialog(row[key])} style={{ width: 100, height: 100 }} alt={row[key]} />
                      ) : (
                        "-"
                      )
                    ) : key === "AudioFile" ? (
                      row[key] ? (
                        <audio controls>
                          <source src={`${expenseDocs}${row[key]}`} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      ) : (
                        "-"
                      )
                    ) : (
                      row[key]
                    )}
                  </TableCell>
                ))}
                <TableCell align="center">
                <Button onClick={()=>UpdateStatus(row , 1)} variant="contained" >Approve</Button>
                </TableCell>
                <TableCell align="center">
                <Button onClick={()=>Reject(row)} color="error" variant="contained" >Reject</Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={FilterSumCat(data, employeeIdSearch, projectNameSearch, mainCategorySearch).length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
          </Grid>
          </Box>


</Container>
  </Box>
  )
}
