import React, { useState } from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useForm } from 'react-hook-form';
import { LeaveApprovaltable, OnBoardDeletetable, OnBoardtable, paysliptable } from '../../../Variables/Variables';
import { get_employee_detail, get_employee_name } from '../../../API_Service/API_Links';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FilterData } from '../../FilterData/FilterData';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteEmployeeDialog from './DeleteEmployeeDialog';
import SnackBar from '../../SnackBar';

export default function EditTab() {

  const { formState: { errors }, handleSubmit } = useForm();
  const [EmployeeName, setEmployeeName] = useState("");
  const [EmployeeId, setEmployeeId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [allEmployee, setAllEmployee] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const options = ['Approved', 'Pending', 'Rejected'];
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [EmployeeNameList, setEmployeeNameList] = useState([]);
  const [OpenDeleteDialog, setOpenDeleteDialog]= useState(false);
  const [SelectedEmployee, setSelectedEmployee] = useState([]);
  const [SelectedEmployeeId, setSelectedEmployeeId] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  useEffect(() => {
    if (EmployeeId !== '' && EmployeeId !== null) {
      const sendData = new FormData()
      sendData.append('EmployeeId', EmployeeId)
      axios({
        method: 'POST',
        url: get_employee_name,
        data: sendData
      }).then(res => {
        if (res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
        } else {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          setEmployeeName(res.data.data.EmployeeName);
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    }
  }, [EmployeeId])



  const getAllEmployeeAtOnce = () => {
    const sendData = new FormData()
    sendData.append('EmployeeName', EmployeeName)
    sendData.append('EmployeeId', EmployeeId)
    axios({
      method: 'POST',
      url: get_employee_detail,
      data: sendData
    }).then(res => {
      if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
        setAllEmployee([])
      } else {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setAllEmployee(res.data.data);
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllEmployeeAtOnce();
  }
    , [])

  const getFilteredEmployee = () => {
    if (EmployeeName !== '') {
      const sendData = new FormData()
      sendData.append('EmployeeName', EmployeeName)
      sendData.append('EmployeeId', EmployeeId)
      axios({
        method: 'POST',
        url: get_employee_detail,
        data: sendData
      }).then(res => {
        if (res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
        } else {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          setAllEmployee([res.data.data]);
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    }
    else {
      const sendData = new FormData()
      sendData.append('EmployeeName', '')
      sendData.append('EmployeeId', '')
      axios({
        method: 'POST',
        url: get_employee_detail,
        data: sendData
      }).then(res => {
        if (res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
        } else {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          setAllEmployee(res.data.data);
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    }
  }

  const HandleOpenDeleteDialog = (item, Eid) =>{
     setOpenDeleteDialog(true);
    setSelectedEmployee(item);
    setSelectedEmployeeId(Eid)
  }

  return (
    <Box>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />
      <DeleteEmployeeDialog getAllEmployeeAtOnce={getAllEmployeeAtOnce} SelectedEmployeeId={SelectedEmployeeId} OpenDeleteDialog={OpenDeleteDialog} setOpenDeleteDialog={setOpenDeleteDialog} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} SelectedEmployee={SelectedEmployee} />
   <Container>
   <Box component={Card} p={4}>
      <Grid container>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Box>
                <Box py={2}>
                  <Typography sx={{ fontSize: 17, fontWeight: 700, color: '#616e80' }}>Employee Id</Typography>
                </Box>
                <Box mt={1}>
                  <TextField
                    id="EmployeeId"
                    label="Employee Id" variant="outlined"
                    size='small'
                    color='secondary'
                    value={EmployeeId}
                    onChange={(e) => setEmployeeId(e.target.value)}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Box>
                <Box py={2}>
                  <Typography sx={{ fontSize: 17, fontWeight: 700, color: '#616e80' }}>Employee Name</Typography>
                </Box>
                <Box mt={1}>
                  <TextField
                    id="EmployeeName"
                    label="Employee Name" variant="outlined"
                    size='small'
                    color='secondary'
                    value={EmployeeName}
                  />
                </Box>
              </Box>
            </Grid>

        <Grid item xs={4} sm={4} md={4} lg={4}>
      <Box display='flex' justifyContent='start' alignSelf='center' py={4}>
                <Button variant='contained' color='secondary' onClick={getFilteredEmployee}>GET EMPLOYEE INFO</Button>
      </Box>
      </Grid>

         </Grid>
    </Box>

    <Box mt={2} p={2} bgcolor='#EDF4F4'>
    <Grid container>
                    <TableContainer sx={{border:'1px solid silver'}} >

                    <Table>
                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
                    <TableRow sx={{borderBottom:'1px solid silver'}}>
                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600  }}>
                    Employee ID
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                    Employee Name
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                      Department
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                      Role
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                      Joined Date
                    </TableCell> 
                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                      Remove
                    </TableCell>
                    </TableRow>
                    </TableHead>


                    <TableBody>
                  {
                    allEmployee && allEmployee.filter((data) => FilterData(data, search, {
                      searchFeildOne: data.id,
                      searchFeildTwo: data.status,
                    })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i, index) => {
                      return (
                    <TableRow key={index} sx={{borderBottom:'1px solid silver'}}>
                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.EmployeeId}</TableCell>
                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.EmployeeName}</TableCell>
                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.department}</TableCell>
                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.role}</TableCell>
                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.dateOfJoining}</TableCell>
                          <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }} onClick={() => HandleOpenDeleteDialog(i, i.EmployeeId)}><DeleteIcon color='error' sx={{verticalAlign:'middle'}} /></TableCell>
                    </TableRow>
                          )
                        })
                      }
                    </TableBody>
                    </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={allEmployee.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Grid>
                  </Box>


</Container>

  </Box>
  )
}
