import React, { useState } from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container, Tab} from "@mui/material";
import Heading from '../Heading';
import Breadcrumbs from '../Breadcrumbs';
import IconBreadcrumbs from '../Breadcrumbs';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Form16Add from './Form16/Form16Add';
import Form16Get from './Form16/Form16Get';

export default function Form16Ssection() {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
};
  return (
    <Box>
      <Box py={2} px={1}>
    <IconBreadcrumbs
    previous={'Home'}
    current={'HRMS'}
    currentSection={'Form 16'}
    link1={`/home`}
    link2={'/home/HRMS'}
    
    />
    </Box>

    <Container>
      <Box py={3}>
      <Heading  title={'FORM 16'}/>
      </Box>
    </Container>

    <TabContext value={value}>
      <Box>
          <TabList
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          onChange={handleChange} aria-label="tabs">
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Add Form 16" value="1" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', m:2  }} label="Table Form 16" value="2" />
          </TabList>
          </Box>

          <TabPanel value="1">
          <Box>
          <Form16Add />
          </Box>
          </TabPanel>
          <TabPanel value="2">
          <Box>
          <Form16Get />
          </Box>
          </TabPanel>
        </TabContext>
  </Box>
  )
}
