import { useEffect, useState } from 'react';

// This custom hook will handle the cleanup on beforeunload event and inactivity timer
const useBeforeUnload = (callback, inactivityThreshold) => {
    const [lastActivity, setLastActivity] = useState(Date.now());

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            callback();
        };

        const handleActivity = () => {
            setLastActivity(Date.now());
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);

        const inactivityTimer = setInterval(() => {
            if (Date.now() - lastActivity >= inactivityThreshold) {
                callback();
            }
        }, inactivityThreshold);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            clearInterval(inactivityTimer);
        };
    }, [callback, inactivityThreshold, lastActivity]);
};

export default useBeforeUnload;
