import React,{ useState } from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container, Divider} from "@mui/material";
import Heading from '../../../Components/Heading';
import Breadcrumbs from '../../../Components/Breadcrumbs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useNavigate } from 'react-router-dom';
import CashOnHand from './CashOnHand';
import AllCashInHand from './AllCashInHand';


export default function CashInHandTab() {



      const [value, setValue] = useState("1");

  // Tab functionality
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const navigate = useNavigate();

  const handleClick = (path) =>{
   navigate(`${path}`)
  }

  const access = JSON.parse(localStorage.getItem('access'));
  const { CreateEvent : Create, SeeAllEvents } = access;


  return (
    <Box>

    <Box py={2} px={1}>
    <Breadcrumbs 
    previous={'Home'}
    current={'Finance'}
    link1={`/home`}
    link2={'/home/finance'}
    currentSection={'Cash In Hand'}
    curr
    />
    </Box>

    <Container>
      <Box py={3}>
      <Heading  title={'Cash In Hand'}/>
      </Box>
    </Container>



 <Container>

    <Box >
    <TabContext value={value}>
      <Box>
          <TabList
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
          onChange={handleChange} aria-label="tabs">
            {
            SeeAllEvents === 'true' && 
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginLeft: 5 }} label="New Entry" value="1" />
            }
            {
            SeeAllEvents === 'true' && 
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginLeft: 5 }} label="Spend List" value="2" />
            }
          </TabList>
          </Box>
          {
          SeeAllEvents === 'true' &&
          <TabPanel value="1">
          <Box>
          <CashOnHand />
          </Box>
          </TabPanel>
          }
                    {
          SeeAllEvents === 'true' &&
          <TabPanel value="2">
          <Box>
          <AllCashInHand />
          </Box>
          </TabPanel>
          }
        </TabContext>
      </Box>
    </Container>

  </Box>
  )
}
