import { Box, Button, Card, Grid, TextField, Typography, Container } from "@mui/material";
import React, { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import {
  add_amount, getAllEmployeeName, getAllSiteProject,
  get_available_balance, get_department, get_factory_employees, methodGet, methodPost, underInsertMaintenance
} from '../../../API_Service/API_Links';
import { appendData } from '../../../Variables/Variables';
import SnackBar from "../../SnackBar";
import { Form } from "react-router-dom";
import { useForm } from 'react-hook-form';

export default function AddUnderMaintenancePage() {
  const { handleSubmit } = useForm();
  const [maintenanceCategoryId, setMaintenanceCategoryId] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState(null);
  const [mechineDetails, setMechineDetails] = useState(null);
  const [vehiclesDetails, setVehiclesDetails] = useState(null);
  const [subCategoryImages, setSubCategoryImages] = useState(null);


  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [input, setInput] = useState(false);

  const underMaintenanceOptions = ['Machine', 'Vehicle'];

  const handleUnderMaintenanceChange = (event, value) => {
    setMaintenanceCategoryId(value);
  };

  const onSubmit = () => {
    const obj = {
      maintenanceCategoryId: maintenanceCategoryId,
      subCategoryName: subCategoryName,
      mechineDetails: mechineDetails,
      vehiclesDetails: vehiclesDetails,
      subCategoryImages: subCategoryImages
    }
    const ldata = appendData(obj);
    axios({
      method: 'POST',
      url: underInsertMaintenance,
      data: ldata,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setMaintenanceCategoryId(null);
        setSubCategoryName('');
        setMechineDetails('');
        setVehiclesDetails('');
        setSubCategoryImages('');
        document.getElementById('subCategoryImages').value = '';
      } else {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
        setInput(false);
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }
  return (
    <Box>
      <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
      <Container>
        <Box component={Card} p={4}>


          <Box
            sx={{
              border: "1px solid black",
              p: 4,
              borderColor: "#d2cbcb;",
              borderRadius: "4px",
              ":hover": { boxShadow: 2 },
              mt: 5,
            }}
          >

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Autocomplete
                  disablePortal
                  id="time-combo-box"
                  options={underMaintenanceOptions}
                  value={maintenanceCategoryId}
                  onChange={handleUnderMaintenanceChange}
                  size="small"
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ my: 1 }}
                      fontSize="small"
                      label="Maintenance Category"
                      color="secondary"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  label="SubCategory Name"
                  value={subCategoryName}
                  onChange={(e) => setSubCategoryName(e.target.value)}
                  fullWidth
                  sx={{ my: 1 }}
                  size='small'
                />
              </Grid>
              {maintenanceCategoryId === 'Vehicle' ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      name="Vehicles Details"
                      label="Vehicles Details"
                      value={vehiclesDetails}
                      onChange={(e) => setVehiclesDetails(e.target.value)}
                      fullWidth
                      sx={{ my: 1 }}
                      size="small"
                      required
                    />
                  </Grid>
              ): (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  label="Mechine Details"
                  value={mechineDetails}
                  onChange={(e) => setMechineDetails(e.target.value)}
                  fullWidth
                  sx={{ my: 1 }}
                  size='small'
                  required
                />
              </Grid>
              )}

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  name="Image"
                  label="SubCategory Images"
                  type='file'
                  id='subCategoryImages'
                  onChange={(e) => setSubCategoryImages(e.target.files[0])}
                  fullWidth
                  sx={{ my: 1 }}
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box display='flex' justifyContent='center' py={2}>
                  <Button onClick={onSubmit} variant='contained' color='secondary' fullWidth>Submit</Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
