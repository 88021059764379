import React, { useState } from 'react'
import { Box, Button, Card, Grid, TextField, Typography, Container, Autocomplete, Snackbar, Alert, Grow } from "@mui/material";
import Heading from '../Heading';
import Breadcrumbs from '../Breadcrumbs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AddTab from './OnBoardTabPanels/AddTab';
import EditTab from './OnBoardTabPanels/EditTab';
import DeleteTab from './OnBoardTabPanels/DeleteTab';
import { Outlet} from 'react-router-dom';
import IconBreadcrumbs from '../Breadcrumbs';
import CreateCTCTab from './OnBoardTabPanels/CreateCTCTab';
import OnDutyPendingPage from './OnDutyTabPanels/Pending';
import OnDutyApprovedPage from './OnDutyTabPanels/Approved';
import OnDutyRejectedPage from './OnDutyTabPanels/Rejected';



export default function OnDuty() {


  const [value, setValue] = useState("1");
  const [isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen] = useState(false);
  const [Notification, setNotification] = useState("");

  // Tab functionality
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const handleCloseAddedCartSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsAddedCartSnackbarOpen(false);
  };

  function GrowTransition(props) {
    return <Grow {...props} direction="up" />;
  }


  return (
  <Box>

      <Snackbar
        open={isAddedCartSnackbarOpen}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={GrowTransition}
        onClose={handleCloseAddedCartSnackbar}>
        <Alert onClose={handleCloseAddedCartSnackbar} severity='success' variant="filled" sx={{ width: '100%' }}>
          {Notification}
        </Alert>
      </Snackbar>

    <Box py={2} px={1}>
    <IconBreadcrumbs
    previous={'Home'}
    current={'HRMS'}
    currentSection={'OnDuty'}
    link1={`/home`}
    link2={'/home/HRMS'}

    />
    </Box>

    <Container>
      <Box py={3}>
      <Heading  title={'OnBoard Informations'}/>
      </Box>
    </Container>

    {/* Table Panel */}

    <Container>
    <Box sx={{ p: 3 }}>
    <TabContext value={value}>
      <Box>
          <TabList
          variant="scrollable"
          scrollButtons='auto'
          onChange={handleChange} aria-label="tabs">
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#616e80', marginRight:5 }} label="Pending" value="1" />
          <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#616e80', marginLeft:5}} label="Approved" value="2" />
         <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#616e80', marginLeft:5}} label="Rejected" value="3" />
          </TabList>
          </Box>
          <TabPanel value="1">
            <Box>
                <OnDutyPendingPage setValue={setValue} isAddedCartSnackbarOpen={isAddedCartSnackbarOpen} setIsAddedCartSnackbarOpen={setIsAddedCartSnackbarOpen} setNotification={setNotification} />
            </Box>
        </TabPanel>
          <TabPanel value="2">
          <Box>
                <OnDutyApprovedPage setValue={setValue} isAddedCartSnackbarOpen={isAddedCartSnackbarOpen} setIsAddedCartSnackbarOpen={setIsAddedCartSnackbarOpen} setNotification={setNotification} />
           </Box>
        </TabPanel>
        <TabPanel id='editTab' value="3">
            <Box>
            <OnDutyRejectedPage setValue={setValue} isAddedCartSnackbarOpen={isAddedCartSnackbarOpen} setIsAddedCartSnackbarOpen={setIsAddedCartSnackbarOpen} setNotification={setNotification} />
            </Box>
        </TabPanel>
        </TabContext>
      </Box>
    </Container>
















  </Box>
  )
}
