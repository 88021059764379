import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import {  methodPost, updateTechnicalToCommercial } from '../../API_Service/API_Links';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function TechnicalFromCommercialDialogPage({
  openApproveDialog,
  setopenApproveDialog,
  data,
  setOpen,
  setMessage,
  setStatus,
  setColor
}) {


  const [itemCode, setItemCode] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [SetQuantity, setSetQuantity] = useState('');
  const [quantity, setQuantity] = useState('');
  const [itemName, setItemName] = useState('');
  const [itemValue, setItemValue] = useState('');
  const [rawMaterialGrade, setRawMaterialGrade] = useState('');
  const [input, setInput] = useState(false);

  const [itemsListData, setItemsListData] = useState([]);
  const [postItemsListData, setPostItemsListData] = useState([]);

  const handleClose = () => {
    setopenApproveDialog(false);
    setInput(true);
    setPostItemsListData([]);

  };
  const addItemsList = () => {
    if (itemCode !== '' && itemDescription !== '' && setQuantity !== '' && itemName !== '' && itemValue !== '' && rawMaterialGrade !== '') {
      const newObject = { "ItemCode": itemCode, "ItemDescription": itemDescription, "SetQuantity": SetQuantity, "Quantity": quantity, "ItemName": itemName, "ItemValue": itemValue, "RawMaterialGrade": rawMaterialGrade };
      setItemsListData([...itemsListData, newObject]);
      const newObject1 = { "ItemCode": itemCode, "ItemDescription": itemDescription, "SetQuantity": SetQuantity, "Quantity": quantity, "ItemName": itemName, "ItemValue": itemValue, "RawMaterialGrade": rawMaterialGrade };
      setPostItemsListData([...postItemsListData, newObject1]);
      setItemCode('');
      setItemDescription('');
      setSetQuantity('');
      setQuantity('');
      setItemName('');
      setItemValue('');
      setRawMaterialGrade('');
    }
  }

  const approvereq = () => {
    const sendData = new FormData()
    // sendData.append('Id', data.id)
    sendData.append('itemsList', JSON.stringify(itemsListData))
    sendData.append('ProjectId', data.ProjectId)

    axios({
      method: methodPost,
      url: updateTechnicalToCommercial,
      data: sendData
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true)
          setStatus(true)
          setColor(true)
          handleClose();
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
          setPostItemsListData([]);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }


  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={openApproveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Add Raw Materials</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                label="Item Code"
                value={itemCode}
                onChange={(e) => setItemCode(e.target.value)}
                fullWidth
                sx={{ my: 1 }}
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                label="Item Description"
                value={itemDescription}
                onChange={(e) => setItemDescription(e.target.value)}
                fullWidth
                sx={{ my: 1 }}
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                label="Set Quantity"
                value={SetQuantity}
                onChange={(e) => {
                  const input = e.target.value;
                  const regex = /^[0-9]*$/;
                  if (regex.test(input)) { setSetQuantity(e.target.value)}
                }}
                fullWidth
                sx={{ my: 1 }}
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                label="Quantity"
                value={quantity}
                onChange={(e) => {
                  const input = e.target.value;
                  const regex = /^[0-9]*$/;
                  if (regex.test(input)) {
                    setQuantity(e.target.value);
                  }
                }}
                fullWidth
                sx={{ my: 1 }}
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                label="Item Name"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                fullWidth
                sx={{ my: 1 }}
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                label="Item Value"
                value={itemValue}
                onChange={(e) => {
                  const input = e.target.value;
                  const regex = /^[0-9]*$/;
                  if (regex.test(input)) { setItemValue(e.target.value)}
                }}
                fullWidth
                sx={{ my: 1 }}
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                label="RawMaterialGrade"
                value={rawMaterialGrade}
                onChange={(e) => setRawMaterialGrade(e.target.value)}
                fullWidth
                sx={{ my: 1 }}
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box mb={3} mt={1}>
                <Button variant='contained' fullWidth onClick={addItemsList}  ><AddCircleOutlineIcon sx={{ verticalAlign: 'middle' }} />Add</Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant='h6' sx={{ marginBottom: 2 }}>Raw Material List</Typography>
              <Grid container>
                {
                  postItemsListData.length !== 0 && postItemsListData.map((i) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} lg={4} mt={2}>
                        <Box>
                          <Typography variant='body1' fontWeight={600}>
                            ItemCode:{i.ItemCode} <br /> Item Description: {i.ItemDescription} <br /> Setquantity: {i.SetQuantity} <br /> Quantity: {i.Quantity} <br /> Item Name: {i.ItemName} <br /> Item Value{i.ItemValue} <br /> Raw Material Grade {i.RawMaterialGrade}</Typography>
                        </Box>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleClose}>Cancel</Button>
          <Button variant='contained' color='primary' onClick={approvereq}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}