import React, { useEffect, useState } from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import Heading from '../Heading';
import Breadcrumbs from '../Breadcrumbs';
import { assignedProjectsempTable, LeaveApprovaltable, paysliptable } from '../../Variables/Variables';
import Filter from '../FilterData/Filter';
import {FilterAssignedFactory, FilterData} from '../FilterData/FilterData';
import { useNavigate } from 'react-router-dom';
import { get_all_factory_project, methodGet } from '../../API_Service/API_Links';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import axios from 'axios';

export default function AssignedFactoryProjectTable() {

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();

  const [projectNameSearch, setprojectNameSearch] = useState('');
  const [projectTypeSearch, setprojectTypeSearch] = useState('');

  useEffect(() => {
    axios({
      method: 'GET',
      url: get_all_factory_project,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setdata(res.data.data);
          setOpen(true);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  const statusMapping = {
    1: 'In-Progress',
    2: 'Purchase Pending',
    3: 'Finance Pending',
    4: 'Finance Approved',
    5: 'Raw Material Available',
    6: 'Assigned To Employee',
    7: 'Completed',
  };


const handleChangePage = (event, newPage) => {
    setPage(newPage);
};
const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

 const handleOpenEdit = (i) =>{
  navigate('editFactproject', {state:{id:i.ProjectId}});
 }

  const handleOpenView = (i) => {
  navigate('viewFactproject', {state:{project:i}})
  }

  const keys = Object.keys(data[0] || {}).filter((key) => key !== "ProjectId");

  const keysToKeep = [
    "ProjectName",
    "ProjectCode",
    "CompanyName",
    "ProjectType",
    "CustomerName",
    "ProjectStatus"
  ];

  const filteredKeys = keys.filter((key) => keysToKeep.includes(key));

  return (
    <Box boxShadow={4} bgcolor='#EDF4F4'>



    <Container>
    <Box mt={2} py={4}  >
    <Grid container>

    <TableContainer sx={{border:'1px solid silver'}} >
         <Table>
         <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
          <TableRow>
            {filteredKeys.map((key) => (
             <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }} key={key}>
                <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                {key.split(/(?=[A-Z])/).join(' ')}
                </Box>
                  {key === "ProjectName" && (
                    <Box>
                      <Filter search={projectNameSearch} setSearch={setprojectNameSearch} setPage={setPage} />
                    </Box>
                  )
                  }
                  {key === "ProjectType" && (
                    <Box>
                      <Filter search={projectTypeSearch} setSearch={setprojectTypeSearch} setPage={setPage} />
                    </Box>
                  )
                  }
            </Box>
             </TableCell>
            ))}
            <TableCell sx={{ textAlign: 'center', color: 'white',textTransform: 'uppercase', fontWeight: 500 }}>
            View
            </TableCell>
            <TableCell sx={{ textAlign: 'center', color: 'white', textTransform: 'uppercase' ,fontWeight: 500 }}>
            Edit
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {FilterAssignedFactory(data, projectNameSearch, projectTypeSearch)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow key={index}>
             {filteredKeys.map((key) => (
                  <TableCell align="center" key={key}>
                    {row[key]}
                  </TableCell>
                ))}
                 <TableCell onClick={()=>handleOpenView(row)} sx={{ cursor:'pointer' , textAlign:'center',borderBottom:'1px solid silver'}}><VisibilityIcon /></TableCell>
                  <TableCell onClick={()=>handleOpenEdit(row)} sx={{cursor:'pointer' ,textAlign:'center',borderBottom:'1px solid silver'}}><ModeEditIcon /></TableCell>
              </TableRow>
            ))}
        </TableBody>
              </Table>
            </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={FilterAssignedFactory(data, projectNameSearch, projectTypeSearch).length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Grid>
                  </Box>
                </Container>



  </Box>
  )
}
