import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Grid, TextField, Typography, Container, Autocomplete, Table, TableContainer, TablePagination, TableCell, TableBody, TableRow, TableHead } from "@mui/material";
import { useForm } from 'react-hook-form';
import { SiteDocs, add_factory_project, getDeduction, getFlowOfBill, getParticularSiteProject, getTenderSiteProject, methodGet, methodPost, updateSiteProject } from '../../API_Service/API_Links';
import axios from 'axios';
import { appendData } from '../../Variables/Variables';
import SnackBar from '../SnackBar';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import IconBreadcrumbs from '../Breadcrumbs';
import Heading from '../Heading';
import SiteProjectBillChatDialog from './SiteProjectBillChatDialog';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function SiteProjectsViewForm() {


    const options = ['Factory', 'Field'];

    const { formState: { errors }, handleSubmit } = useForm();

    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const empID = JSON.parse(localStorage.getItem('EmployeeId'));
    const location = useLocation();
    const ProjectCode = location.state.projectCode;
    const SiteProjectId = location.state.SiteProjectId;
    const [currentProjectData, setcurrentProjectData] = useState([]);
    const [FlowBillList, setFlowBillList] = useState([]);
    const [deductionTypeOptions, setdeductionTypeOptions] = useState([]);

    // FILEDVALUE STATE
    const [selectedClient, setSelectedClient] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedDistrict, setSelectedDistrict] = useState("");
    const [projectType, setSelectedProjectType] = useState("");
    const [siteProjectId, setsiteProjectId] = useState('');
    const [selectedArea, setSelectedArea] = useState("");
    const [districtList, setDistrictList] = useState([]);
    const [client, setClient] = useState([]);
    const [state, setState] = useState([]);



    // CRAETING NEW PROJECT STATES
    const [selectedTenderType, setSelectedTenderType] = useState('');
    const [tenderSpecRemarkText, setTenderSpecRemarkText] = useState('');
    const [tenderSpecDocument, setTenderSpecDocument] = useState('');
    const [openingDateRemarkText, setOpeningDateRemarkText] = useState('');
    const [openingDate, setOpeningDate] = useState('');
    const [closingDateRemarkText, setClosingDateRemarkText] = useState('');
    const [dateError, setDateError] = useState('');
    const [closingDate, setClosingDate] = useState('');
    const [BQRRemarkText, setBQRRemarkText] = useState('');
    const [BQRDocument, setBQRDocument] = useState('');
    const [EMDExemptionRemarkText, setEMDExemptionRemarkText] = useState('');
    const [EMDExemptionDocument, setEMDExemptionDocument] = useState('');
    const [TenderLostRemarkText, setTenderLostRemarkText] = useState('');
    const [SelectedTenderLost, setSelectedTenderLost] = useState('');
    const [TenderLostCompetitorName, setTenderLostCompetitorName] = useState('');
    const [TenderLostAmount, setTenderLostAmount] = useState('');
    const [BillDeductionRemarkText, setBillDeductionRemarkText] = useState('');
    const [BillDeductionDocument, setBillDeductionDocument] = useState('');
    const [BOQRemarkText, setBOQRemarkText] = useState('');
    const [BOQDocument, setBOQDocument] = useState('');
    const [ProjectDetailsRemarkText, setProjectDetailsRemarkText] = useState('');
    const [ProjectDetailsDocument, setProjectDetailsDocument] = useState('');
    const [LOARemarkText, setLOARemarkText] = useState('');
    const [LOADocument, setLOADocument] = useState('');
    const [ProfileDrawingRemarkText, setProfileDrawingRemarkText] = useState('');
    const [ProfileDrawing, setProfileDrawing] = useState('');
    const [AgreementRemarkText, setAgreementRemarkText] = useState('');
    const [AgreementDocument, setAgreementDocument] = useState('');
    const [SupplyofMaterialRemarkText, setSupplyofMaterialRemarkText] = useState('');
    const [SupplyofMaterialDocument, setSupplyofMaterialDocument] = useState('');
    const [SubContract, setSubContract] = useState('');
    const [SubContractNameRemarkText, setSubContractNameRemarkText] = useState('');
    const [SubContractName, setSubContractName] = useState('');
    const [SubContractOrderRemarkText, setSubContractOrderRemarkText] = useState('');
    const [SubContractOrder, setSubContractOrder] = useState('');
    const [ScopeOfWorkRemarkText, setScopeOfWorkRemarkText] = useState('');
    const [ScopeOfWork, setScopeOfWork] = useState('');
    const [OurScopeOfWorkRemarkText, setOurScopeOfWorkRemarkText] = useState('');
    const [OurScopeOfWork, setOurScopeOfWork] = useState('');
    const [OwnMachineOrSubRemarkText, setOwnMachineOrSubRemarkText] = useState('');
    const [SelectedOwnMachineOrSub, setSelectedOwnMachineOrSub] = useState('');
    const [BillingDetailsRemarkText, setBillingDetailsRemarkText] = useState('');
    const [BillingDetailsDocument, setBillingDetailsDocument] = useState('');
    const [FlowOfBillsRemarkText, setFlowOfBillsRemarkText] = useState('');
    const [FlowOfBills, setFlowOfBills] = useState('');
    const [SelectedFlowOfBills, setSelectedFlowOfBills] = useState('');
    const [PaymentsRemarkText, setPaymentsRemarkText] = useState('');
    const [PaymentsReceived, setPaymentsReceived] = useState('');
    const [SelectedDeductionType, setSelectedDeductionType] = useState('');
    const [deductedAmount, setDeductedAmount] = useState('');
    const [SDRetentionDateRemarkText, setSDRetentionDateRemarkText] = useState('');
    const [SDRetentionDate, setSDRetentionDate] = useState('');
    const [SDRetentionAmount, setSDRetentionAmount] = useState('');
    const [EMDRetentionDateRemarkText, setEMDRetentionDateRemarkText] = useState('');
    const [EMDRetentionDate, setEMDRetentionDate] = useState('');
    const [EMDRetentionAmount, setEMDRetentionAmount] = useState('');
    const [OtherRetentionDateRemarkText, setOtherRetentionDateRemarkText] = useState('');
    const [OtherRetentionDate, setOtherRetentionDate] = useState('');
    const [OtherRetentionAmount, setOtherRetentionAmount] = useState('');
    const [AllDeduction, setAllDeduction] = useState([]);
    const [lastUpdatedEmployeeID, setlastUpdatedEmployeeID] = useState('');
    const [lastUpdatedDate, setlastUpdatedDate] = useState('');
    const [lastUpdatedEmployeeName, setlastUpdatedEmployeeName] = useState('');
    const [ManualOurScopeEntries, setManualOurScopeEntries] = useState('');
    const [ManualOurScopeEntriesFromAPI, setManualOurScopeEntriesFromAPI] = useState('');
    const [loading, setLoading] = useState(false);
    const [isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen] = useState(false);
    const [Notification, setNotification] = useState("");
    const [qualified, setQualified] = useState("");
    const [tenderAmount, setTenderAmount] = useState("");
    const [emdFile, setEmdFile] = useState("");
    const [sdFile, setSdFile] = useState("");
    const [otherFile, setOtherFile] = useState("");
    const [descriptions, setDescriptions] = useState("");
    const [unit, setUnit] = useState("");
    const [unitRate, setUnitRate] = useState("");
    const [siteAmount, setSiteAmount] = useState("");
    const [siteTenderQuantity, setSiteTenderQuantity] = useState("");
    const [addUnit, setAddUnit] = useState("");
    const [unitDropDown, setUnitDropDown] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [openUnitDialog, setOpenUnitDialog] = useState(false);
    const [input, setInput] = useState(false);
    const [addDescription, setAddDescription] = useState("");
    const [descriptionDropDown, setdescriptionDropDown] = useState([]);
    const [selectedDescription, setSelectedDescription] = useState(null);
    const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
    const [detailList, setDetailList] = useState([]);
    const [detailsCount, setDetailsCount] = useState(0);
    const [allTenderSite, setAllTenderSite] = useState([]);
    // checkBoxes

    const [checkboxState, setCheckboxState] = useState({
        hdd: '',
        escavatorsSmall: '',
        hydraSmall: '',
        hydraBig: '',
        generator: '',
        weldingMachine: '',
        grindingMachine: '',
        clamp: '',
        rollersSmall: '',
        rollersBig: '',
        cradels: '',
        drumStands: '',
        winchMachine: '',
        manPower: '',
    });


    const [subCheckboxState, setSubCheckboxState] = useState({
        subHDD: '',
        subEscavatorsSmall: '',
        subHydraSmall: '',
        subHydraBig: '',
        subGenerator: '',
        subWeldingMachine: '',
        subGrindingMachine: '',
        subClamp: '',
        subRollersSmall: '',
        subRollersBig: '',
        subCradels: '',
        subDrumStands: '',
        subWinchMachine: '',
        submanPower: '',
    });

    // GET Current Project
    useEffect(() => {
        const serverData = new FormData()
        serverData.append('SiteProjectId', SiteProjectId);
        axios({
            method: 'POST',
            url: getParticularSiteProject,
            data: serverData
        }).then(res => {
            if (!res.data.error) {
                setcurrentProjectData(res.data.data[0]);
                setSelectedTenderType(res.data.data[0].TenderType);
                setTenderSpecRemarkText(res.data.data[0].TenderSpecRemarks);
                setTenderSpecDocument(res.data.data[0].TenderSpecFile);
                setOpeningDateRemarkText(res.data.data[0].OpeningDateRemarks);
                setOpeningDate(res.data.data[0].OpeningDate);
                setClosingDateRemarkText(res.data.data[0].ClosingDateRemarks);
                setClosingDate(res.data.data[0].closingDate);
                setBQRRemarkText(res.data.data[0].bqrRemarks);
                setBQRDocument(res.data.data[0].bqrFile);
                setEMDExemptionRemarkText(res.data.data[0].emdExemptionRemarks);
                setEMDExemptionDocument(res.data.data[0].emdExemptionFile);
                setTenderLostRemarkText(res.data.data[0].tenderLostRemarks);
                setSelectedTenderLost(res.data.data[0].tenderLost);
                setTenderLostCompetitorName(res.data.data[0].opponentCompany);
                setTenderLostAmount(res.data.data[0].amount);
                setBillDeductionRemarkText(res.data.data[0].billDeductionRemarks);
                setBillDeductionDocument(res.data.data[0].billDeductionFile);
                setBOQRemarkText(res.data.data[0].boqRemarks);
                setBOQDocument(res.data.data[0].boqFile);
                setProjectDetailsRemarkText(res.data.data[0].projectDetailsRemarks);
                setProjectDetailsDocument(res.data.data[0].projectDetailsFile);
                setLOARemarkText(res.data.data[0].workOrderRemarks);
                setLOADocument(res.data.data[0].workOrderFile);
                setProfileDrawingRemarkText(res.data.data[0].profileDrawingRemarks);
                setProfileDrawing(res.data.data[0].profileDrawing);
                setAgreementRemarkText(res.data.data[0].agreementRemarks);
                setAgreementDocument(res.data.data[0].agreementFile);
                setSupplyofMaterialRemarkText(res.data.data[0].budgetRemarks);
                setSupplyofMaterialDocument(res.data.data[0].budgetFile);
                setSubContract(res.data.data[0].subcontract);
                setSubContractNameRemarkText(res.data.data[0].subcontractNameRemarks);
                setSubContractName(res.data.data[0].subcontractName);
                setSubContractOrderRemarkText(res.data.data[0].subcontractOrderRemarks);
                setSubContractOrder(res.data.data[0].subcontractOrder);
                setScopeOfWorkRemarkText(res.data.data[0].subcontractScopeWorkRemarks);
                setScopeOfWork(res.data.data[0].subcontractScopeWork);
                setOurScopeOfWorkRemarkText(res.data.data[0].ourScopeOfWorkRemarks);
                setOurScopeOfWork(res.data.data[0].ourScopeOfWork);
                setOwnMachineOrSubRemarkText(res.data.data[0].machineOrSubcontractRemark);
                setSelectedOwnMachineOrSub(res.data.data[0].machineOrSubcontract);
                setBillingDetailsRemarkText(res.data.data[0].billingRemarks);
                setBillingDetailsDocument(res.data.data[0].billingFile);
                setFlowOfBillsRemarkText(res.data.data[0].flowBillsRemarks);
                setSelectedFlowOfBills(res.data.data[0].flowBills);
                setPaymentsRemarkText(res.data.data[0].paymentReceivedRemarks);
                setPaymentsReceived(res.data.data[0].paymentReceived);
                setSelectedDeductionType(res.data.data[0].deductionType);
                setDeductedAmount(res.data.data[0].deductedAmount);
                setSDRetentionDateRemarkText(res.data.data[0].sdRetentionRemarks);
                setSDRetentionDate(res.data.data[0].sdRetentionDate);
                setSDRetentionAmount(res.data.data[0].sdAmount);
                setEMDRetentionDateRemarkText(res.data.data[0].emdRetentionRemarks);
                setEMDRetentionDate(res.data.data[0].emdRetentionDate);
                setEMDRetentionAmount(res.data.data[0].emdAmount);
                setOtherRetentionDateRemarkText(res.data.data[0].otherRetentionRemarks);
                setOtherRetentionDate(res.data.data[0].otherRetentionDate);
                setOtherRetentionAmount(res.data.data[0].otherAmount);
                setTenderAmount(res.data.data[0].tenderAmount);

                setCheckboxState({
                    hdd: res.data.data[0].hdd,
                    escavatorsSmall: res.data.data[0].escavatorsSmall,
                    hydraSmall: res.data.data[0].hydraSmall,
                    hydraBig: res.data.data[0].hydraBig,
                    generator: res.data.data[0].generator,
                    weldingMachine: res.data.data[0].weldingMachine,
                    grindingMachine: res.data.data[0].grindingMachine,
                    clamp: res.data.data[0].clamp,
                    rollersSmall: res.data.data[0].rollersSmall,
                    rollersBig: res.data.data[0].rollersBig,
                    cradels: res.data.data[0].cradels,
                    drumStands: res.data.data[0].drumStands,
                    winchMachine: res.data.data[0].winchMachine,
                    manPower: res.data.data[0].manPower,
                });

                setSubCheckboxState({
                    subHDD: res.data.data[0].subHDD,
                    subEscavatorsSmall: res.data.data[0].subEscavatorsSmall,
                    subHydraSmall: res.data.data[0].subHydraSmall,
                    subHydraBig: res.data.data[0].subHydraBig,
                    subGenerator: res.data.data[0].subGenerator,
                    subWeldingMachine: res.data.data[0].subWeldingMachine,
                    subGrindingMachine: res.data.data[0].subGrindingMachine,
                    subClamp: res.data.data[0].subClamp,
                    subRollersSmall: res.data.data[0].subRollersSmall,
                    subRollersBig: res.data.data[0].subRollersBig,
                    subCradels: res.data.data[0].subCradels,
                    subDrumStands: res.data.data[0].subDrumStands,
                    subWinchMachine: res.data.data[0].subWinchMachine,
                    submanPower: res.data.data[0].submanPower,
                });

                setlastUpdatedEmployeeName(res.data.data[0].lastUpdatedEmployeeName)
                setlastUpdatedEmployeeID(res.data.data[0].lastUpdatedEmployeeID)
                setlastUpdatedDate(res.data.data[0].lastUpdatedDate)
                // setAllDeduction(res.data.data[0].deduction)
                setManualOurScopeEntries(res.data.data[0].ManualOurScopeEntries)
                setManualOurScopeEntriesFromAPI(res.data.data[0].ManualOurScopeEntries)
                setQualified(res.data.data[0].Qualified)
                setEmdFile(res.data.data[0].emdFile)
                setSdFile(res.data.data[0].sdFile)
                setOtherFile(res.data.data[0].otherFile)
            } else {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }, [])


    const fetchTenderSite = async () => {
        const sendData = new FormData();
        sendData.append("SiteProjectId", SiteProjectId);
        console.log("SiteProjectId", SiteProjectId)
        try {
            const res = await axios.post(getTenderSiteProject, sendData)
            setAllTenderSite(res.data.data)
            console.log(res.data.data, "a")
        } catch (error) {
            console.log(error)
            setAllTenderSite([])
        }
    }

    useEffect(() => {
        fetchTenderSite()
    }, [])

    return (
        <Box>

            <Box py={2} px={1}>
                <IconBreadcrumbs
                    previous={'Home'}
                    current={'Site Project'}
                    link1={`/home`}
                    link2={'/home/siteproject'}
                    currentSection={'View Site Project'}

                />
            </Box>

            <Container>
                <Box py={3}>
                    <Heading title={'View Site Project'} />
                </Box>
            </Container>
            <Box p={2} sx={{ height: '90%' }} display="flex" alignItems="center" justifyContent='center'>
                <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />

                <Box py={3} sx={{ px: 7, backgroundColor: '#EDF4F4', borderRadius: '10px', mx: 2, my: 4, boxShadow: 11 }}>
                    <Grid container justifyContent='center' display='flex' alignContent='center' spacing={4} textAlign='justify' >
                        <Grid item lg={12} xl={12} md={12} xs={12} sm={12}>


                            {/* Project Code Creation */}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }} display='flex' flexDirection='column' gap={3}>
                                <Grid container>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>PROJECT CODE</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container display='flex' gap={2} justifyContent='center'>
                                    <Grid item lg={2} sm={2} xl={2} xs={2} md={2}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Client"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={currentProjectData.clientName}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={2} xl={2} xs={2} md={2}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="SUB OR OWN"
                                            variant="outlined"
                                            size='small'
                                            disabled
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={currentProjectData.projectType}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={2} xl={2} xs={2} md={2}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Month&Year"
                                            variant="outlined"
                                            size='small'
                                            disabled
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={currentProjectData.monthYear}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={2} xl={2} xs={2} md={2}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Serial Num"
                                            variant="outlined"
                                            size='small'
                                            disabled
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={currentProjectData.serialNum}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={2} xl={2} xs={2} md={2}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="State"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={currentProjectData.projectState}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={2} xl={2} xs={2} md={2}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="District"
                                            variant="outlined"
                                            size='small'
                                            disabled
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={currentProjectData.projectDistrict}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={2} xl={2} xs={2} md={2}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Area"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={currentProjectData.projectArea}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container display='flex' alignItems='center'>
                                    <Grid item lg={9} sm={12} xl={12} xs={12} md={9} justifyContent='start' >
                                        <Typography fontWeight={500} variant='subtitle1' color='#060606' ><Typography color='#616e80' variant='subtitle1' fontWeight='bold' component='span'>PROJECT CODE : </Typography> {ProjectCode} </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            {/* LAST UPDATED */}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={3} justifyContent='space-evenly'>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>Last Update</Typography>
                                    </Grid>
                                    <Grid item lg={2} sm={2} xl={2} xs={2} md={2}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Employee Name"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            value={lastUpdatedEmployeeName}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={2} xl={2} xs={2} md={2}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Employee ID"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            value={lastUpdatedEmployeeID}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={2} xl={2} xs={2} md={2}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Last Updated Date"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            value={lastUpdatedDate}
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* Tender Type */}

                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={3}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>TENDER TYPE</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={12} xl={4} xs={12} md={6}>
                                        <Autocomplete
                                            disablePortal
                                            disabled
                                            freeSolo
                                            id="combo-box-demo"
                                            value={qualified || "No"}
                                            options={['Yes', 'No']}
                                            onChange={(event, value) => setQualified(value)}
                                            renderInput={(params) => <TextField
                                                {...params} label="Qualified"
                                                sx={{ width: '100%' }}
                                                variant="outlined" size='small' color='secondary'
                                            />}
                                        />
                                    </Grid>
                                    {qualified === 'Yes' &&
                                        <>
                                            <Grid item lg={4} sm={12} xl={4} xs={12} md={6}>
                                                <Autocomplete
                                                    disablePortal
                                                    disabled
                                                    freeSolo
                                                    id="combo-box-demo"
                                                    value={selectedTenderType || ""}
                                                    options={['OPEN', 'CLOSE']}
                                                    onChange={(event, value) => setSelectedTenderType(value || "")}
                                                    renderInput={(params) => <TextField
                                                        {...params} label="Choose Tender Type"
                                                        sx={{ width: '100%' }}
                                                        variant="outlined" size='small' color='secondary'
                                                    />}
                                                />
                                            </Grid>
                                            <Grid item lg={4} sm={12} xl={4} xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    disabled
                                                    label="Tender Amount"
                                                    variant="outlined"
                                                    name='tenderSpecRemarkText'
                                                    size='small'
                                                    color='secondary'
                                                    value={tenderAmount}
                                                    onChange={(e) => setTenderAmount(e.target.value)}
                                                />
                                            </Grid>
                                        </>
                                    }

                                </Grid>
                            </Box>


                            {/* TextField 1 - TENDER SPEC /ENQUIRY SPEC*/}

                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>TENDER SPEC /ENQUIRY SPEC</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            disabled
                                            variant="outlined"
                                            name='tenderSpecRemarkText'
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={tenderSpecRemarkText}
                                            onChange={(e) => setTenderSpecRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecdocument"
                                            label="Documents"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            type="file"
                                            InputProps={{
                                                defaultValue: '',
                                                style: { color: 'transparent' },
                                                inputProps: { 'aria-label': 'File Upload' }
                                            }}
                                            onChange={(e) => setTenderSpecDocument(e.target.files[0])}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }} textAlign='center'>
                                        <Typography variant='subtitle1' color='#616e80'>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${SiteDocs}${tenderSpecDocument}`}
                                                target="_blank"
                                            >
                                                {tenderSpecDocument}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <TableContainer sx={{ border: '1px solid silver' }}>
                                    <Table>
                                        <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
                                            <TableRow sx={{ borderBottom: '1px solid silver' }}>
                                                <TableCell sx={{ color: 'white', fontWeight: 600, textAlign: 'center' }}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Box sx={{ my: 'auto' }}>
                                                            Descriptions
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                    Unit
                                                </TableCell>
                                                <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Box sx={{ my: 'auto' }}>
                                                            Amount
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                    Quantity
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allTenderSite.map((i, index) => (
                                                <TableRow key={index} sx={{ borderBottom: '1px solid silver' }}>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.descriptions}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.unit}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.amount}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>
                                                        {i.siteProjectTenders.map((tender) => tender.qty).join(', ')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                            {/* TextField 2 - OPENING DATE*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>OPENING DATE</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            disabled
                                            label="Remark Text"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={openingDateRemarkText}
                                            onChange={(e) => setOpeningDateRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Opening Date"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            type='date'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={openingDate}
                                            onChange={(e) => setOpeningDate(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextField 3 - CLOSING DATE*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>CLOSING DATE</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={closingDateRemarkText}
                                            onChange={(e) => setClosingDateRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Closing Date"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            type='date'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={closingDate}
                                            onChange={(e) => setClosingDate(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* Newly Added Field - BQR */}

                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>BQR</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={BQRRemarkText}
                                            onChange={(e) => setBQRRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="BQQdocument"
                                            label="Documents"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            type="file"
                                            InputProps={{
                                                defaultValue: '',
                                                style: { color: 'transparent' },
                                                inputProps: { 'aria-label': 'File Upload' }
                                            }}
                                            onChange={(e) => setBQRDocument(e.target.files[0])}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }} textAlign='center'>
                                        <Typography variant='subtitle1' color='#616e80'>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${SiteDocs}${BQRDocument}`}
                                                target="_blank"
                                            >
                                                {BQRDocument}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>


                            {/* TextField 4 -EMD/EMD EXEMPTION*/}

                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>EMD/EMD EXEMPTION</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={EMDExemptionRemarkText}
                                            onChange={(e) => setEMDExemptionRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="EMDExemptiondocument"
                                            label="Documents"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            type="file"
                                            InputProps={{
                                                defaultValue: '',
                                                style: { color: 'transparent' },
                                                inputProps: { 'aria-label': 'File Upload' }
                                            }}
                                            onChange={(e) => setEMDExemptionDocument(e.target.files[0])}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }} textAlign='center'>
                                        <Typography variant='subtitle1' color='#616e80'>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${SiteDocs}${EMDExemptionDocument}`}
                                                target="_blank"
                                            >
                                                {EMDExemptionDocument}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>


                            {/* TextField 5 - TENDER LOST*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>TENDER LOST</Typography>
                                    </Grid>
                                    <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 1 }}>
                                        <TextField
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={TenderLostRemarkText}
                                            onChange={(e) => setTenderLostRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 1 }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            disabled
                                            value={SelectedTenderLost}
                                            options={['Finance', 'Remarks']}
                                            onChange={(event, value) => setSelectedTenderLost(value)}
                                            renderInput={(params) => <TextField
                                                {...params} label="Tender Lost"
                                                sx={{ width: 230 }}
                                                variant="outlined" size='small' color='secondary'
                                            />}
                                        />
                                    </Grid>
                                    <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 1 }}>
                                        <TextField
                                            id="TenderSpecText"
                                            label="Competitor Name"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={TenderLostCompetitorName}
                                            onChange={(e) => setTenderLostCompetitorName(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 1 }}>
                                        <TextField
                                            id="TenderSpecText"
                                            label="Quote Amount"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={TenderLostAmount}
                                            onChange={(e) => setTenderLostAmount(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextFiled 6 -SD DATE WITH AMOUNT/ BANK GUARANTEE/ BILL DEDUCTION */}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>SD DATE WITH AMOUNT/ BANK GUARANTEE/ BILL DEDUCTION</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={BillDeductionRemarkText}
                                            onChange={(e) => setBillDeductionRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="BillDeductiondocument"
                                            label="Documents"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            type="file"
                                            InputProps={{
                                                defaultValue: '',
                                                style: { color: 'transparent' },
                                                inputProps: { 'aria-label': 'File Upload' }
                                            }}
                                            onChange={(e) => setBillDeductionDocument(e.target.files[0])}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }} textAlign='center'>
                                        <Typography variant='subtitle1' color='#616e80'>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${SiteDocs}${BillDeductionDocument}`}
                                                target="_blank"
                                            >
                                                {BillDeductionDocument}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>


                            {/* TextFiled 7 -BOQ */}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>BOQ</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={BOQRemarkText}
                                            onChange={(e) => setBOQRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="BOQdocument"
                                            label="Documents"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            type="file"
                                            InputProps={{
                                                defaultValue: '',
                                                style: { color: 'transparent' },
                                                inputProps: { 'aria-label': 'File Upload' }
                                            }}
                                            onChange={(e) => setBOQDocument(e.target.files[0])}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }} textAlign='center'>
                                        <Typography variant='subtitle1' color='#616e80'>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${SiteDocs}${BOQDocument}`}
                                                target="_blank"
                                            >
                                                {BOQDocument}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextFiled 8 -PROJECT DETAILS */}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>PROJECT DETAILS</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={ProjectDetailsRemarkText}
                                            onChange={(e) => setProjectDetailsRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="projectDetailsdocument"
                                            label="Documents"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            disabled
                                            type="file"
                                            InputProps={{
                                                defaultValue: '',
                                                style: { color: 'transparent' },
                                                inputProps: { 'aria-label': 'File Upload' }
                                            }}
                                            onChange={(e) => setProjectDetailsDocument(e.target.files[0])}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }} textAlign='center'>
                                        <Typography variant='subtitle1' color='#616e80'>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${SiteDocs}${ProjectDetailsDocument}`}
                                                target="_blank"
                                            >
                                                {ProjectDetailsDocument}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextFiled 10 -WORK ORDER/LOA*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>WORK ORDER/LOA</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={LOARemarkText}
                                            onChange={(e) => setLOARemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="LOAdocument"
                                            label="Documents"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            type="file"
                                            InputProps={{
                                                defaultValue: '',
                                                style: { color: 'transparent' },
                                                inputProps: { 'aria-label': 'File Upload' }
                                            }}
                                            onChange={(e) => setLOADocument(e.target.files[0])}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }} textAlign='center'>
                                        <Typography variant='subtitle1' color='#616e80'>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${SiteDocs}${LOADocument}`}
                                                target="_blank"
                                            >
                                                {LOADocument}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextField 11 -PROFILE DRAWING*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>PROFILE DRAWING</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={ProfileDrawingRemarkText}
                                            onChange={(e) => setProfileDrawingRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Profile Drawing"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={ProfileDrawing}
                                            onChange={(e) => setProfileDrawing(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextFiled 12 -AGREEMENT*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>AGREEMENT</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={AgreementRemarkText}
                                            onChange={(e) => setAgreementRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="Agreementdocument"
                                            label="Documents"
                                            variant="outlined"
                                            size='small'
                                            disabled
                                            color='secondary'
                                            type="file"
                                            InputProps={{
                                                defaultValue: '',
                                                style: { color: 'transparent' },
                                                inputProps: { 'aria-label': 'File Upload' }
                                            }}
                                            onChange={(e) => setAgreementDocument(e.target.files[0])}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }} textAlign='center'>
                                        <Typography variant='subtitle1' color='#616e80'>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${SiteDocs}${AgreementDocument}`}
                                                target="_blank"
                                            >
                                                {AgreementDocument}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextFiled 13 -SUPPLY OF MATERIALS WITH BUDGET*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>SUPPLY OF MATERIALS WITH BUDGET</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={SupplyofMaterialRemarkText}
                                            onChange={(e) => setSupplyofMaterialRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="SupplyofMaterialdocument"
                                            label="Documents"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            disabled
                                            type="file"
                                            InputProps={{
                                                defaultValue: '',
                                                style: { color: 'transparent' },
                                                inputProps: { 'aria-label': 'File Upload' }
                                            }}
                                            onChange={(e) => setSupplyofMaterialDocument(e.target.files[0])}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 2 }} textAlign='center'>
                                        <Typography variant='subtitle1' color='#616e80'>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${SiteDocs}${SupplyofMaterialDocument}`}
                                                target="_blank"
                                            >
                                                {SupplyofMaterialDocument}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* Newly Added Field - Sub YES/NO */}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>SUB CONTRACT</Typography>
                                    </Grid>
                                    <Grid item lg={2} sm={2} xl={2} xs={2} md={2}>
                                        <Autocomplete
                                            disablePortal
                                            disabled
                                            id="combo-box-demo"
                                            options={['Yes', 'No']}
                                            value={SubContract || 'No'}
                                            onChange={(event, value) => setSubContract(value)}
                                            renderInput={(params) => <TextField
                                                {...params} label="Sub Contract"
                                                sx={{ width: '100%' }}
                                                variant="outlined" size='small' color='secondary'
                                            />}
                                        />
                                    </Grid>
                                    {SubContract === 'Yes' &&
                                        <Grid item lg={12} sm={12} xl={12} xs={12} md={12} sx={{ py: 1 }} display='flex' flexDirection='row' gap={2}>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                {Object.keys(subCheckboxState).map((key, value) => (
                                                    <div key={key} style={{ flexBasis: '14%', padding: '5px' }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={subCheckboxState[key] === key}
                                                                    name={key}
                                                                />
                                                            }
                                                            label={key}
                                                        />
                                                    </div>
                                                ))}
                                            </div>

                                        </Grid>
                                    }
                                </Grid>
                            </Box>



                            {/* TextField 14 -SUB CONTRACT NAME*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>SUB CONTRACT NAME</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={SubContractNameRemarkText}
                                            onChange={(e) => setSubContractNameRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Sub Contract Name"
                                            variant="outlined"
                                            size='small'
                                            disabled
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={SubContractName}
                                            onChange={(e) => setSubContractName(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextField 15 - SUB CONTRACT ORDER*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>SUB CONTRACT ORDER</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={SubContractOrderRemarkText}
                                            onChange={(e) => setSubContractOrderRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Sub Contract Order"
                                            variant="outlined"
                                            size='small'
                                            disabled
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={SubContractOrder}
                                            onChange={(e) => setSubContractOrder(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextField 16 -SCOPE OF WORK IN CASE OF SUBCONTRACT*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>SCOPE OF WORK IN CASE OF SUBCONTRACT</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={ScopeOfWorkRemarkText}
                                            onChange={(e) => setScopeOfWorkRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Scope of work When Sub Contract"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={ScopeOfWork}
                                            onChange={(e) => setScopeOfWork(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextField 17 -OUR SCOPE OF WORK*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>OUR SCOPE OF WORK</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={OurScopeOfWorkRemarkText}

                                        />
                                    </Grid>

                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12} sx={{ py: 1 }} display='flex' flexDirection='row' gap={2}>
                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                            {Object.keys(checkboxState).map((key) => (
                                                <div key={key} style={{ flexBasis: '14%', padding: '5px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={checkboxState[key]}
                                                                name={key}
                                                            />
                                                        }
                                                        label={key}
                                                    />
                                                </div>
                                            ))}
                                        </div>

                                    </Grid>
                                    <Grid item lg={12} sm={6} xl={12} xs={6} md={6} sx={{ py: 1 }}>
                                        <Typography variant='subtitle1'>Other : {ManualOurScopeEntriesFromAPI}</Typography>
                                    </Grid>

                                </Grid>
                            </Box>

                            {/* TextField 18 -OWN MACHINE OR SUBCONTRACT*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>OWN MACHINE OR SUBCONTRACT</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            size='small'
                                            disabled
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={OwnMachineOrSubRemarkText}

                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            disabled
                                            options={['Own Machine', 'Sub contract']}
                                            value={SelectedOwnMachineOrSub}

                                            renderInput={(params) => <TextField
                                                {...params} label="Own Machine or Sub Contract"
                                                sx={{ width: '100%' }}
                                                variant="outlined"
                                                size='small'
                                                color='secondary'
                                            />}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextFiled 19 -BILLING DETAILS*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2} justifyContent='center'>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>BILLING DETAILS</Typography>
                                    </Grid>
                                    <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={BillingDetailsRemarkText}

                                        />
                                    </Grid>
                                    <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="BillingDetailsdocument"
                                            label="Documents"
                                            variant="outlined"
                                            size='small'
                                            disabled
                                            color='secondary'
                                            type="file"
                                            InputProps={{
                                                defaultValue: '',
                                                style: { color: 'transparent' },
                                                inputProps: { 'aria-label': 'File Upload' }
                                            }}

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }} textAlign='center'>
                                        <Typography variant='subtitle1' color='#616e80'>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${SiteDocs}${BillingDetailsDocument}`}
                                                target="_blank"
                                            >
                                                {BillingDetailsDocument}
                                            </a>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
                                        <SiteProjectBillChatDialog />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextField 20 -FLOW OF BILLS*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>FLOW OF BILLS</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={FlowOfBillsRemarkText}
                                            onChange={(e) => setFlowOfBillsRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={[]}
                                            disabled
                                            value={SelectedFlowOfBills}
                                            onChange={(event, value) => setSelectedFlowOfBills(value)}
                                            renderInput={(params) => <TextField
                                                {...params} label="Flow Of Bills"
                                                sx={{ width: '100%' }}
                                                variant="outlined" size='small' color='secondary'
                                            />}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextField 21 -PAYMENT RECEIVED*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 0px 1px' }}>
                                <Grid container spacing={2} justifyContent='space-evenly'>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>PAYMENT RECEIVED</Typography>
                                    </Grid>
                                    <Grid item lg={2} sm={3} xl={3} xs={3} md={3} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            disabled
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={PaymentsRemarkText}
                                            onChange={(e) => setPaymentsRemarkText(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={3} xl={3} xs={3} md={3} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Payment Received"
                                            variant="outlined"
                                            disabled
                                            size='small'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={PaymentsReceived}
                                            onChange={(e) => setPaymentsReceived(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={3} xl={3} xs={3} md={3} sx={{ py: 1 }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={[]}
                                            disabled
                                            value={SelectedDeductionType}
                                            onChange={(event, value) => setSelectedDeductionType(value)}
                                            renderInput={(params) => <TextField
                                                {...params} label="Deduction Type"
                                                sx={{ width: '100%' }}
                                                variant="outlined" size='small' color='secondary'
                                            />}
                                        />
                                    </Grid>
                                    <Grid item lg={2} sm={3} xl={3} xs={3} md={3} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Deducted Amount"
                                            variant="outlined"
                                            size='small'
                                            disabled
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={deductedAmount}
                                            onChange={(e) => setDeductedAmount(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextField 22 -EMD  RETENTION RECEIVABLE DATE*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 1px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>EMD RETENTION RECEIVABLE DATE</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={EMDRetentionDateRemarkText}

                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={EMDRetentionAmount}

                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="EMD /SD Rentention Receivable Date"
                                            variant="outlined"
                                            size='small'
                                            type='date'
                                            color='secondary'
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={EMDRetentionDate}

                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={6} xl={4} xs={12} md={6} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="EMD Documents"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            type="file"
                                            InputProps={{
                                                defaultValue: '',
                                                style: { color: 'transparent' },
                                                inputProps: { 'aria-label': 'File Upload' }
                                            }}
                                            onChange={(e) => setEmdFile(e.target.files[0])}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={6} xl={4} xs={12} md={6} sx={{ py: 2, textAlign: 'center' }}>
                                        <Typography variant='subtitle1' color='#616e80'>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${SiteDocs}${emdFile}`}
                                                target="_blank"
                                            >
                                                {emdFile}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextField 23 -SD  RETENTION RECEIVABLE DATE*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 1px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>SD RETENTION RECEIVABLE DATE</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={SDRetentionDateRemarkText}

                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={SDRetentionAmount}

                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="EMD /SD Rentention Receivable Date"
                                            variant="outlined"
                                            size='small'
                                            type='date'
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={SDRetentionDate}
                                            disabled

                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={6} xl={4} xs={12} md={6} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="SD Documents"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            type="file"
                                            InputProps={{
                                                defaultValue: '',
                                                style: { color: 'transparent' },
                                                inputProps: { 'aria-label': 'File Upload' }
                                            }}
                                            onChange={(e) => setSdFile(e.target.files[0])}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={6} xl={4} xs={12} md={6} sx={{ py: 2, textAlign: 'center' }}>
                                        <Typography variant='subtitle1' color='#616e80'>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${SiteDocs}${sdFile}`}
                                                target="_blank"
                                            >
                                                {sdFile}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* TextField 24 -OTHER  RETENTION RECEIVABLE DATE*/}
                            <Box p={2} sx={{ border: '1px solid #d2cbcb', borderWidth: '1px 1px 1px 1px' }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Typography variant='subtitle1' fontWeight={600}>OTHER RETENTION RECEIVABLE DATE</Typography>
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={OtherRetentionDateRemarkText}

                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="Remark Text"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={OtherRetentionAmount}

                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={4} xl={4} xs={4} md={4} sx={{ py: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="TenderSpecText"
                                            label="EMD /SD Rentention Receivable Date"
                                            variant="outlined"
                                            size='small'
                                            type='date'
                                            disabled
                                            color='secondary'
                                            InputLabelProps={{ shrink: true }}
                                            value={OtherRetentionDate}

                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={6} xl={4} xs={12} md={6} sx={{ py: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="otherDocument"
                                            label="Others Documents"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            type="file"
                                            InputProps={{
                                                defaultValue: '',
                                                style: { color: 'transparent' },
                                                inputProps: { 'aria-label': 'File Upload' }
                                            }}
                                            onChange={(e) => setOtherFile(e.target.files[0])}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} sm={6} xl={4} xs={12} md={6} sx={{ py: 2, textAlign: 'center' }}>
                                        <Typography variant='subtitle1' color='#616e80'>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${SiteDocs}${otherFile}`}
                                                target="_blank"
                                            >
                                                {otherFile}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box p={2} mb={2}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                                        <Button fullWidth
                                            onClick={() => navigate(-1)}
                                            variant='contained'
                                            color='error'>
                                            <Typography
                                                sx={{ letterSpacing: 2, fontWeight: 'bold', color: '#ffff' }}>
                                                Go Back
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box >
            </Box >
        </Box>
    )
}
