import React, { useState } from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container, Autocomplete, Snackbar, Alert, Grow} from "@mui/material";
import Heading from '../Components/Heading';
import Breadcrumbs from '../Components/Breadcrumbs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FactoryProjectAssignTab from '../Components/FactoryProjectComps/FactoryProjectAssignTab';
import AssignedFactoryProjectsTab from '../Components/FactoryProjectComps/AssignedFactoryProjectsTab';
import IconBreadcrumbs from '../Components/Breadcrumbs';
import StoreFactoryProject from '../Components/FactoryProjectComps/StoreFactoryProject';
import PurchaseTable from '../Components/FactoryProjectComps/PurchaseTable';
import MaterialInwards from '../Components/FactoryProjectComps/MaterialInwards';
import MaterialIssued from '../Components/FactoryProjectComps/MaterialIssued';
import MaterialIssueProjectList from '../Components/FactoryProjectComps/MaterialIssueProjectList';
import CommercialFromSalesPage from '../Components/FactoryProjectComps/CommercialFromSales';
import TechnicalFromCommercialPage from '../Components/FactoryProjectComps/TechnicalFromCommercial';
import CommercialFromTechnicalPage from '../Components/FactoryProjectComps/CommercialFromTechnical';
import ProjectManagerFromCommercialPage from '../Components/FactoryProjectComps/ProjectManagerFromCommercial';
import CommercialFromProjectManagerPage from '../Components/FactoryProjectComps/CommercialFromProjectManager';

  export default function FactoryProjectsPage() {
  const [value, setValue] = useState("1");
  const [isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen] = useState(false);
  const [Notification, setNotification] = useState("");

  // Tab functionality
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const handleCloseAddedCartSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsAddedCartSnackbarOpen(false);
  };

  function GrowTransition(props) {
    return <Grow {...props} direction="up" />;
  }


  const role = JSON.parse(localStorage.getItem('role'));

  const roles = ['Project Manager', 'Store', 'Purchase',];

  const access = JSON.parse(localStorage.getItem('access'));
  const { AssingnedProjectFactory, ProjectCreation, CommercialFromSales, TechnicalFromCommercial,  MaterialInwards: MaterialInward , MaterialIssued , Store , Purchase, CommercialFromTechnical, ProjectManagerFromCommercial, CommercialFromProjectManager } = access;

  return (
  <Box>

      <Snackbar
        open={isAddedCartSnackbarOpen}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={GrowTransition}
        onClose={handleCloseAddedCartSnackbar}>
        <Alert onClose={handleCloseAddedCartSnackbar} severity='success' variant="filled" sx={{ width: '100%' }}>
          {Notification}
        </Alert>
      </Snackbar>

    <Box py={2} px={1}>
    <IconBreadcrumbs
    previous={'Home'}
    current={'Factory Project'}
    link1={`/home`}
    link2={'/home/project'}
    currentSection={ value !== "1" ? 'Factory Projects Creation' : 'Assigned Factory Projects' }

    />
    </Box>

    <Container>
      <Box py={3}>
      <Heading  title={ value !== "1" ? 'Factory Projects Creation' : 'Assigned Factory Projects' }/>
      </Box>
    </Container>


      <Container>
        <Box sx={{ p: 3 }}>
          <TabContext value={value}>
            <TabList variant="scrollable" scrollButtons="auto" onChange={handleChange} aria-label="tabs">
            {
                AssingnedProjectFactory === 'true' &&
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Assigned Projects" value="1" />
            }

            {
                ProjectCreation === 'true' &&

            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Project Creation" value="2" />
            }
            {
                CommercialFromSales !== 'true' &&

            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Commercial From Sales" value="3" />
            }
            {
                TechnicalFromCommercial !== 'true' &&

            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Technical From Commercial" value="4" />
            }
            {
                MaterialInward === 'true' &&
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Material Inwards" value="5" />
            }
            {
                MaterialIssued === 'true' &&
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Material Issued" value="6" />
            }
            {
                Store === 'true' &&
            <Tab key="store" sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Store" value="7" />
            }
            {
                Purchase === 'true' &&
            <Tab key="purchase" sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Purchase" value="8" />
            }
            {
                CommercialFromTechnical !== 'true' &&
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Commercial From Technical" value="9" />
            }
            {
                ProjectManagerFromCommercial !== 'true' &&
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="ProjectManager From Commercial" value="10" />
            }
            {
                CommercialFromProjectManager !== 'true' &&
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Commercial From ProjectManager" value="11" />
            }
            </TabList>
            {
              AssingnedProjectFactory === 'true' &&
            <TabPanel value="1">
            <Box>
            <AssignedFactoryProjectsTab />
            </Box>
            </TabPanel>
            }

            {
              ProjectCreation === 'true' &&
            <TabPanel value="2">
            <Box>
            <FactoryProjectAssignTab setValue={setValue} isAddedCartSnackbarOpen={isAddedCartSnackbarOpen} setIsAddedCartSnackbarOpen={setIsAddedCartSnackbarOpen} setNotification={setNotification} />
            </Box>
            </TabPanel>
            }
            {
              CommercialFromSales !== 'true' &&
            <TabPanel value="3">
            <Box>
            <CommercialFromSalesPage />
            </Box>
            </TabPanel>
            }
            {
              TechnicalFromCommercial !== 'true' &&
            <TabPanel value="4">
            <Box>
            <TechnicalFromCommercialPage />
            </Box>
            </TabPanel>
            }
            {
              MaterialInward === 'true' &&
            <TabPanel value="5">
            <Box>
            <MaterialInwards />
            </Box>
            </TabPanel>
            }
            {
              MaterialIssued === 'true' &&
            <TabPanel value="6">
            <Box>
            <MaterialIssueProjectList />
            </Box>
            </TabPanel>
            }
            {
              Store === 'true' &&
            <TabPanel value="7">
            <Box>
            <StoreFactoryProject setValue={setValue} />
            </Box>
            </TabPanel>
            }
            {
              Purchase === 'true' &&
            <TabPanel value="8">
            <Box>
            <PurchaseTable setValue={setValue} />
            </Box>
            </TabPanel>
            }
            {
              CommercialFromTechnical !== 'true' &&
            <TabPanel value="9">
            <Box>
            <CommercialFromTechnicalPage />
            </Box>
            </TabPanel>
            }
            {
              ProjectManagerFromCommercial !== 'true' &&
            <TabPanel value="10">
            <Box>
            <ProjectManagerFromCommercialPage />
            </Box>
            </TabPanel>
            }
            {
              CommercialFromProjectManager !== 'true' &&
            <TabPanel value="11">
            <Box>
            <CommercialFromProjectManagerPage />
            </Box>
            </TabPanel>
            }

          </TabContext>
        </Box>
      </Container>

  </Box>
  )
}
