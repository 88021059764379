import React,{ useState } from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container, Divider} from "@mui/material";
import Heading from '../Components/Heading';
import Breadcrumbs from '../Components/Breadcrumbs';
import { AnnouncementView } from '../Variables/Variables';
import { useNavigate } from 'react-router-dom';

export default function AnnouncementPage() {

    const userName = JSON.parse(localStorage.getItem('user'));
    const role = JSON.parse(localStorage.getItem('role'));

      const [value, setValue] = useState("1");

  // Tab functionality
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const navigate = useNavigate();

  const handleClick = (path) =>{
   navigate(`${path}`)
  }

  const access = JSON.parse(localStorage.getItem('access'));
  const { CreateEvent : Create, SeeAllEvents } = access;


  return (
    <Box>

    <Box py={2} px={1}>
    <Breadcrumbs 
    previous={'Home'}
    current={'Announcement'}
    link1={`/home`}
    link2={'/home/announcement'}
    />
    </Box>

    <Container>
      <Box py={3}>
      <Heading  title={'Announcement'}/>
      </Box>
    </Container>



 <Container>

 <Grid container>
        <Grid item sm={12} xs={12} md={12} lg={12} xl={12} display='flex' justifyContent='center' py={5}>
        <Grid container spacing={5}>
        {
                   AnnouncementView.map((i, index)=>{
                      if (access[i.access] === 'true') {
                        return (
                    <Grid item sm={4} xs={4} md={4} lg={4} xl={4} display='flex' justifyContent='center' alignContent='center' >
                    <Box onClick={()=>{handleClick(i.path)}}>
                    <Box component='div' className="cards" p={3} justifyContent='space-between' display='flex' flexDirection='column' >

                    <img src={i.Icon} alt='project-icon' />

                    <Box textAlign='center'>
                    <Typography fontWeight={600}>{i.name}</Typography>
                    </Box>
                     </Box>
                     </Box>
                     </Grid>
                        );
                      } else {
                        return null;
                      }
                    })
                  }
         </Grid>
         
        </Grid>
        </Grid>
    </Container>

  </Box>
  )
}
