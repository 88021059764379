import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { bankimages, methodPost, updateBankTrancationDetails } from '../../../API_Service/API_Links';


export default function ImageUpdateDialog({ setDialogData , dialogData ,   setopenUpdateDialog , openUpdateDialog ,
    setOpen,
    setMessage,getAllSummary,
    setStatus,
    setColor}) {


  const handleClose = () => {
    setopenUpdateDialog(false);
    setDialogData('');
    getAllSummary();
  };

  const [AddedProof, setAddedProof] = useState('');

  const update = () =>{
    const sendData = new FormData()
    sendData.append('Id', dialogData?.Id)
   sendData.append('Images', AddedProof)
   axios({
       method: methodPost,
       url: updateBankTrancationDetails,
       data:sendData
     })
       .then((res) => {
         if (res.data.error) {
           setMessage(res.data.message);
           setOpen(true)
           setStatus(false)
           setColor(false)
         } else {
           setMessage(res.data.message);
           setStatus(true);
           setColor(true);
           setOpen(true);
           handleClose();
         }
       })
       .catch((err) => {
         alert("Oops something went wrong " + err);
       });
  }

  return (
    <div>
      <Dialog
        open={openUpdateDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle>Update Transaction</DialogTitle>
        <DialogContent>
          <Stack p={3} spacing={2}>
          <img src={`${bankimages}${dialogData?.Images}`} alt='alt' />
          <Divider />

          <Typography variant='h6' fontWeight={600}>Upload New</Typography>
          <TextField
            fullWidth
            variant="outlined"
            size='small'
            id='proof'
            color='secondary'
            type="file"
            required
            sx={{ width: 250 }}
            onChange={(e) => setAddedProof(e.target.files[0]) }
            InputLabelProps={{
            shrink: true,
            }}

            />
          </Stack>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={update}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}