import React,{ useState } from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container, Divider} from "@mui/material";
import Heading from '../../../Components/Heading';
import Breadcrumbs from '../../../Components/Breadcrumbs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useNavigate } from 'react-router-dom';
import AddBank from './AddBank';
import BankList from './BankList';
import BalanceCheck from './BalanceCheck';
import Transaction from './Transaction';
import Transfer from './Transfer';
import Debit from './Debit';
import BankBalanaceSummary from './BankBalanaceSummary';


export default function BankTab() {



      const [value, setValue] = useState("1");

  // Tab functionality
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const navigate = useNavigate();

  const handleClick = (path) =>{
   navigate(`${path}`)
  }

  const access = JSON.parse(localStorage.getItem('access'));
  const { CreateEvent : Create, SeeAllEvents } = access;


  return (
    <Box>

    <Box py={2} px={1}>
    <Breadcrumbs 
    previous={'Home'}
    current={'Finance'}
    link1={`/home`}
    link2={'/home/finance'}
    currentSection={'Bank A/C'}
    curr
    />
    </Box>

    <Container>
      <Box py={3}>
      <Heading  title={'Bank Accounts'}/>
      </Box>
    </Container>



 <Container>

    <Box >
    <TabContext value={value}>
      <Box>
          <TabList
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
          onChange={handleChange} aria-label="tabs">
                        {
            SeeAllEvents === 'true' && 
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginLeft: 5 }} label="Transfer" value="1" />
            }
            {
            SeeAllEvents === 'true' && 
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginLeft: 5 }} label="New Bank Account" value="2" />
            }
                        {
            SeeAllEvents === 'true' && 
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginLeft: 5 }} label="All Bank Accounts" value="3" />
            }
           {
            SeeAllEvents === 'true' && 
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginLeft: 5 }} label="Add Bank Balance" value="4" />
            }
              {
            SeeAllEvents === 'true' && 
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginLeft: 5 }} label="Bank Balance Summary" value="5" />
            }
           {
            SeeAllEvents === 'true' && 
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginLeft: 5 }} label="Credit" value="6" />
            }
            {
            SeeAllEvents === 'true' && 
            <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginLeft: 5 }} label="Debit" value="7" />
            }
          </TabList>
          </Box>
          {
          SeeAllEvents === 'true' &&
          <TabPanel value="1">
          <Box>
          <Transfer />
          </Box>
          </TabPanel>
          }
          {
          SeeAllEvents === 'true' &&
          <TabPanel value="2">
          <Box>
          <AddBank />
          </Box>
          </TabPanel>
          }
                    {
          SeeAllEvents === 'true' &&
          <TabPanel value="3">
          <Box>
          <BankList />
          </Box>
          </TabPanel>
          }

{
          SeeAllEvents === 'true' &&
          <TabPanel value="4">
          <Box>
          <BalanceCheck />
          </Box>
          </TabPanel>
          }

{
          SeeAllEvents === 'true' &&
          <TabPanel value="5">
          <Box>
          <BankBalanaceSummary />
          </Box>
          </TabPanel>
          }

{
          SeeAllEvents === 'true' &&
          <TabPanel value="6">
          <Box>
          <Transaction />
          </Box>
          </TabPanel>
          }

{
          SeeAllEvents === 'true' &&
          <TabPanel value="7">
          <Box>
          <Debit />
          </Box>
          </TabPanel>
          }
        </TabContext>
      </Box>
    </Container>

  </Box>
  )
}
