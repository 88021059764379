import React, {useState } from 'react'
import {Box,Button,Grid,TextField,Typography, Container, Autocomplete, FormControlLabel, Switch} from "@mui/material";
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import {get_factory_employees, get_factory_project, get_factory_project_items, get_size, methodGet, methodPost, update_factory_project } from "../../API_Service/API_Links";
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { appendData } from '../../Variables/Variables';
import SnackBar from '../SnackBar';
import { useLocation, useNavigate } from 'react-router-dom';
import IconBreadcrumbs from '../Breadcrumbs';
import Heading from '../Heading';
import { useEffect } from 'react';
import ProjectManagerChip from '../ChipInputTable/ProjectManagerChip';
import TeamChipInput from '../ChipInputTable/TeamChipInput';

export default function FactoryProjectEditForm() {
    const { formState: { errors }, handleSubmit } = useForm();
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [CustomerName, setCustomerName] = useState("");
    const [MobileNum, setMobileNum] = useState("");
    const [Email, setEmail] = useState("");
    const [CompanyName, setCompanyName] = useState("");
    const [CompanyAddress, setCompanyAddress] = useState("");
    const [GstNum, setGstNum] = useState("");
    const [Services, setServices] = useState("");
    const [ProjectName, setProjectName] = useState("");
    const [ProjectDescp, setProjectDescp] = useState("");
    const [ExpectDate, setExpectDate] = useState("");
    const [ProjectBudget, setProjectBudget] = useState("");
    const [projectType, setProjectType] = useState([]);
    const [projectCode, setProjectCode] = useState("");
    const[deadLine, setDeadLine] = useState('');
    const [ProjectStart, setProjectStart] = useState("");
    const [ProjectEnd, setProjectEnd] = useState("");
    const [input, setInput] = useState(false);
    const [projectdocument, setProjectDocument] = useState('');
    const [teamMember , setTeamMember] = useState([]);
    const [staffMember, setStaffMember] = useState([]);
    const [teamName , setTeamName] = useState([]);
    const [staffName, setStaffName] = useState([]);
    const [currentTeam , setCurrentTeam] = useState('');
    const navigate = useNavigate();
    const [project, setcurrentProjectData] = useState([]);
    const [dept, setDept] = useState([]);
    const [managerId, setManagerId] = useState(null);
    const [managername, setManagername] = useState(null);
    const [options, setOptions] = useState([]);
    const [sizeOptions , setSizeOptions] = useState([]);
    const [itemCodeOptions, setItemCodeOptions]= useState([]);
    const [orderConfirmedDate, setOrderConfirmedDate] = useState('');
    const [customerRequiredDate, setCustomerRequiredDate] = useState('');
    const [dispatchCommittedDate, setDispatchCommittedDate] = useState('');
    const [demand, setDemand] = useState('');
    const [rawMaterialGrade, setRawMaterialGrade] = useState('');
    const [orderValue, setOrderValue] = useState('');
    const [shipmentAddress, setShipmentAddress] = useState('');
    const [transportationScope, setTransportationScope] = useState('');
    const [itemsList, setItemsList] = useState([]);
    const [factoryProjectItemId , setFactoryProjectItemId] = useState([]);
    const [itemCode, setItemCode] = useState(null);
    const [itemDescription, setItemDescription] = useState('');
    const [quantity, setQuantity] = useState('');
    const [thread, setThread] = useState('');
    const [itemName, setItemName] = useState('');
    const [equipmentDetail, setEquipmentDetail] = useState('');
    const [itemValue, setItemValue] = useState('');
    const [size, setSize] = useState(null);

    const RoleId = '7';
    const [fileTypeError, setFileTypeError] = useState('');

    const allowedExtensions = ['pdf', 'ppt', 'png', 'jpeg', 'jpg', 'gif', 'txt', 'xls'];


    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          setProjectDocument(selectedFile);
          setFileTypeError('');
        } else {
          setProjectDocument(null);
          setFileTypeError('Only F, PPT, PNG, JPEG, JPG, GIF, TEXT, and XLS files are allowed.');
        }
      }
    };

    const location = useLocation();

    const projectId = location.state.id;


        // GET Current Project
           useEffect(() => {
            const serverData = new FormData()
            serverData.append('ProjectId', projectId);
            axios({
                method: 'POST',
                url: get_factory_project,
                data:serverData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
              setMessage(res.data.message)
              setcurrentProjectData(res.data.data);
              setProjectType(res.data.data.ProjectType)
              setCompanyAddress(res.data.data.CompanyAddress);
              setCompanyName(res.data.data.CompanyName);
              setCustomerName(res.data.data.CustomerName);
              setEmail(res.data.data.Email);
              setExpectDate(res.data.data.ExpectDate);
              setGstNum(res.data.data.GST);
              setMobileNum(res.data.data.MobileNum);
              setProjectCode(res.data.data.ProjectCode);
              setProjectBudget(res.data.data.ProjectBudget);
              setProjectDescp(res.data.data.ProjectDescp);
              setProjectStart(res.data.data.StartDate);
              setProjectEnd(res.data.data.EndDate);
              setServices(res.data.data.Services);
              setProjectName(res.data.data.ProjectName);
              setManagername(res.data.data.ManagerName)
              setManagerId(res.data.data.ManagerId)
              setCustomerRequiredDate(res.data.data.CustomerRequiredDate)
              setOrderConfirmedDate(res.data.data.OrderConfirmedDate)
              setDispatchCommittedDate(res.data.data.DispatchCommittedDate)
              setOrderValue(res.data.data.OrderValue)
              setShipmentAddress(res.data.data.ShipmentAddress)
              setTransportationScope(res.data.data.TranspotationScope)
              setItemsList(res.data.data.FactoryProjectsItems)
              setOpen(true)
              setStatus(true)
              setColor(true)
              }
            }).catch(err => {
                alert('Oops something went wrong ' + err)
            });
    }, [])

     const cancelClick = () =>{
      navigate('/home/project');
     }

     const handleRemoveItem = (itemId , itemValue) => {
      setOrderValue(orderValue - itemValue)
      const updatedItemsList = itemsList.filter((item) => item.ItemId !== itemId);
      setItemsList(updatedItemsList);
      const newObject = {"FactoryProjectId":itemId};
      setFactoryProjectItemId([...factoryProjectItemId , newObject]);
    };

     useEffect(() => {
      if (RoleId !== null) {
          const sendData = new FormData();
          sendData.append("DepartmentId", RoleId);

          axios({
              method: methodPost,
              url: get_factory_employees,
              data: sendData,
          })
              .then((res) => {
                  if (res.data.error) {
                      setMessage(res.data.message);
                      setOpen(true);
                      setStatus(false);
                      setColor(false);
                      setOptions([]);
                  } else {
                      setMessage(res.data.message);
                      setOptions(res.data.data);
                      setOpen(true);
                      setStatus(true);
                      setColor(true);
                  }
              })
              .catch((err) => {
                  alert("Oops something went wrong " + err);
                  console.log("chip2", err);
              });
      }
      else {
          setOptions([]);
      }
  }, [RoleId]);

  useEffect(() => {
    const sendData = new FormData();
    sendData.append("Id", "");
    sendData.append("ItemCode", "");
    sendData.append("ItemDescription", "");
    sendData.append("ItemName", "");
    axios({
      method: methodPost,
      url: get_factory_project_items,
      data: sendData,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
          setItemCodeOptions([]);
        } else {
          setMessage(res.data.message);
          setItemCodeOptions(res.data.data);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
}, []);

useEffect(() => {
  axios({
    method: methodGet,
    url: get_size,
    data: sendData,
  })
    .then((res) => {
      if (res.data.error) {
        setMessage(res.data.message);
        setStatus(false);
        setColor(false);
        setSizeOptions([]);
      } else {
        setMessage(res.data.message);
        setSizeOptions(res.data.data);
        setStatus(true);
        setColor(true);
      }
    })
    .catch((err) => {
      alert("Oops something went wrong " + err);
    });
}, []);

const saveItemList =() =>{
 setOrderValue(parseInt(orderValue) + itemValue);
const newItemList = {
"ItemCode":itemCode,
"ItemDescription":itemDescription,
"Quantity":quantity,
"Size":size?.Size,
"ItemName":itemName,
"ItemValue":itemValue,
"RawMaterialGrade":rawMaterialGrade
}
setItemsList([...itemsList , newItemList]);
setItemCode(null);
setItemDescription('');
setQuantity('');
setSize(null);
setItemName('');
setItemValue('');
setRawMaterialGrade('');
setInput(!input);
}

const serverData = {
  CustomerName: CustomerName,
  MobileNum: MobileNum,
  Email: Email,
  CompanyName: CompanyName,
  CompanyAddress: CompanyAddress,
  GstNum: GstNum,
  Services: Services,
  ProjectName: ProjectName,
  ProjectType: projectType,
  ProjectCode: projectCode,
  ProjectDescp: ProjectDescp,
  ProjectBudget: ProjectBudget,
  ProjectDoc: projectdocument,
  EmployeeId: managerId,
  DepartmentId: RoleId,
  StartDate: ProjectStart,
  EndDate: ProjectEnd,
  ProjectId: projectId,
  OrderConfirmedDate:orderConfirmedDate,
  CustomerRequiredDate:customerRequiredDate,
  DispatchCommittedDate:dispatchCommittedDate,
  OrderValue:orderValue,
  ShipmentAddress:shipmentAddress,
  TranspotationScope:transportationScope,
  itemsList:JSON.stringify(itemsList),
  FactoryProjectId:JSON.stringify(factoryProjectItemId),
}
const sendData = appendData(serverData);
const onSubmit = () => {
    if (!navigator.onLine) {
        setMessage('Your internet is in Offline')
        setOpen(true)
        setStatus(false)
        setColor(false)
    }
    else{
        axios({
          method: methodPost,
          url: update_factory_project,
          data: sendData,
        })
          .then((res) => {
            if (res.data.error) {
              setMessage(res.data.message);
              setOpen(true);
              setStatus(false);
              setColor(false);
            }
            else if (res.data.warning) {
              setMessage(res.data.message);
              setOpen(true);
              setStatus(false);
              setColor(false);
            }
            else
            {
               setMessage(res.data.message);
              setOpen(true);
              setStatus(true);
              setColor(true);
              setCompanyAddress("");
              setCompanyName("");
              setCustomerName("");
              setDeadLine("");
              setEmail("");
              setExpectDate("");
              setGstNum("");
              setMessage("");
              setMobileNum("");
              setProjectCode("");
              setProjectBudget("");
              setProjectDescp("");
              setDeadLine("");
              setProjectStart("");
              setProjectEnd("");
              setServices("");
              setInput(true);
              setProjectName("");
              setTeamMember("");
              setStaffMember("");
              setProjectType([]);
              setFactoryProjectItemId([]);
            }
          })
          .catch((err) => {
            alert("Oops something went wrong " + err);
          }).finally(()=>{
            navigate(-1);
          })
    }
}

  return (
    <Box>
    <Box py={2} px={1}>
    <IconBreadcrumbs
    previous={'Home'}
    current={'Factory Project'}
    link1={`/home`}
    link2={'/home/project'}
    currentSection={'Edit Factory Project'}

    />
    </Box>

    <Container>
      <Box py={3}>
      <Heading title={'Update Factory Project'}/>
      </Box>
    </Container>
      <Box p={3} sx={{ height: "90%" }} display="flex" alignItems="center">
        <SnackBar
          open={open}
          message={message}
          setOpen={setOpen}
          status={status}
          color={color}
        />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              px: 5,
              backgroundColor: "#EDF4F4",
              borderRadius: "10px",
              mx: 2,
              my: 4,
              boxShadow: 11,
            }}
          >

            <Grid
              container
              justifyContent="center"
              sx={{ textAlign: "center" }}
              spacing={4}
            >
              <Grid item lg={6} xl={6}>
                <Box
                  sx={{
                    border: "1px solid black",
                    p: 4,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 5,
                  }}
                >
                  <Box sx={{ pb: 3, textAlign: "left" }}>
                    <h5>CUSTOMER DETAILS</h5>
                  </Box>
                  <Grid container justifyContent="start" spacing={2}>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="CustomerName"
                        label="Customer Name"
                        variant="outlined"
                        required
                        value={CustomerName}
                        size="small"
                        color="secondary"
                        onChange={(e) => setCustomerName(e.target.value)}
                        InputLabelProps={{shrink:true}}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="MobileNum"
                        label="Mobile number"
                        required
                        value={MobileNum}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={{
                          maxLength: 10,
                        }}
                        onChange={(e) => setMobileNum(e.target.value)}
                        InputLabelProps={{shrink:true}}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="Email"
                        label="Email id"
                        variant="outlined"
                        size="small"
                        type='email'
                        autoComplete='off'
                        value={Email}
                        color="secondary"
                        InputLabelProps={{shrink:true}}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="CompanyName"
                        label="Company Name"
                        variant="outlined"
                        value={CompanyName}
                        size="small"
                        color="secondary"
                        onChange={(e) => setCompanyName(e.target.value)}
                        InputLabelProps={{shrink:true}}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="CompanyAddress"
                        label="Company address"
                        variant="outlined"
                        value={CompanyAddress}
                        size="small"
                        color="secondary"
                        InputLabelProps={{shrink:true}}
                        onChange={(e) => setCompanyAddress(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={141}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="GstNum"
                        label="Gst"
                        variant="outlined"
                        value={GstNum}
                        size="small"
                        color="secondary"
                        InputLabelProps={{shrink:true}}
                        onChange={(e) => setGstNum(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="Services"
                        label="Service Domain"
                        variant="outlined"
                        value={Services}
                        size="small"
                        color="secondary"
                        InputLabelProps={{shrink:true}}
                        onChange={(e) => setServices(e.target.value)}
                      />
                    </Grid>

                       <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectBudget"
                        label="Approx Budget"
                        variant="outlined"
                        value={ProjectBudget}
                        size="small"
                        color="secondary"
                        InputLabelProps={{shrink:true}}
                        onChange={(e) => setProjectBudget(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {/* (project Enquiries) */}
              <Grid item lg={6} xl={6}>
                <Box
                  sx={{
                    border: "1px solid black",
                    px: 4,
                    pb: 5,
                    pt: 4,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 5,
                  }}
                >
                  <Box sx={{ pb: 2, textAlign: "left" }}>
                    <h5>PROJECT DETAILS</h5>
                  </Box>
                  <Grid container justifyContent="start" spacing={2}>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectName"
                        label="Project name"
                        variant="outlined"
                        size="small"
                        value={ProjectName}
                        color="secondary"
                        InputLabelProps={{shrink:true}}
                        onChange={(e) => setProjectName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={['Factory', 'Field']}
                        value={projectType}
                        onChange={(event, newValue) => setProjectType(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Project Type"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="ProjectCode"
                        label="Project Code"
                        variant="outlined"
                        value={projectCode}
                        size="small"
                        color="secondary"
                           InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Documents"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setProjectDocument(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 3 }}
                    >
                      <TextField
                      fullWidth
                        id="ProjectDescp"
                        rows={4}
                        label="Project Description"
                        color="secondary"
                        value={ProjectDescp}
                        variant="outlined"
                        size="small"
                        multiline
                        InputLabelProps={{shrink:true}}
                        onChange={(e) => setProjectDescp(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>


            <Grid item lg={6} xl={6}>
            <Box
              sx={{
                border: "1px solid black",
                px: 4,
                pb: 2,
                pt: 2,
                borderColor: "#d2cbcb;",
                borderRadius: "4px",
                ":hover": { boxShadow: 2 },
                mt: 2,
              }}
            >

              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
                md={12}
                sx={{ py: 1 }}
              >
                <Box sx={{ textAlign: "left" , paddingBottom:1 }}>
                  <h5>ADD TEAM MEMBERS</h5>
                </Box>
              </Grid>

              <Grid container justifyContent="space-evenly" spacing={2} minHeight={210}>
              <Grid
              item
              lg={6}
              sm={4}
              xl={4}
              xs={14}
              md={4}
              sx={{ py: 2 }}
            >

                    <TextField
                      fullWidth
                      id="ProjectCode"
                      label="Department"
                      variant="outlined"
                      value="Project Manager"
                      size="small"

                      color="secondary"
                      InputLabelProps={{
                        shrink: true,
                      }}

                    />
                    </Grid>

                    <Grid
                    item
                    lg={6}
                    sm={4}
                    xl={4}
                    xs={14}
                    md={4}
                    sx={{ py: 2 }}
                  >


         <Autocomplete
      // ... other props
      value={{ EmployeeName: managername, EmployeeId: managerId }}
      onChange={(event, newValue) => {
        setManagerId(newValue.EmployeeId);
        setManagername(newValue.EmployeeName);
      }}
      disableClearable
      options={options} // Assuming you have your options array
      getOptionLabel={(option) => `${option.EmployeeName} (${option.EmployeeId})`}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          color="secondary"
          size="small"
          label="Manager Selection"
          fullWidth
        />
      )}
    />

   </Grid>

                       <Grid
              item
              lg={6}
              sm={4}
              xl={4}
              xs={14}
              md={4}
              sx={{ py: 2 }}
            >

                     <TextField
                        fullWidth
                        id="ExpectDate"
                        label="Start Date"
                        type="date"
                        value={ProjectStart}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setProjectStart(e.target.value)}
                      />
                    </Grid>

                    <Grid
                    item
                    lg={6}
                    sm={4}
                    xl={4}
                    xs={14}
                    md={4}
                    sx={{ py: 2 }}
                  >

                      <TextField
                        fullWidth
                        id="ExpectDate"
                        label="End Date"
                        type="date"
                      value={ProjectEnd}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setProjectEnd(e.target.value)}
                      />
                    </Grid>


                    </Grid>
                    </Box>
                    </Grid>


             {/* (Order) */}
             <Grid item lg={6} xl={6}>
             <Box
               sx={{
                 border: "1px solid black",
                 px: 4,
                 pb: 2,
                 pt: 2,
                 borderColor: "#d2cbcb;",
                 borderRadius: "4px",
                 ":hover": { boxShadow: 2 },
                 mt: 2,
               }}
             >

               <Grid
                 item
                 lg={12}
                 sm={12}
                 xl={12}
                 xs={12}
                 md={12}
                 sx={{ py: 1 }}
               >
                 <Box sx={{ textAlign: "left" }}>
                   <h5>ORDER</h5>
                 </Box>
               </Grid>

               <Grid container justifyContent="space-evenly" spacing={1} minHeight={210}>

               <Grid
               item
               lg={6}
               sm={4}
               xl={4}
               xs={14}
               md={4}
               sx={{ py: 2 }}
             >
                   <TextField
                     fullWidth
                     id="Confirm"
                     label="Order Confirmed Date"
                     type="date"
                     value={orderConfirmedDate}
                     InputLabelProps={{ shrink: true }}
                     variant="outlined"
                     size="small"
                     color="secondary"
                     onChange={(e) => setOrderConfirmedDate(e.target.value)}
                   />
                 </Grid>

                 <Grid
                 item
                 lg={6}
                 sm={4}
                 xl={4}
                 xs={14}
                 md={4}
                 sx={{ py: 2 }}
               >
                 <TextField
                   fullWidth
                   id="CustomerRequire"
                   label="Customer Require Date"
                   type="date"
                   value={customerRequiredDate}
                   InputLabelProps={{ shrink: true }}
                   variant="outlined"
                   size="small"
                   color="secondary"
                   onChange={(e) => setCustomerRequiredDate(e.target.value)}
                   InputProps={{ inputProps: { min: orderConfirmedDate } }}
                 />
               </Grid>

               <Grid
               item
               lg={6}
               sm={4}
               xl={4}
               xs={14}
               md={4}
               sx={{ py: 2 }}
             >
               <TextField
                 fullWidth
                 id="Dispatch"
                 label="Dispatch Committed Date"
                 type="date"
                 value={dispatchCommittedDate}
                 InputLabelProps={{ shrink: true }}
                 variant="outlined"
                 size="small"
                 color="secondary"
                 onChange={(e) => setDispatchCommittedDate(e.target.value)}
                 InputProps={{ inputProps: { min: orderConfirmedDate } }}
               />
             </Grid>

            <Grid
            item
            lg={6}
            sm={4}
            xl={4}
            xs={14}
            md={4}
            sx={{ py: 2 }}
            >
             <TextField
             fullWidth
             id="shipmentAddress"
             label="Shipment address"
             variant="outlined"
             size="small"
             value={shipmentAddress}
             color="secondary"
             onChange={(e) => setShipmentAddress(e.target.value)}
           />
             </Grid>


             <Grid
             item
             lg={12}
             sm={12}
             xl={12}
             xs={12}
             md={12}
             sx={{ py: 2 }}
             justifyContent="start"
             display='flex'
           >
 <FormControlLabel
 control={<Switch
   value={transportationScope}
   checked={transportationScope === 'Yes' ? true : false}
 onChange={(e)=>{
   if(e.target.checked){
   setTransportationScope('Yes')
   }
   else{
     setTransportationScope('No')
   }
 }} />}
  labelPlacement="start"
   label="Transportation Scope"
    />
 </Grid>


               </Grid>
             </Box>
           </Grid>

           <Grid item lg={12} xl={12}>
           <Box
           sx={{
             border: "1px solid black",
             px: 4,
             pb: 2,
             pt: 2,
             borderColor: "#d2cbcb;",
             borderRadius: "4px",
             ":hover": { boxShadow: 2 },
             mt: 2,
           }}
         >
         <Grid
         item
         lg={12}
         sm={12}
         xl={12}
         xs={12}
         md={12}
         sx={{ py: 1 }}
       >
         <Box sx={{ textAlign: "left" }}>
           <h5>DEMAND</h5>
         </Box>
       </Grid>

       <Grid container justifyContent="space-evenly" spacing={1} minHeight={210}>

       <Grid
       item
       lg={4}
       sm={6}
       xl={4}
       xs={12}
       md={4}
       sx={{ py: 1 }}
     >
     <Autocomplete
     onChange={(event, newValue) => {
       setItemCode(newValue.ItemCode);
       setItemDescription(newValue.ItemDescription);
       setItemName(newValue.ItemName);
     }}
     disableClearable
     key={input}
     options={itemCodeOptions}
     getOptionLabel={(option) => `${option.ItemCode}-${option.ItemDescription}-${option.ItemName}`}
     renderInput={(params) => (
       <TextField
         {...params}
         variant="outlined"
         color="secondary"
         size="small"
         label="Item"
         fullWidth
       />
     )}
     />
   </Grid>

       <Grid
       item
       lg={4}
       sm={6}
       xl={4}
       xs={12}
       md={4}
       sx={{ py: 1 }}
     >
     <Autocomplete
     onChange={(event, newValue) => {
     setSize(newValue);
     }}
     disableClearable
     value={size}
     options={sizeOptions} // Assuming you have your options array
     getOptionLabel={(option) => option.Size}
     renderInput={(params) => (
       <TextField
         {...params}
         variant="outlined"
         color="secondary"
         size="small"
         label="Size"
         fullWidth
       />
     )}
     />
         </Grid>

     <Grid
     item
     lg={4}
     sm={6}
     xl={4}
     xs={12}
     md={4}
     sx={{ py: 1 }}
   >
     <TextField
     fullWidth
     id="Qty"
     label="Quantity"
     variant="outlined"
     size="small"
     value={quantity}
     color="secondary"
     onChange={(e) => {
      const newValue = e.target.value;
      if (newValue.match(/^[0-9]+$/)){
        setQuantity(parseInt(newValue));
      }
      else{
        setQuantity('');
      }
    }}
     />
     </Grid>

     <Grid
     item
     lg={4}
     sm={6}
     xl={4}
     xs={12}
     md={4}
     sx={{ py: 1 }}
   >
     <TextField
     fullWidth
     id="itemValue"
     label="Item Value"
     variant="outlined"
     size="small"
     value={itemValue}
     color="secondary"
     onChange={(e) => {
      const newValue = e.target.value;
      if (newValue.match(/^[0-9]+$/)){
        setItemValue(parseInt(newValue));
      }
      else{
       setItemValue('');
      }
    }}
     />
     </Grid>


     <Grid
     item
     lg={4}
     sm={6}
     xl={4}
     xs={12}
     md={4}
     sx={{ py: 1 }}
   >
     <TextField
     fullWidth
     id="RawGuid"
     label="Raw Material Grade"
     variant="outlined"
     size="small"
     value={rawMaterialGrade}
     color="secondary"
     onChange={(e) => setRawMaterialGrade(e.target.value)}
     />
     </Grid>


     <Grid
     item
     lg={4}
     sm={6}
     xl={4}
     xs={12}
     md={4}
     sx={{ py: 1 }}
   >
   <Button fullWidth variant='contained' onClick={saveItemList}>Save</Button>
   </Grid>

   <Grid
   item
   lg={12}
   sm={12}
   xl={12}
   xs={12}
   md={12}
   sx={{ py: 1 }}
 >
 <Grid container>
 {
   itemsList && itemsList.map((item) =>{
     return(
       <Grid item lg={4} md={4} sm={6} xs={12} textAlign='left' borderBottom='1px solid rgba(0,0,0,.1)' mt={2} display='flex' justifyContent='start' flexWrap='wrap' whiteSpace='nowrap' flexDirection='column'>
       <Typography><strong>Item:</strong> {item.ItemCode}-{item.ItemDescription}-{item.ItemName}</Typography>
       <Typography><strong>Item Value:</strong> {item.ItemValue}</Typography>
       <Typography><strong>Size:</strong> {item.Size}</Typography>
       <Typography><strong>Quantity:</strong> {item.Quantity}</Typography>
       <Typography><strong>Raw Material Grade:</strong> {item.RawMaterialGrade}</Typography>
        <Box display='flex' justifyContent='start'>
        <Button color='error' onClick={() => handleRemoveItem(item.ItemId , parseInt(item.ItemValue))}>Remove</Button>
        </Box>

       </Grid>
     )
   })
 }
 </Grid>

 <Box sx={{ textAlign: "left" }}>
 <h5>TOTAL ORDER VALUE</h5>
 </Box>

  <Grid
  item
  lg={4}
  sm={6}
  xl={4}
  xs={12}
  md={4}
  sx={{ py: 1 }}
  >
  <TextField
  fullWidth
  id="orderValue"
  label="Order Value"
  variant="outlined"
  size="small"
  value={orderValue}
  color="secondary"
  onChange={(e) => {
    const newValue = e.target.value;
    if (newValue.match(/^[0-9]+$/)){
      setOrderValue(parseInt(newValue));
    }
    else{
      setOrderValue('');
    }
  }}
  />
  </Grid>

 </Grid>



           </Grid>
           </Box>
           </Grid>
           </Grid>

            {/* {buttons}  */}
            <Grid
            container
            justifyContent="center"
            sx={{ textAlign: "center", mt: 3 }}
          >
            <Grid item lg={6} xl={6} xs={12}>
              <Grid
                container
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      fullWidth
                      variant="outlined"
                      type="submit"
                      sx={{
                        color: "white",
                        backgroundColor: "#7bc54c",
                        borderColor: "#7bc54c",
                        ":hover": {
                          borderColor: "#7bc54c",
                          color: "#000000",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Grid>

                <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={cancelClick}
                      type="cancel"
                      sx={{
                        color: "white",
                        backgroundColor: "#c62828",
                        borderColor: "#c62828",
                        ":hover": {
                          borderColor: "#c62828",
                          color: "#000000",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          </Box>
        </Form>
      </Box>
    </Box>
  );
}
