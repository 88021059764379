import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Autocomplete, Box, IconButton, Input, InputAdornment, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { add_site_comments, getAllEmployeeName, get_site_comments, methodGet, methodPost } from '../../API_Service/API_Links';
import axios from 'axios';
import { useState } from 'react';
import SnackBar from '../SnackBar';
import { useLocation } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { useEffect } from 'react';

export default function SiteProjectBillChatDialog() {

  const [inputText, setInputText] = useState('');
  const [data, setData] = useState('');
  const [getcomments, setGetComments] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const empid = localStorage.getItem('EmployeeId');
  const empname = localStorage.getItem('user');
  const location = useLocation();
  const SiteProjectId = location.state.SiteProjectId;
  const [EmpList, setEmpList] = useState([]);
  const [EmpId, setEmpId] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmpId(null);

  };

  const sendComment = () => {
    const sendData = new FormData()
    sendData.append('EmployeeId', empid)
    sendData.append('Comments', inputText)
    sendData.append('SiteProjectId', SiteProjectId)
    sendData.append('ToEmployee', EmpId?.EmployeeName)
    axios({
      method: methodPost,
      url: add_site_comments,
      data: sendData
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setData(res.data.data);
          setInputText('')
          getComments();
        } else {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
          setOpen(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }

  const getComments = () => {
    const sendData = new FormData()
    // sendData.append('EmployeeId', empid)
    sendData.append('SiteProjectId', SiteProjectId)
    axios({
      method: methodPost,
      url: get_site_comments,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          setGetComments([]);
        } else {
          setGetComments(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }

  useEffect(() => {
    getComments();
  }, [SiteProjectId])

  useEffect(() => {
    axios({
      method: methodGet,
      url: getAllEmployeeName,
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setOpen(true);
          // setStatus(false);
          // setColor(false);
        } else {
          // setMessage(res.data.message);
          setEmpList(res.data.data);
          // setOpen(true);
          // setStatus(true);
          // setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);


  return (
    <div>
      {/* <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      /> */}
      <Button fullWidth sx={{ height: 42, bgcolor: 'secondary.main', ':hover': { bgcolor: 'success.main' } }} variant="outlined" onClick={handleClickOpen}>
        Open Bill Chat Box
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <Box display='flex' justifyContent='end' paddingTop={1} paddingRight={1}>
          <CloseIcon onClick={handleClose} />
        </Box>
        <Box m={1} border='1px solid silver'>
          <Box textAlign='left' p={1} mb={3}>
            <Typography variant='h5' fontWeight={600}>Bill Chat</Typography>
            <Typography>All Your Chats Will be Stored Here.</Typography>
          </Box>
          {getComments == "" ? "No messages found" :
            getcomments.map((cmt, index) => (
              <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)', margin: '15px', padding: '5px', borderRadius: '5px' }}>
                <Card.Body>
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography sx={{ wordBreak: 'break-word' }}>To:{cmt.to_employee}</Typography>
                    <Typography sx={{ wordBreak: 'break-word' }}>{cmt.UserName} : {cmt.Comments}</Typography>
                    <Typography variant='caption'>{cmt.CreatedAt}</Typography> <br />
                  </Box>
                </Card.Body>
              </Card>
            ))}

          <Box sx={{ border: '1px solid silver', borderWidth: '1px 1px 1px 1px', padding: '7px' }}>
          <Autocomplete
              disablePortal
              size='small'
              id="combo-box-demo"
              options={EmpList}
              value={EmpId}
              getOptionLabel={(option) => `${option.EmployeeName}`}
              onChange={(event, value) => setEmpId(value)}
              renderInput={(params) => <TextField color='secondary' label='Employee Name' {...params} required />}
            />
            <Input
              id="standard-adornment-password"
              type='text'
              multiline
              value={inputText}
              placeholder=' Type Your Text Here'
              fullWidth
              onChange={(e) => setInputText(e.target.value)}
              sx={{paddingTop:'20px'}}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={sendComment} sx={{ color: '#7BC54C' }}>
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}