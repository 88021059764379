import React from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container} from "@mui/material";
import Heading from '../Components/Heading';
import Breadcrumbs from '../Components/Breadcrumbs';
import { ExpenseView, financepageControllerView, financepageEmployeeView } from '../Variables/Variables';
import { Outlet, useNavigate } from 'react-router-dom';
import IconBreadcrumbs from '../Components/Breadcrumbs';

export default function ExpensePage() {

  const navigate = useNavigate();

  const handleClick = (path) =>{
   navigate(`${path}`)
  }


  const access = JSON.parse(localStorage.getItem('access'));

  return (
    <Box>
     <Box>
    <Box py={2} px={1}>

    <IconBreadcrumbs
    previous={'Home'}
    current={'Finance'}
    link1={`/home`}
    link2={'/home/finance'}

    />
    </Box>

    <Container>
      <Box py={3}>
      <Heading  title={'Expense'}/>
      </Box>
    </Container>


    <Container>
        <Grid container>
        <Grid item sm={12} xs={12} md={12} lg={12} xl={12} display='flex' justifyContent='center' py={5}>
        <Grid container spacing={5}>
        {
                   ExpenseView.map((i, index)=>{
                      if (access[i.access] === 'true') {
                        return (
                    <Grid item sm={4} xs={4} md={4} lg={4} xl={4} display='flex' justifyContent='center' alignContent='center' >
                    <Box onClick={()=>{handleClick(i.path)}}>
                    <Box component='div' className="cards" p={3} justifyContent='space-between' display='flex' flexDirection='column' >

                    <img src={i.Icon} alt='project-icon' />

                    <Box textAlign='center'>
                    <Typography fontWeight={600}>{i.name}</Typography>
                    </Box>
                     </Box>
                     </Box>
                     </Grid>
                        );
                      } else {
                        return null;
                      }
                    })
                  }
         </Grid>
         
        </Grid>
        </Grid>
     </Container>
     </Box>


   <Box>
    <Outlet />
   </Box>



  </Box>
  )
}
