import React, { useState } from 'react'
import { Box, Button, Card, Grid, TextField, Typography, Container, Divider, Autocomplete, FormControl, Select, MenuItem } from "@mui/material";
import Heading from '../../../Components/Heading';
import Breadcrumbs from '../../../Components/Breadcrumbs';
import { useEffect } from 'react';
import axios from 'axios';
import {
  getAllEmployeeName, getAllSiteProject, getCashInHandBalanceAmount, getGroupsDropdown, getLedgerCategory1Dropdown,
  getLedgerCategory2Dropdown, getLedgerCategory3Dropdown,
  getLedgerCategory4Dropdown, getLedgerCategory5Dropdown,
  getLedgerNameDropdown,
  getOfficeDropdown, insertCashInHand, insertLedgerCreation, methodGet, methodPost
} from '../../../API_Service/API_Links';
import { appendData } from '../../../Variables/Variables';
import SnackBar from '../../SnackBar';
import { format } from 'date-fns';


export default function CashOnHand() {
  const [ImageProof, setImageProof] = useState(null);
  const [comments, setComments] = useState('');
  const [amount, setAmount] = useState('');
  const [projectCode, setProjectCode] = useState(null);
  const [ProjectList, setProjectList] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [input, setInput] = useState(false);
  const [LedgerName, setLedgerName] = useState(null);
  const [offficeCategory, setoffficeCategory] = useState(null);
  const [group, setGroup] = useState(null);
  const [category1, setCategory1] = useState(null);
  const [category2, setCategory2] = useState(null);
  const [category3, setCategory3] = useState(null);
  const [category4, setCategory4] = useState(null);
  const [category5, setCategory5] = useState(null);
  // const EmployeeId = localStorage.getItem('EmployeeId');
   const options = ['GST', 'NON-GST'];
  const [gst, setGst] = useState('NON-GST');
  const [sgst, setSGST] = useState('');
  const [cgst, setCGST] = useState('');
  const [igst, setIGST] = useState('');
  const [groupList, setGroupList] = useState([]);
  const [ledgerList, setLedgerList] = useState([]);
  const [offficeCategoryList, setoffficeCategoryList] = useState([]);
  const [Category1List, setCategory1List] = useState([]);
  const [Category2List, setCategory2List] = useState([]);
  const [Category3List, setCategory3List] = useState([]);
  const [Category4List, setCategory4List] = useState([]);
  const [Category5List, setCategory5List] = useState([]);
  const [Balance, setBalance] = useState('');
  const [SpendDate, setSpendDate] = useState('');

  const [ManualOfficecategory1, setManualOfficeCategory1] = useState(null);
  const [ManualLedgerName, setManualLedgerName] = useState(null);
  const [Manualgroup, setManualGroup] = useState(null);
  const [Manualcategory1, setManualCategory1] = useState(null);
  const [Manualcategory2, setManualCategory2] = useState(null);
  const [Manualcategory3, setManualCategory3] = useState(null);
  const [Manualcategory4, setManualCategory4] = useState(null);
  const [Manualcategory5, setManualCategory5] = useState(null);
  const [empname, setEmpname] = useState([]);
  const [EmpId, setEmpId] = useState(null);

  useEffect(() => {
    const today = new Date();
    const formattedDate = format(today, 'yyyy-MM-dd');

    setSpendDate(formattedDate);
  }, []);

  useEffect(() => {
    axios({
      method: methodGet,
      url: getAllSiteProject,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setProjectList(res.data.data);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);


  useEffect(() => {
    axios({
      method: methodGet,
      url: getOfficeDropdown,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setoffficeCategoryList(res.data.data);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);


  useEffect(() => {
    axios({
      method: methodGet,
      url: getAllEmployeeName,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setEmpname(res.data.data);
          setOpen(true);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  useEffect(() => {
    axios({
      method: methodGet,
      url: getLedgerNameDropdown,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setLedgerList(res.data.data);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  useEffect(() => {
    const sendData = new FormData();
    sendData.append('LedgerNameId', (ManualLedgerName === null && LedgerName !== undefined && LedgerName !== null) ? LedgerName?.LedgerNameId : "");
    axios({
      method: methodPost,
      url: getGroupsDropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setGroupList(res.data.data);
          if (LedgerName !== null) {
            setGroup(res.data.data[0])
          }
          else {
            setGroup(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [LedgerName]);





  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerGroupsId', (Manualgroup === null && group !== undefined && group !== null) ? group?.LedgerGroupsId : "")
    axios({
      method: methodPost,
      url: getLedgerCategory1Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setCategory1List(res.data.data);
          if (group !== null) {
            setCategory1(res.data.data[0])
          }
          else {
            setCategory1(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [group]);


  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId1', (Manualcategory1 === null && category1 !== undefined && category1 !== null) ? category1?.LedgerCategoryId1 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory2Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setCategory2List(res.data.data);
          if (category1 !== null) {
            setCategory2(res.data.data[0])
          }
          else {
            setCategory2(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [category1]);

  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId2', (Manualcategory2 === null && category2 !== undefined && category2 !== null) ? category2?.LedgerCategoryId2 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory3Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setCategory3List(res.data.data);
          if (category2 !== null) {
            setCategory3(res.data.data[0])
          }
          else {
            setCategory3(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [category2]);

  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId3', (Manualcategory3 === null && category3 !== undefined && category3 !== null) ? category3?.LedgerCategoryId3 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory4Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setCategory4List(res.data.data);
          if (category3 !== null) {
            setCategory4(res.data.data[0])
          }
          else {
            setCategory4(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [category3]);

  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId4', (Manualcategory4 === null && category4 !== undefined && category4 !== null) ? category4?.LedgerCategoryId4 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory5Dropdown,
      data: sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setCategory5List(res.data.data);
          if (category4 !== null) {
            setCategory5(res.data.data[0])
          }
          else {
            setCategory5(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, [category4]);


  const getBalance = (id) => {
    const sendData = new FormData()
    sendData.append('EmployeeId', id)
    axios({
      method: methodPost,
      url: getCashInHandBalanceAmount,
      data: sendData,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setBalance(res.data.data);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }

  console.log(category4);

  const AddNewLedger = () => {
    const obj = {
      EmployeeId: EmpId,
      Category: ManualOfficecategory1 !== null ? ManualOfficecategory1 : offficeCategory?.Category,
      Comments: comments,
      Amount: amount,
      Images: ImageProof,
      Types: gst,
      SGST: sgst,
      CGST: cgst,
      IGST: igst,
      SpendDate: SpendDate,
      LedgerNameId: LedgerName?.LedgerNameId || null,
      LedgerName: ManualLedgerName !== null ? ManualLedgerName : LedgerName?.LedgerName ? LedgerName?.LedgerName : null,
      LedgerGroups: Manualgroup != null ? Manualgroup : group?.LedgerGroups ? group?.LedgerGroups : null,
      LedgerCategory1: Manualcategory1 != null ? Manualcategory1 : category1?.LedgerCategory1 ? category1?.LedgerCategory1 : null,
      LedgerCategory2: Manualcategory2 != null ? Manualcategory2 : category2?.LedgerCategory2 ? category2?.LedgerCategory2 : null,
      LedgerCategory3: Manualcategory3 != null ? Manualcategory3 : category3?.LedgerCategory3 ? category3?.LedgerCategory3 : null,
      LedgerCategory4: Manualcategory4 !== null ? Manualcategory4 : category4?.LedgerCategory4 ? category4?.LedgerCategory4 : null,
      LedgerCategory5: Manualcategory5 !== null ? Manualcategory5 : category5?.LedgerCategory5 ? category5?.LedgerCategory5 : null,
    }
    const ldata = appendData(obj);
    axios({
      method: methodPost,
      url: insertCashInHand,
      data: ldata,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setProjectCode(null);
        setoffficeCategory(null);
        setComments('')
        setAmount('')
        document.getElementById('Imageproof').value = '';
        setCategory1(null);
        setCategory2(null);
        setCategory3(null);
        setCategory4(null);
        setCategory5(null);
        setManualCategory1(null)
        setManualCategory2(null)
        setManualCategory3(null)
        setManualCategory4(null)
        setManualCategory5(null)
        setManualLedgerName(null)
        setManualOfficeCategory1(null);
        setManualGroup(null)
        setSpendDate('')
        setGroup(null);
        setGst(null);
        setIGST('')
        setCGST('')
        setInput(true)
        setSGST('')
        setLedgerName(null);
      } else {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    })
  }

  return (
    <Box>

      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />

      <Container>
        <Box display='flex' justifyContent='end' mb={1}>
          <Box bgcolor='#008B8B' p={1}>
            <Typography variant='h6' color='#fff'>Cash In Hand : {Balance}</Typography>
          </Box>
        </Box>
      </Container>



      <Container>

        <Box component={Card} p={2} mb={1}>
          <Box
            sx={{
              border: "1px solid black",
              p: 3,
              borderColor: "#d2cbcb;",
              borderRadius: "4px",
              mt: 1,
            }}
          >

            <Grid container spacing={2}>

              {/* <Grid item xs={12} sm={6} md={4} lg={4}>
      <Autocomplete
      disablePortal
      id="combo-box-demo"
      key={input}

      value={projectCode}
      options={ProjectList.map((i)=>i.projectCode)}
      onChange={(event, value) => setProjectCode(value)}
      size='small'
      renderInput={(params) => <TextField sx={{my:1}} fontSize='small' label='Project Code' color='secondary' {...params} />}
        />
      </Grid> */}

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={offficeCategoryList}
                  value={offficeCategory}
                  freeSolo
                  getOptionLabel={(option) => option.Category}
                  onChange={(event, value) => {
                    setoffficeCategory(value)
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualOfficeCategory1(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Spend Type' color='secondary' {...params} />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  clearText="Clear"
                  disablePortal
                  size='small'
                  id="combo-box-demo"
                  options={empname}
                  getOptionLabel={(option) => `${option.EmployeeName} (${option.EmployeeId})`}
                  onChange={(event, value) => setEmpId(value ? value.EmployeeId : '')}
                  fullWidth
                  sx={{ my: 1 }}
                  renderInput={(params) => <TextField color='secondary' label='Employee Name' {...params} />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="comments"
                  label="Comments"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  fullWidth
                  size='small'
                  sx={{ my: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="SpendDate"
                  label="Spend Date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  type='date'
                  value={SpendDate}
                  onChange={(e) => setSpendDate(e.target.value)}
                  fullWidth
                  size='small'
                  sx={{ my: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="Amount"
                  label="Amount"
                  value={amount}
                  fullWidth
                  sx={{ my: 1 }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    // Regular expression for allowing decimal numbers
                    if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                      setAmount(newValue);
                    } else {
                      setAmount('');
                    }
                  }}
                  size='small'
                />

              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="Imageproof"
                  label="Upload Image"
                  type='file'
                  id='Imageproof'
                  onChange={(e) => setImageProof(e.target.files[0])}
                  fullWidth
                  size='small'
                  sx={{ my: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>


              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={['GST', 'NON-GST']}
                  value={gst}
                  onChange={(event, value) => setGst(value)}
                  size='small'
                  renderInput={(params) => <TextField sx={{ my: 1 }} fontSize='small' label='GST/NON-GST' color='secondary' {...params} />}
                />
              </Grid>

              {
                (gst === 'GST' && gst !== null && gst !== undefined) && <>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                      name="name"
                      label="SGST (%)"
                      fullWidth
                      sx={{ my: 1 }}
                      size='small'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // Regular expression for allowing decimal numbers
                        if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                          setSGST(newValue);
                        } else {
                          setSGST('');
                        }
                      }}
                      value={sgst}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                      name="name"
                      label="CGST (%)"
                      fullWidth
                      size='small'
                      sx={{ my: 1 }}
                      value={cgst}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // Regular expression for allowing decimal numbers
                        if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                          setCGST(newValue);
                        } else {
                          setCGST('');
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                      name="name"
                      label="IGST (%)"
                      fullWidth
                      size='small'
                      sx={{ my: 1 }}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // Regular expression for allowing decimal numbers
                        if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                          setIGST(newValue);
                        } else {
                          setIGST('');
                        }
                      }}
                      value={igst}
                    />
                  </Grid>
                </>
              }


              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={ledgerList}
                  value={LedgerName}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerName}
                  onChange={(event, value) => setLedgerName(value ?? value)}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualLedgerName(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Ledger' color='secondary' {...params} />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={groupList}
                  value={group}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerGroups}
                  onChange={(event, value) => setGroup(value ?? value)}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualGroup(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Select Group' color='secondary' {...params} />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category1List}
                  value={category1}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory1}
                  onChange={(event, value) => {
                    setCategory1(value ?? value)
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory1(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 1' color='secondary' {...params} />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category2List}
                  value={category2}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory2}
                  onChange={(event, value) => {
                    setCategory2(value ?? value);
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory2(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 2' color='secondary' {...params} />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category3List}
                  value={category3}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory3}
                  onChange={(event, value) => {
                    setCategory3(value ?? value);
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory3(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 3' color='secondary' {...params} />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category4List}
                  value={category4}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory4}
                  onChange={(event, value) => {
                    setCategory4(value ?? value);
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory4(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 4' color='secondary' {...params} />}
                />
              </Grid>


              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  key={input}
                  options={Category5List}
                  value={category5}
                  freeSolo
                  getOptionLabel={(option) => option.LedgerCategory5}
                  onChange={(event, value) => {
                    setCategory5(value ?? value);
                  }}
                  size='small'
                  renderInput={(params) => <TextField onChange={(e) => setManualCategory5(e.target.value)} sx={{ my: 1 }} fontSize='small' label='Category 5' color='secondary' {...params} />}
                />
              </Grid>

            </Grid>

            <Grid container display='flex' justifyContent='center'>
              <Grid item xs={12} sm={6} md={6} lg={6} display='flex' justifyContent='center'>
                <Box display='flex' justifyContent='center' py={2}>
                  <Button variant='contained' color='secondary' onClick={AddNewLedger} fullWidth>SUBMIT</Button>
                </Box>
              </Grid>
            </Grid>


          </Box>
        </Box>

      </Container>
    </Box>
  )
}
