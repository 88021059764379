import { Box, Button, Card, Grid, TextField, Typography, Container, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import React, { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { add_amount, getAllEmployeeName, getAllSiteProject, get_all_transaction_amount, get_available_balance, 
    get_department, get_factory_employees, methodGet, methodPost, transacting_amount } from '../../../API_Service/API_Links';
import { appendData } from '../../../Variables/Variables';
import SnackBar from "../../SnackBar";
import { FilterData } from "../../FilterData/FilterData";
import Filter from "../../FilterData/Filter";
import Heading from "../../Heading";

export default function Transaction() {

    const [EmployeeNameList, setEmployeeNameList] = useState([]);
    const [SelectedEmployeeName, setSelectedEmployeeName] = useState("");
    const [SelectedFromEmployeeId, setSelectedFromEmployeeId] = useState("");
    const [SelectedToEmployeeId, setSelectedToEmployeeId] = useState("");
    const [ProjectList, setProjectList] = useState([]);
    const [selectedFromProjectList, setSelectedFromProjectList] = useState('');
    const [selectedToProjectList, setSelectedToProjectList] = useState('');
    const [AddedAmount, setAddedAmount] = useState('');
    const [Balance, setBalance] = useState('');
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [input, setInput] = useState(false);
    const [Transaction, setTransaction] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState("");
    

    const [page, setPage] = useState(0);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // GET PROJECTS
    useEffect(() => {
        axios({
            method: methodGet,
            url: getAllSiteProject,
        })
            .then((res) => {
                if (res.data.error) {
                    setMessage(res.data.message);
                    setStatus(false);
                    setColor(false);
                } else {
                    setMessage(res.data.message);
                    setProjectList(res.data.data);
                    setStatus(true);
                    setColor(true);
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
                console.log("chip1", err);
            });
    }, []);

// GET TRANSACTION

    useEffect(() => {
        axios({
            method: methodGet,
            url: get_all_transaction_amount,
        })
            .then((res) => {
                if (res.data.error) {
                    setMessage(res.data.message);
                    setStatus(false);
                    setColor(false);
                } else {
                    setMessage(res.data.message);
                    setTransaction(res.data.data);
                    setStatus(true);
                    setColor(true);
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
                console.log("chip1", err);
            });
    }, []);



    //  GET EMPLOYEE AND EMPID
    useEffect(() => {
        axios({
            method: 'GET',
            url: getAllEmployeeName,
        })
            .then((res) => {
                if (res.data.error) {
                    setMessage(res.data.message);
                    setStatus(false);
                    setColor(false);
                } else {
                    setMessage(res.data.message);
                    setEmployeeNameList(res.data.data);
                    setStatus(true);
                    setColor(true);
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
            });
    }, []);


    //  GET EMPLOYEE BALANCE
    useEffect(() => {
        if (SelectedFromEmployeeId !== '' || SelectedFromEmployeeId !== null || SelectedFromEmployeeId !== undefined) {
            const sendData = new FormData();
            sendData.append("ProjectName", selectedFromProjectList);
            sendData.append("EmployeeId", SelectedFromEmployeeId);
            axios({
                method: methodPost,
                url: get_available_balance,
                data: sendData,
            })
                .then((res) => {
                    if (res.data.error) {
                        setMessage(res.data.message);
                        setStatus(false);
                        setColor(false);
                        setBalance(0.00);
                    } else {
                        setMessage(res.data.message);
                        setBalance(res.data.data.AvailableBalance);
                        setStatus(true);
                        setColor(true);
                    }
                })
                .catch((err) => {
                    alert("Oops something went wrong " + err);
                });
        }
    }, [SelectedFromEmployeeId]);



    // Add Amount
    const serverData = {
    FromEmployeeId: SelectedFromEmployeeId,
    FromProjectName: selectedFromProjectList,
    ToEmployeeId: SelectedToEmployeeId,
    ToProjectName: selectedToProjectList,
    Amount: AddedAmount,
    }
    const sendData = appendData(serverData);
    const AddMoneyToEmployee = () => {
        axios({
            method: 'POST',
            url: transacting_amount,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
                setInput(false);
            } else {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(true)
                setColor(true)
                setInput(true)
                setBalance('')
                setAddedAmount("");
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }


    return (
        <Box>
            <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
            <Container>
                <Box component={Card} p={4}>


                    <Box
                        sx={{
                            border: "1px solid black",
                            p: 4,
                            borderColor: "#d2cbcb;",
                            borderRadius: "4px",
                            boxShadow:3,
                            ":hover": { boxShadow: 4 },
                            mt: 5,
                        }}
                    >
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box mb={3}>
                                    <Box>
                                        <Typography sx={{ fontSize: 17, fontWeight: 700, color: '#616e80' }}>From Project</Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            key={input}
                                            options={ProjectList.map((i) => i.projectCode)}
                                            onChange={(event, value) => setSelectedFromProjectList(value)}
                                            size='small'
                                            sx={{ width: 250 }}
                                            renderInput={(params) => <TextField fontSize='small' color='secondary' {...params} />}
                                        />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box mb={3}>
                                    <Box>
                                        <Typography sx={{ fontSize: 17, fontWeight: 700, color: '#616e80' }}>From Employee</Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Autocomplete
                                            disablePortal
                                            size='small'
                                            id="combo-box-demo"
                                            key={input}
                                            options={EmployeeNameList}
                                            getOptionLabel={(option) => `${option.EmployeeName} (${option.EmployeeId})`}
                                            onChange={(event, value) => setSelectedFromEmployeeId(value ? value.EmployeeId : '')}
                                            sx={{ width: 250 }}
                                            renderInput={(params) => <TextField color='secondary' {...params} />}
                                        />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box mb={3}>
                                    <Box>
                                        <Typography sx={{ fontSize: 17, fontWeight: 700, color: '#616e80' }}>Available Bal(₹)</Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <TextField
                                            id="EmployeeName"
                                            variant="outlined"
                                            size='small'
                                            color='secondary'
                                            sx={{ width: 250 }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={Balance}
                                        />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box mb={3}>
                                    <Box>
                                        <Typography sx={{ fontSize: 17, fontWeight: 700, color: '#616e80' }}>To Project</Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            key={input}
                                            options={ProjectList.map((i) => i.projectCode)}
                                            onChange={(event, value) => setSelectedToProjectList(value)}
                                            size='small'
                                            sx={{ width: 250 }}
                                            renderInput={(params) => <TextField fontSize='small' color='secondary' {...params} />}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box mb={3}>
                                    <Box>
                                        <Typography sx={{ fontSize: 17, fontWeight: 700, color: '#616e80' }}>To Employee</Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Autocomplete
                                            disablePortal
                                            size='small'
                                            id="combo-box-demo"
                                            key={input}
                                            options={EmployeeNameList}
                                            getOptionLabel={(option) => `${option.EmployeeName} (${option.EmployeeId})`}
                                            onChange={(event, value) => setSelectedToEmployeeId(value ? value.EmployeeId : '')}
                                            sx={{ width: 250 }}
                                            renderInput={(params) => <TextField color='secondary' {...params} />}
                                        />
                                    </Box>
                                </Box>
                            </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Box mb={3}>
                                <Box>
                                    <Typography sx={{ fontSize: 17, fontWeight: 700, color: '#616e80' }}>Send Money(₹)</Typography>
                                </Box>
                                <Box mt={1}>
                                    <TextField
                                        id="amount"
                                        variant="outlined"
                                        size='small'
                                        color='secondary'
                                        sx={{ width: 250 }}
                                        value={AddedAmount}
                                        inputProps={{
                                            maxLength: 5,
                                            type: 'text',
                                          }}
                                          onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                              e.preventDefault();
                                            }
                                          }}
                                        onChange={(e) => setAddedAmount(e.target.value)}
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        </Grid>

                        <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box display='flex' justifyContent='center' py={2}>
                        <Button onClick={AddMoneyToEmployee} variant='contained' color='secondary' fullWidth>Add Money in Wallet</Button>
                         </Box>
                        </Grid>
                        </Grid>
                         </Box>


                    <Container>
                        <Box py={3}>
                            <Heading title={'Transactions'} />
                        </Box>
                    </Container>


                    <Box mt={1} boxShadow={3}>
                    <Grid container>
                        <TableContainer sx={{ border: '1px solid silver' }} >
                            <Table>
                                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
                                    <TableRow sx={{ borderBottom: '1px solid silver' }}>
                                        <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                            Transaction Id
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    From Project
                                                </Box>
                                                <Box>
                                                    <Filter label={"Project Code"} search={search} setSearch={setSearch} />
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                            From Employee
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    To Project
                                                </Box>
                                                <Box>
                                                    <Filter label={"Project Code"} search={search} setSearch={setSearch} />
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                            To Employee
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                            Amount
                                        </TableCell>
                                    </TableRow>
                                </TableHead>


                                <TableBody>

                                    {
                                       Transaction && Transaction.filter((data) => FilterData(data, search, {
                                           searchFeildOne: data.FromProjectName,
                                           searchFeildTwo: data.ToProjectName,
                                        })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i, index) => {
                                            return (
                                                <TableRow key={index} sx={{ borderBottom: '1px solid silver' }}>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.TransactionId}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.FromProjectName}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.FromEmployeeName} ({i.FromEmployeeId})</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.ToProjectName}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.ToEmployeeName} ({i.ToEmployeeId})</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.Amount}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={Transaction.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                    </Box>

                </Box>
            </Container>
        </Box>
    )
}
